import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Chart } from 'primereact/chart';
import { TabMenu } from 'primereact/tabmenu';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Accordion, AccordionTab } from 'primereact/accordion';
import * as _ from 'lodash';

const UserStats = ({
  stats,
  setSidebarVisible,
  sidebarVisible,
  resetStats,
}: any) => {
  const [leastKnownRank, setLeastKnownRank] = useState(6);
  const [mostKnownRank, setMostKnownRank] = useState(6);
  const [mostCommonTaxa, setMostCommonTaxa] = useState(6);
  const modes: ('practice' | 'daily')[] = ['practice', 'daily'];
  const [mode, setMode] = useState<'practice' | 'daily'>('practice');

  const winCount = stats[mode].games.filter(
    ({ status }: { status: 'won' | 'lost' }) => status === 'won',
  ).length;
  const loseCount = stats[mode].games.filter(
    ({ status }: { status: 'won' | 'lost' }) => status === 'lost',
  ).length;
  const items = [
    { label: 'Practice', icon: 'pi pi-fw pi-stopwatch' },
    { label: 'Daily', icon: 'pi pi-fw pi-bolt' },
  ];
  //   backgroundColor: [
  //     documentStyle.getPropertyValue('--red-300'),
  //     documentStyle.getPropertyValue('--green-300'),
  //   ],
  //   hoverBackgroundColor: [
  //     documentStyle.getPropertyValue('--red-200'),
  //     documentStyle.getPropertyValue('--green-200'),
  //   ],
  // const noLegendOptions = {
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  // };
  const sideLegendOptions = {
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
      },
    },
  };

  const getStatGroup = ({
    level,
    status,
    maxLength,
    orderBy,
  }: {
    level: number;
    status?: string;
    maxLength?: number;
    orderBy?: string;
  }) => {
    let chain = _.chain(stats[mode].games)
      .filter((g: any) => (status ? g.status === status : true))
      .map(({ currentTree }: any) => currentTree?.[level])
      .groupBy('display_name')
      .map((it: any) => {
        const games = status
          ? stats[mode].games.filter((g: any) => g.status === status)
          : stats[mode].games;
        const allGuesses = games
          .filter(({ speciesId }: any) => speciesId === it[0]?.id)
          .map(({ guesses }: any) => guesses.length);
        return {
          ...it[0],
          averageGuesses:
            allGuesses.reduce(
              (partialSum: number, a: number) => partialSum + a + 1,
              0,
            ) / parseFloat(it.length),
          count: it.length,
        };
      });
    if (orderBy ?? status) {
      chain = chain
        .orderBy(
          ['count', 'averageGuesses'],
          (orderBy ?? status) === 'won' ? ['asc', 'desc'] : ['asc', 'asc'],
        )
        .reverse();
    }
    if (maxLength) {
      chain = chain.slice(0, maxLength);
    }
    return chain.value();
  };

  const rankOptions = [
    { label: 'Species', value: 6 },
    { label: 'Genus', value: 5 },
    { label: 'Family', value: 4 },
    { label: 'Order', value: 3 },
    { label: 'Class', value: 2 },
    { label: 'Phylum', value: 1 },
    { label: 'Kingdom', value: 0 },
  ];
  return (
    <Sidebar
      position="right"
      visible={sidebarVisible}
      onHide={() => setSidebarVisible(false)}
    >
      <h2 style={{ marginTop: 0 }}>
        <i
          className="pi pi-chart-bar"
          style={{ fontSize: '1em', marginRight: 6 }}
        />{' '}
        User Stats
      </h2>
      <TabMenu
        className="stats-tabmenu"
        model={items}
        activeIndex={modes.indexOf(mode)}
        onTabChange={(e) => setMode(modes[e.index])}
      />
      <div style={{ padding: 10 }}>
        <DataTable
          value={[
            {
              total: winCount + loseCount,
              streak: stats[mode].streak,
              wins: winCount,
              losses: loseCount,
            },
          ]}
        >
          <Column field="total" header="Total Games"></Column>
          <Column field="wins" header="Wins"></Column>
          <Column field="losses" header="Losses"></Column>
          <Column field="streak" header="Current Streak"></Column>
        </DataTable>
      </div>
      {stats[mode].games.length > 0 && (
        <Accordion activeIndex={null}>
          <AccordionTab header="Charts">
            <div style={{ padding: 20, paddingTop: 0 }}>
              <div className="grid">
                <div className="col-12">
                  <h2 style={{ marginTop: 0 }}>Wins/Losses</h2>
                </div>
                <div className="col-6">
                  <Chart
                    type="doughnut"
                    data={{
                      labels: ['Losses', 'Wins'],
                      datasets: [
                        {
                          data: [loseCount, winCount],
                        },
                      ],
                    }}
                    options={sideLegendOptions}
                  />
                </div>
                <div className="col-6"></div>
                <div className="col-12">
                  <h2>Most Common Taxa (Top 20)</h2>
                  <span
                    className="p-float-label"
                    style={{ marginBottom: 12, marginTop: 24 }}
                  >
                    <Dropdown
                      inputId="most-known-rank"
                      value={mostCommonTaxa}
                      onChange={(e) => setMostCommonTaxa(e.value)}
                      options={rankOptions}
                      optionLabel="label"
                      className="w-full"
                    />
                    <label htmlFor="most-known-rank">Taxonomic Rank</label>
                  </span>
                  <Chart
                    type="doughnut"
                    data={{
                      labels: getStatGroup({
                        level: mostCommonTaxa,
                        orderBy: 'won',
                        maxLength: 20,
                      }).map(({ display_name }) => display_name),
                      datasets: [
                        {
                          data: getStatGroup({
                            level: mostCommonTaxa,
                            orderBy: 'won',
                            maxLength: 20,
                          }).map(({ count }) => count),
                        },
                      ],
                    }}
                    options={sideLegendOptions}
                  />
                </div>
              </div>
              {/* <Chart type="pie" data={chartData} options={chartOptions} /> */}
            </div>
          </AccordionTab>
          <AccordionTab header="Most Known Taxa" style={{ padding: 0 }}>
            <Tooltip
              target=".stats-tooltip"
              position="left"
              mouseTrack
              mouseTrackLeft={10}
            />
            <span
              className="p-float-label"
              style={{ marginBottom: 12, marginTop: 24 }}
            >
              <Dropdown
                inputId="most-known-rank"
                value={mostKnownRank}
                onChange={(e) => setMostKnownRank(e.value)}
                options={rankOptions}
                optionLabel="label"
                className="w-full"
              />
              <label htmlFor="most-known-rank">Taxonomic Rank</label>
            </span>
            <ol style={{ margin: 0, padding: 0, paddingLeft: 20 }}>
              {getStatGroup({
                level: mostKnownRank,
                status: 'won',
                maxLength: 10,
              }).map(
                ({ name, common_name, photo, averageGuesses, count }, idx) => (
                  <li
                    key={name}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: 8,
                      borderRadius: 6,
                      backgroundColor: idx % 2 === 0 ? '#efefef' : '#fff',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        alt={common_name}
                        src={photo?.replace('medium', 'thumb')}
                        style={{
                          width: 32,
                          height: 32,
                          borderRadius: 4,
                          marginRight: 12,
                        }}
                      />
                      {common_name ? (
                        <span>
                          {common_name}
                          <em style={{ opacity: 0.7 }}> ({name})</em>
                        </span>
                      ) : (
                        <span>{name}</span>
                      )}
                    </div>
                    <div style={{ cursor: 'help' }}>
                      <span
                        className="stats-tooltip"
                        data-pr-tooltip="Total Count"
                      >
                        {count}
                      </span>{' '}
                      /{' '}
                      <span
                        className="stats-tooltip"
                        data-pr-tooltip="Average Guesses"
                      >
                        {Math.round(averageGuesses * 100) / 100}
                      </span>
                    </div>
                  </li>
                ),
              )}
            </ol>
          </AccordionTab>
          <AccordionTab header="Least Known Taxa" style={{ padding: 0 }}>
            <Tooltip
              target=".stats-tooltip"
              position="left"
              mouseTrack
              mouseTrackLeft={10}
            />
            <span
              className="p-float-label"
              style={{ marginBottom: 12, marginTop: 24 }}
            >
              <Dropdown
                inputId="least-known-rank"
                value={leastKnownRank}
                onChange={(e) => setLeastKnownRank(e.value)}
                options={rankOptions}
                optionLabel="label"
                className="w-full"
              />
              <label htmlFor="least-known-rank">Taxonomic Rank</label>
            </span>
            <ol style={{ margin: 0, padding: 0, paddingLeft: 20 }}>
              {getStatGroup({
                level: leastKnownRank,
                status: 'lost',
                maxLength: 10,
              })
                .filter(Boolean)
                .map(
                  (
                    { name, common_name, photo, averageGuesses, count },
                    idx,
                  ) => (
                    <li
                      key={name}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 8,
                        borderRadius: 6,
                        backgroundColor: idx % 2 === 0 ? '#efefef' : '#fff',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          alt={common_name}
                          src={photo?.replace('medium', 'thumb')}
                          style={{
                            width: 32,
                            height: 32,
                            borderRadius: 4,
                            marginRight: 12,
                          }}
                        />
                        {common_name ? (
                          <span>
                            {common_name}
                            <em style={{ opacity: 0.7 }}> ({name})</em>
                          </span>
                        ) : (
                          <span>{name}</span>
                        )}
                      </div>
                      <div style={{ cursor: 'help' }}>
                        <span
                          className="stats-tooltip"
                          data-pr-tooltip="Total Count"
                        >
                          {count}
                        </span>{' '}
                        /{' '}
                        <span
                          className="stats-tooltip"
                          data-pr-tooltip="Average Guesses"
                        >
                          {Math.round(averageGuesses * 100) / 100}
                        </span>
                      </div>
                    </li>
                  ),
                )}
            </ol>
          </AccordionTab>
        </Accordion>
      )}
      <Button
        rounded
        outlined
        style={{ width: '100%', marginTop: 16 }}
        size="large"
        icon="pi pi-trash"
        label="Clear All Stats"
        onClick={() => resetStats()}
      />
    </Sidebar>
  );
};

export default UserStats;
