import { formatDropdownOptionsFromApi } from './hooks/useLevels';
import { ORDER } from './orders';

export const TAXONOMY = [
  'kingdom',
  'phylum',
  'class',
  'order',
  'family',
  'genus',
  'species',
];
export const KINGDOM = [
  {
    id: 1,
    name: 'Animalia',
    common_name: 'Animals',
    parent_id: 48460,
    ancestor_ids: [48460, 1],
    rank: 'kingdom',
    wikipedia_url: 'http://en.wikipedia.org/wiki/Animal',
  },
  {
    id: 47126,
    name: 'Plantae',
    common_name: 'plants',
    parent_id: 48460,
    ancestor_ids: [48460, 47126],
    rank: 'kingdom',
    wikipedia_url: 'https://en.wikipedia.org/wiki/Plant',
  },
  {
    id: 47170,
    name: 'Fungi',
    common_name: 'Fungi Including Lichens',
    parent_id: 48460,
    ancestor_ids: [48460, 47170],
    rank: 'kingdom',
    wikipedia_url: 'http://en.wikipedia.org/wiki/Fungus',
  },
];
export const PHYLUM = [
  {
    id: 211194,
    name: 'Tracheophyta',
    common_name: 'vascular plants',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 211194],
    rank: 'phylum',
  },
  {
    id: 47120,
    name: 'Arthropoda',
    common_name: 'Arthropods',
    parent_id: 1,
    ancestor_ids: [48460, 1, 47120],
    rank: 'phylum',
  },
  {
    id: 2,
    name: 'Chordata',
    common_name: 'Chordates',
    parent_id: 1,
    ancestor_ids: [48460, 1, 2],
    rank: 'phylum',
  },
  {
    id: 47169,
    name: 'Basidiomycota',
    common_name: 'Basidiomycete Fungi',
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 47169],
    rank: 'phylum',
  },
  {
    id: 47115,
    name: 'Mollusca',
    common_name: 'Molluscs',
    parent_id: 1,
    ancestor_ids: [48460, 1, 47115],
    rank: 'phylum',
  },
  {
    id: 48250,
    name: 'Ascomycota',
    common_name: 'Ascomycete Fungi',
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 48250],
    rank: 'phylum',
  },
  {
    id: 311249,
    name: 'Bryophyta',
    common_name: 'mosses',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 311249],
    rank: 'phylum',
  },
  {
    id: 47534,
    name: 'Cnidaria',
    common_name: 'Cnidarians',
    parent_id: 1,
    ancestor_ids: [48460, 1, 47534],
    rank: 'phylum',
  },
  {
    id: 47549,
    name: 'Echinodermata',
    common_name: 'Echinoderms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 47549],
    rank: 'phylum',
  },
  {
    id: 47491,
    name: 'Annelida',
    common_name: 'Segmented Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 47491],
    rank: 'phylum',
  },
  {
    id: 64615,
    name: 'Marchantiophyta',
    common_name: 'liverworts',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 64615],
    rank: 'phylum',
  },
  {
    id: 57774,
    name: 'Rhodophyta',
    common_name: 'red algae',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 57774],
    rank: 'phylum',
  },
  {
    id: 50863,
    name: 'Chlorophyta',
    common_name: 'green algae',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 50863],
    rank: 'phylum',
  },
  {
    id: 48824,
    name: 'Porifera',
    common_name: 'Sponges',
    parent_id: 1,
    ancestor_ids: [48460, 1, 48824],
    rank: 'phylum',
  },
  {
    id: 52319,
    name: 'Platyhelminthes',
    common_name: 'Flatworms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 52319],
    rank: 'phylum',
  },
  {
    id: 68104,
    name: 'Bryozoa',
    common_name: 'Bryozoans',
    parent_id: 1,
    ancestor_ids: [48460, 1, 68104],
    rank: 'phylum',
  },
  {
    id: 311313,
    name: 'Charophyta',
    common_name: 'charophyte algae',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 311313],
    rank: 'phylum',
  },
  {
    id: 55113,
    name: 'Zygomycota',
    common_name: 'zygote fungi',
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 55113],
    rank: 'phylum',
  },
  {
    id: 126917,
    name: 'Rotifera',
    common_name: 'Rotifers',
    parent_id: 1,
    ancestor_ids: [48460, 1, 126917],
    rank: 'phylum',
  },
  {
    id: 54960,
    name: 'Nematoda',
    common_name: 'Nematodes',
    parent_id: 1,
    ancestor_ids: [48460, 1, 54960],
    rank: 'phylum',
  },
  {
    id: 51508,
    name: 'Ctenophora',
    common_name: 'Comb Jellies',
    parent_id: 1,
    ancestor_ids: [48460, 1, 51508],
    rank: 'phylum',
  },
  {
    id: 51280,
    name: 'Nemertea',
    common_name: 'Ribbon Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 51280],
    rank: 'phylum',
  },
  {
    id: 63142,
    name: 'Nematomorpha',
    common_name: 'Horsehair Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 63142],
    rank: 'phylum',
  },
  {
    id: 56327,
    name: 'Anthocerotophyta',
    common_name: 'hornworts',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 56327],
    rank: 'phylum',
  },
  {
    id: 51836,
    name: 'Onychophora',
    common_name: 'velvet worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 51836],
    rank: 'phylum',
  },
  {
    id: 124337,
    name: 'Tardigrada',
    common_name: 'Tardigrades',
    parent_id: 1,
    ancestor_ids: [48460, 1, 124337],
    rank: 'phylum',
  },
  {
    id: 122158,
    name: 'Brachiopoda',
    common_name: 'Brachiopods',
    parent_id: 1,
    ancestor_ids: [48460, 1, 122158],
    rank: 'phylum',
  },
  {
    id: 125609,
    name: 'Chytridiomycota',
    common_name: 'Chytrids',
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 125609],
    rank: 'phylum',
  },
  {
    id: 151829,
    name: 'Gastrotricha',
    common_name: 'Gastrotrichs',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151829],
    rank: 'phylum',
  },
  {
    id: 48051,
    name: 'Phoronida',
    common_name: 'Horseshoe Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 48051],
    rank: 'phylum',
  },
  {
    id: 211191,
    name: 'Xenacoelomorpha',
    common_name: 'Xenoturbellids and Allies',
    parent_id: 1,
    ancestor_ids: [48460, 1, 211191],
    rank: 'phylum',
  },
  {
    id: 68235,
    name: 'Hemichordata',
    common_name: 'Hemichordates',
    parent_id: 1,
    ancestor_ids: [48460, 1, 68235],
    rank: 'phylum',
  },
  {
    id: 151831,
    name: 'Entoprocta',
    common_name: 'Entoprocts',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151831],
    rank: 'phylum',
  },
  {
    id: 151827,
    name: 'Chaetognatha',
    common_name: 'Arrow Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151827],
    rank: 'phylum',
  },
  {
    id: 151868,
    name: 'Glomeromycota',
    common_name: 'Glomeromycetes',
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 151868],
    rank: 'phylum',
  },
  {
    id: 1094423,
    name: 'Blastocladiomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094423],
    rank: 'phylum',
  },
  {
    id: 151826,
    name: 'Acanthocephala',
    common_name: 'Thorny-headed Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151826],
    rank: 'phylum',
  },
  {
    id: 311312,
    name: 'Glaucophyta',
    common_name: 'glaucophyte algae',
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 311312],
    rank: 'phylum',
  },
  {
    id: 151838,
    name: 'Priapulida',
    common_name: 'Priapulid Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151838],
    rank: 'phylum',
  },
  {
    id: 1094433,
    name: 'Mucoromycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094433],
    rank: 'phylum',
  },
  {
    id: 151832,
    name: 'Kinorhyncha',
    common_name: 'Mud Dragons',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151832],
    rank: 'phylum',
  },
  {
    id: 1365644,
    name: 'Peltaspermophyta',
    common_name: null,
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 1365644],
    rank: 'phylum',
  },
  {
    id: 151830,
    name: 'Gnathostomulida',
    common_name: 'Jaw Worms',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151830],
    rank: 'phylum',
  },
  {
    id: 1094432,
    name: 'Entorrhizomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094432],
    rank: 'phylum',
  },
  {
    id: 1094444,
    name: 'Monoblepharomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094444],
    rank: 'phylum',
  },
  {
    id: 151837,
    name: 'Placozoa',
    common_name: 'Placozoans',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151837],
    rank: 'phylum',
  },
  {
    id: 1094440,
    name: 'Entomophthoromycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094440],
    rank: 'phylum',
  },
  {
    id: 774624,
    name: 'Dicyemida',
    common_name: 'Dicyemids',
    parent_id: 1,
    ancestor_ids: [48460, 1, 774624],
    rank: 'phylum',
  },
  {
    id: 151836,
    name: 'Orthonectida',
    common_name: 'Orthonectids',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151836],
    rank: 'phylum',
  },
  {
    id: 1365893,
    name: 'Psilophytophyta',
    common_name: null,
    parent_id: 47126,
    ancestor_ids: [48460, 47126, 1365893],
    rank: 'phylum',
  },
  {
    id: 1186436,
    name: 'Aphelida',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1186436],
    rank: 'phylum',
  },
  {
    id: 1094434,
    name: 'Calcarisporiellomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094434],
    rank: 'phylum',
  },
  {
    id: 1094437,
    name: 'Mortierellomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094437],
    rank: 'phylum',
  },
  {
    id: 1094438,
    name: 'Kickxellomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094438],
    rank: 'phylum',
  },
  {
    id: 1094439,
    name: 'Zoopagomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094439],
    rank: 'phylum',
  },
  {
    id: 1094441,
    name: 'Olpidiomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094441],
    rank: 'phylum',
  },
  {
    id: 1094442,
    name: 'Basidiobolomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094442],
    rank: 'phylum',
  },
  {
    id: 1094445,
    name: 'Neocallimastigomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094445],
    rank: 'phylum',
  },
  {
    id: 1094446,
    name: 'Aphelidiomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094446],
    rank: 'phylum',
  },
  {
    id: 1094447,
    name: 'Rozellomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094447],
    rank: 'phylum',
  },
  {
    id: 1094576,
    name: 'Caulochytriomycota',
    common_name: null,
    parent_id: 47170,
    ancestor_ids: [48460, 47170, 1094576],
    rank: 'phylum',
  },
  {
    id: 884506,
    name: 'Gnathifera',
    common_name: 'Gnathiferans',
    parent_id: 1,
    ancestor_ids: [48460, 1, 884506],
    rank: 'phylum',
  },
  {
    id: 151833,
    name: 'Loricifera',
    common_name: 'Loriciferans',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151833],
    rank: 'phylum',
  },
  {
    id: 151828,
    name: 'Cycliophora',
    common_name: 'Cycliophorans',
    parent_id: 1,
    ancestor_ids: [48460, 1, 151828],
    rank: 'phylum',
  },
  {
    id: 47125,
    name: 'Angiospermae',
    common_name: 'flowering plants',
    parent_id: 211194,
    ancestor_ids: [48460, 47126, 211194, 47125],
    rank: 'phylum',
  },
  {
    id: 372739,
    name: 'Hexapoda',
    common_name: 'Hexapods',
    parent_id: 47120,
    ancestor_ids: [48460, 1, 47120, 372739],
    rank: 'phylum',
  },
  {
    id: 355675,
    name: 'Vertebrata',
    common_name: 'Vertebrates',
    parent_id: 2,
    ancestor_ids: [48460, 1, 2, 355675],
    rank: 'phylum',
  },
  {
    id: 492000,
    name: 'Agaricomycotina',
    common_name: 'Higher Basidiomycetes',
    parent_id: 47169,
    ancestor_ids: [48460, 47170, 47169, 492000],
    rank: 'phylum',
  },
  {
    id: 245097,
    name: 'Chelicerata',
    common_name: 'Chelicerates',
    parent_id: 47120,
    ancestor_ids: [48460, 1, 47120, 245097],
    rank: 'phylum',
  },
  {
    id: 372740,
    name: 'Pezizomycotina',
    common_name: 'Higher Ascomycetes',
    parent_id: 48250,
    ancestor_ids: [48460, 47170, 48250, 372740],
    rank: 'phylum',
  },
  {
    id: 85493,
    name: 'Crustacea',
    common_name: 'Crustaceans',
    parent_id: 47120,
    ancestor_ids: [48460, 1, 47120, 85493],
    rank: 'phylum',
  },
  {
    id: 144128,
    name: 'Myriapoda',
    common_name: 'Myriapods',
    parent_id: 47120,
    ancestor_ids: [48460, 1, 47120, 144128],
    rank: 'phylum',
  },
  {
    id: 481959,
    name: 'Asterozoa',
    common_name: 'Sea Stars and Brittle Stars',
    parent_id: 47549,
    ancestor_ids: [48460, 1, 47549, 481959],
    rank: 'phylum',
  },
  {
    id: 774753,
    name: 'Echinozoa',
    common_name: 'sea urchins and sea cucumbers',
    parent_id: 47549,
    ancestor_ids: [48460, 1, 47549, 774753],
    rank: 'phylum',
  },
  {
    id: 1360860,
    name: 'Eurhodophytina',
    common_name: 'true red algae',
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 1360860],
    rank: 'phylum',
  },
  {
    id: 416490,
    name: 'Pucciniomycotina',
    common_name: null,
    parent_id: 47169,
    ancestor_ids: [48460, 47170, 47169, 416490],
    rank: 'phylum',
  },
  {
    id: 130868,
    name: 'Tunicata',
    common_name: 'Tunicates',
    parent_id: 2,
    ancestor_ids: [48460, 1, 2, 130868],
    rank: 'phylum',
  },
  {
    id: 152105,
    name: 'Rhabditophora',
    common_name: null,
    parent_id: 52319,
    ancestor_ids: [48460, 1, 52319, 152105],
    rank: 'phylum',
  },
  {
    id: 1156601,
    name: 'Intramacronucleata',
    common_name: null,
    parent_id: 118996,
    ancestor_ids: [48460, 48222, 118996, 1156601],
    rank: 'phylum',
  },
  {
    id: 951335,
    name: 'Ustilaginomycotina',
    common_name: null,
    parent_id: 47169,
    ancestor_ids: [48460, 47170, 47169, 951335],
    rank: 'phylum',
  },
  {
    id: 791576,
    name: 'Taphrinomycotina',
    common_name: null,
    parent_id: 48250,
    ancestor_ids: [48460, 47170, 48250, 791576],
    rank: 'phylum',
  },
  {
    id: 774752,
    name: 'Crinozoa',
    common_name: 'sea lilies',
    parent_id: 47549,
    ancestor_ids: [48460, 1, 47549, 774752],
    rank: 'phylum',
  },
  {
    id: 372742,
    name: 'Entomophthoromycotina',
    common_name: null,
    parent_id: 55113,
    ancestor_ids: [48460, 47170, 55113, 372742],
    rank: 'phylum',
  },
  {
    id: 245928,
    name: 'Lobosa',
    common_name: 'Lobose amoebae',
    parent_id: 372735,
    ancestor_ids: [48460, 47686, 372735, 245928],
    rank: 'phylum',
  },
  {
    id: 1410900,
    name: 'Myzozoa',
    common_name: null,
    parent_id: 792744,
    ancestor_ids: [48460, 48222, 792744, 1410900],
    rank: 'phylum',
  },
  {
    id: 1128018,
    name: 'Polyploviricotina',
    common_name: null,
    parent_id: 1128016,
    ancestor_ids: [48460, 131236, 1128016, 1128018],
    rank: 'phylum',
  },
  {
    id: 1276097,
    name: 'Postciliodesmatophora',
    common_name: null,
    parent_id: 118996,
    ancestor_ids: [48460, 48222, 118996, 1276097],
    rank: 'phylum',
  },
  {
    id: 551549,
    name: 'Rhynchonelliformea',
    common_name: 'Articulate Brachiopods',
    parent_id: 122158,
    ancestor_ids: [48460, 1, 122158, 551549],
    rank: 'phylum',
  },
  {
    id: 1185636,
    name: 'Filosa',
    common_name: null,
    parent_id: 151873,
    ancestor_ids: [48460, 48222, 151873, 1185636],
    rank: 'phylum',
  },
  {
    id: 1518591,
    name: 'Proteorhodophytina',
    common_name: null,
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 1518591],
    rank: 'phylum',
  },
  {
    id: 417327,
    name: 'Acoelomorpha',
    common_name: 'Acoelomorph Flatworms',
    parent_id: 211191,
    ancestor_ids: [48460, 1, 211191, 417327],
    rank: 'phylum',
  },
  {
    id: 793974,
    name: 'Saccharomycotina',
    common_name: 'budding yeasts',
    parent_id: 48250,
    ancestor_ids: [48460, 47170, 48250, 793974],
    rank: 'phylum',
  },
  {
    id: 1185618,
    name: 'Opalozoa',
    common_name: null,
    parent_id: 792746,
    ancestor_ids: [48460, 48222, 792746, 1185618],
    rank: 'phylum',
  },
  {
    id: 152804,
    name: 'Catenulida',
    common_name: null,
    parent_id: 52319,
    ancestor_ids: [48460, 1, 52319, 152804],
    rank: 'phylum',
  },
  {
    id: 1276035,
    name: 'Endomyxa',
    common_name: null,
    parent_id: 151873,
    ancestor_ids: [48460, 48222, 151873, 1276035],
    rank: 'phylum',
  },
  {
    id: 129726,
    name: 'Cephalochordata',
    common_name: 'Lancelets',
    parent_id: 2,
    ancestor_ids: [48460, 1, 2, 129726],
    rank: 'phylum',
  },
  {
    id: 551548,
    name: 'Linguliformea',
    common_name: 'Lingulate Brachiopods',
    parent_id: 122158,
    ancestor_ids: [48460, 1, 122158, 551548],
    rank: 'phylum',
  },
  {
    id: 794030,
    name: 'Mortierellomycotina',
    common_name: null,
    parent_id: 55113,
    ancestor_ids: [48460, 47170, 55113, 794030],
    rank: 'phylum',
  },
  {
    id: 1128017,
    name: 'Haploviricotina',
    common_name: null,
    parent_id: 1128016,
    ancestor_ids: [48460, 131236, 1128016, 1128017],
    rank: 'phylum',
  },
  {
    id: 794033,
    name: 'Mucoromycotina',
    common_name: null,
    parent_id: 55113,
    ancestor_ids: [48460, 47170, 55113, 794033],
    rank: 'phylum',
  },
  {
    id: 1308213,
    name: 'Conosa',
    common_name: null,
    parent_id: 372735,
    ancestor_ids: [48460, 47686, 372735, 1308213],
    rank: 'phylum',
  },
  {
    id: 1521744,
    name: 'Cyanidiophytina',
    common_name: null,
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 1521744],
    rank: 'phylum',
  },
  {
    id: 551547,
    name: 'Craniiformea',
    common_name: 'Craniate Brachiopods',
    parent_id: 122158,
    ancestor_ids: [48460, 1, 122158, 551547],
    rank: 'phylum',
  },
  {
    id: 1110629,
    name: 'Wallemiomycotina',
    common_name: null,
    parent_id: 47169,
    ancestor_ids: [48460, 47170, 47169, 1110629],
    rank: 'phylum',
  },
  {
    id: 372741,
    name: 'Kickxellomycotina',
    common_name: null,
    parent_id: 55113,
    ancestor_ids: [48460, 47170, 55113, 372741],
    rank: 'phylum',
  },
  {
    id: 1186038,
    name: 'Apusozoa',
    common_name: null,
    parent_id: 796989,
    ancestor_ids: [48460, 47686, 796989, 1186038],
    rank: 'phylum',
  },
  {
    id: 151840,
    name: 'Xenoturbellida',
    common_name: 'Xenoturbellids',
    parent_id: 211191,
    ancestor_ids: [48460, 1, 211191, 151840],
    rank: 'phylum',
  },
  {
    id: 1272878,
    name: 'Trichozoa',
    common_name: null,
    parent_id: 796988,
    ancestor_ids: [48460, 47686, 796988, 1272878],
    rank: 'phylum',
  },
];
export const PHYLUM_IDS = PHYLUM.map(({ id }) => id.toString());
export const CLASS = [
  {
    id: 47124,
    name: 'Magnoliopsida',
    common_name: 'dicots',
    parent_id: 47125,
    ancestor_ids: [48460, 47126, 211194, 47125, 47124],
    rank: 'class',
  },
  {
    id: 47158,
    name: 'Insecta',
    common_name: 'Insects',
    parent_id: 372739,
    ancestor_ids: [48460, 1, 47120, 372739, 47158],
    rank: 'class',
  },
  {
    id: 3,
    name: 'Aves',
    common_name: 'Birds',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 3],
    rank: 'class',
  },
  {
    id: 47163,
    name: 'Liliopsida',
    common_name: 'monocots',
    parent_id: 47125,
    ancestor_ids: [48460, 47126, 211194, 47125, 47163],
    rank: 'class',
  },
  {
    id: 50814,
    name: 'Agaricomycetes',
    common_name: null,
    parent_id: 492000,
    ancestor_ids: [48460, 47170, 47169, 492000, 50814],
    rank: 'class',
  },
  {
    id: 47119,
    name: 'Arachnida',
    common_name: 'Arachnids',
    parent_id: 245097,
    ancestor_ids: [48460, 1, 47120, 245097, 47119],
    rank: 'class',
  },
  {
    id: 40151,
    name: 'Mammalia',
    common_name: 'Mammals',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 40151],
    rank: 'class',
  },
  {
    id: 26036,
    name: 'Reptilia',
    common_name: 'Reptiles',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 26036],
    rank: 'class',
  },
  {
    id: 20978,
    name: 'Amphibia',
    common_name: 'Amphibians',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 20978],
    rank: 'class',
  },
  {
    id: 47114,
    name: 'Gastropoda',
    common_name: 'Gastropods',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 47114],
    rank: 'class',
  },
  {
    id: 121943,
    name: 'Polypodiopsida',
    common_name: 'ferns',
    parent_id: 211194,
    ancestor_ids: [48460, 47126, 211194, 121943],
    rank: 'class',
  },
  {
    id: 47178,
    name: 'Actinopterygii',
    common_name: 'Ray-finned Fishes',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 47178],
    rank: 'class',
  },
  {
    id: 136329,
    name: 'Pinopsida',
    common_name: 'conifers',
    parent_id: 211194,
    ancestor_ids: [48460, 47126, 211194, 136329],
    rank: 'class',
  },
  {
    id: 54743,
    name: 'Lecanoromycetes',
    common_name: 'common lichens',
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 54743],
    rank: 'class',
  },
  {
    id: 47187,
    name: 'Malacostraca',
    common_name: 'Malacostracans',
    parent_id: 473790,
    ancestor_ids: [48460, 1, 47120, 85493, 473790, 47187],
    rank: 'class',
  },
  {
    id: 48372,
    name: 'Bryopsida',
    common_name: 'joint-toothed mosses',
    parent_id: 311249,
    ancestor_ids: [48460, 47126, 311249, 48372],
    rank: 'class',
  },
  {
    id: 47584,
    name: 'Bivalvia',
    common_name: 'Bivalves',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 47584],
    rank: 'class',
  },
  {
    id: 47735,
    name: 'Diplopoda',
    common_name: 'Millipedes',
    parent_id: 144128,
    ancestor_ids: [48460, 1, 47120, 144128, 47735],
    rank: 'class',
  },
  {
    id: 47533,
    name: 'Anthozoa',
    common_name: 'Sea Anemones and Corals',
    parent_id: 47534,
    ancestor_ids: [48460, 1, 47534, 47533],
    rank: 'class',
  },
  {
    id: 152032,
    name: 'Pezizomycetes',
    common_name: 'Operculate Ascomycetes',
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 152032],
    rank: 'class',
  },
  {
    id: 47684,
    name: 'Myxomycetes',
    common_name: 'true slime molds',
    parent_id: 47685,
    ancestor_ids: [48460, 47686, 47685, 47684],
    rank: 'class',
  },
  {
    id: 53539,
    name: 'Sordariomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 53539],
    rank: 'class',
  },
  {
    id: 47623,
    name: 'Lycopodiopsida',
    common_name: 'lycophytes',
    parent_id: 211194,
    ancestor_ids: [48460, 47126, 211194, 47623],
    rank: 'class',
  },
  {
    id: 48220,
    name: 'Phaeophyceae',
    common_name: 'brown algae',
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 48220],
    rank: 'class',
  },
  {
    id: 47668,
    name: 'Asteroidea',
    common_name: 'Sea Stars',
    parent_id: 481959,
    ancestor_ids: [48460, 1, 47549, 481959, 47668],
    rank: 'class',
  },
  {
    id: 49556,
    name: 'Chilopoda',
    common_name: 'Centipedes',
    parent_id: 144128,
    ancestor_ids: [48460, 1, 47120, 144128, 49556],
    rank: 'class',
  },
  {
    id: 55523,
    name: 'Leotiomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 55523],
    rank: 'class',
  },
  {
    id: 63089,
    name: 'Clitellata',
    common_name: 'Clitellates',
    parent_id: 47491,
    ancestor_ids: [48460, 1, 47491, 63089],
    rank: 'class',
  },
  {
    id: 785240,
    name: 'Polytrichopsida',
    common_name: null,
    parent_id: 311249,
    ancestor_ids: [48460, 47126, 311249, 785240],
    rank: 'class',
  },
  {
    id: 47273,
    name: 'Elasmobranchii',
    common_name: 'Elasmobranchs',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 47273],
    rank: 'class',
  },
  {
    id: 67852,
    name: 'Jungermanniopsida',
    common_name: 'Leafy liverworts',
    parent_id: 64615,
    ancestor_ids: [48460, 47126, 64615, 67852],
    rank: 'class',
  },
  {
    id: 57775,
    name: 'Florideophyceae',
    common_name: 'Florideophycean algae',
    parent_id: 1360860,
    ancestor_ids: [48460, 47126, 57774, 1360860, 57775],
    rank: 'class',
  },
  {
    id: 69967,
    name: 'Pucciniomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 69967],
    rank: 'class',
  },
  {
    id: 243773,
    name: 'Entognatha',
    common_name: 'Entognathans',
    parent_id: 372739,
    ancestor_ids: [48460, 1, 47120, 372739, 243773],
    rank: 'class',
  },
  {
    id: 53277,
    name: 'Dacrymycetes',
    common_name: null,
    parent_id: 492000,
    ancestor_ids: [48460, 47170, 47169, 492000, 53277],
    rank: 'class',
  },
  {
    id: 1091452,
    name: 'Hexanauplia',
    common_name: 'Barnacles and Copepods',
    parent_id: 473790,
    ancestor_ids: [48460, 1, 47120, 85493, 473790, 1091452],
    rank: 'class',
  },
  {
    id: 47548,
    name: 'Echinoidea',
    common_name: 'Sea Urchins, Sand Dollars, and Heart Urchins',
    parent_id: 774753,
    ancestor_ids: [48460, 1, 47549, 774753, 47548],
    rank: 'class',
  },
  {
    id: 47490,
    name: 'Polychaeta',
    common_name: 'Polychaete Worms',
    parent_id: 47491,
    ancestor_ids: [48460, 1, 47491, 47490],
    rank: 'class',
  },
  {
    id: 51429,
    name: 'Ulvophyceae',
    common_name: 'Ulvophycean green algae',
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 51429],
    rank: 'class',
  },
  {
    id: 152080,
    name: 'Marchantiopsida',
    common_name: 'complex thalloid liverworts',
    parent_id: 64615,
    ancestor_ids: [48460, 47126, 64615, 152080],
    rank: 'class',
  },
  {
    id: 48332,
    name: 'Scyphozoa',
    common_name: 'True Jellies',
    parent_id: 47534,
    ancestor_ids: [48460, 1, 47534, 48332],
    rank: 'class',
  },
  {
    id: 47429,
    name: 'Polyplacophora',
    common_name: 'Chitons',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 47429],
    rank: 'class',
  },
  {
    id: 83737,
    name: 'Tremellomycetes',
    common_name: null,
    parent_id: 492000,
    ancestor_ids: [48460, 47170, 47169, 492000, 83737],
    rank: 'class',
  },
  {
    id: 57736,
    name: 'Demospongiae',
    common_name: 'Demosponges',
    parent_id: 48824,
    ancestor_ids: [48460, 1, 48824, 57736],
    rank: 'class',
  },
  {
    id: 47811,
    name: 'Ascidiacea',
    common_name: 'Sea Squirts',
    parent_id: 130868,
    ancestor_ids: [48460, 1, 2, 130868, 47811],
    rank: 'class',
  },
  {
    id: 48921,
    name: 'Hydrozoa',
    common_name: 'Hydrozoans',
    parent_id: 47534,
    ancestor_ids: [48460, 1, 47534, 48921],
    rank: 'class',
  },
  {
    id: 54698,
    name: 'Sphagnopsida',
    common_name: null,
    parent_id: 311249,
    ancestor_ids: [48460, 47126, 311249, 54698],
    rank: 'class',
  },
  {
    id: 47459,
    name: 'Cephalopoda',
    common_name: 'Cephalopods',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 47459],
    rank: 'class',
  },
  {
    id: 152030,
    name: 'Lichinomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 152030],
    rank: 'class',
  },
  {
    id: 55174,
    name: 'Dothideomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 55174],
    rank: 'class',
  },
  {
    id: 47720,
    name: 'Holothuroidea',
    common_name: 'Sea Cucumbers',
    parent_id: 774753,
    ancestor_ids: [48460, 1, 47549, 774753, 47720],
    rank: 'class',
  },
  {
    id: 136336,
    name: 'Cycadopsida',
    common_name: 'Cycads',
    parent_id: 211194,
    ancestor_ids: [48460, 47126, 211194, 136336],
    rank: 'class',
  },
  {
    id: 123880,
    name: 'Bacillariophyceae',
    common_name: 'Diatoms',
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 123880],
    rank: 'class',
  },
  {
    id: 152028,
    name: 'Arthoniomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 152028],
    rank: 'class',
  },
  {
    id: 151883,
    name: 'Gnetopsida',
    common_name: 'gnetophytes',
    parent_id: 211194,
    ancestor_ids: [48460, 47126, 211194, 151883],
    rank: 'class',
  },
  {
    id: 151882,
    name: 'Ginkgoopsida',
    common_name: 'ginkgos',
    parent_id: 211194,
    ancestor_ids: [48460, 47126, 211194, 151882],
    rank: 'class',
  },
  {
    id: 48836,
    name: 'Ophiuroidea',
    common_name: 'Brittle Stars',
    parent_id: 481959,
    ancestor_ids: [48460, 1, 47549, 481959, 48836],
    rank: 'class',
  },
  {
    id: 196963,
    name: 'Cyanophyceae',
    common_name: 'Cyanobacteria',
    parent_id: 67334,
    ancestor_ids: [48460, 67333, 67334, 196963],
    rank: 'class',
  },
  {
    id: 48394,
    name: 'Gymnolaemata',
    common_name: null,
    parent_id: 68104,
    ancestor_ids: [48460, 1, 68104, 48394],
    rank: 'class',
  },
  {
    id: 774724,
    name: 'Conjugatophyceae',
    common_name: 'Conjugating green algae',
    parent_id: 311313,
    ancestor_ids: [48460, 47126, 311313, 774724],
    rank: 'class',
  },
  {
    id: 117868,
    name: 'Eurotiomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 117868],
    rank: 'class',
  },
  {
    id: 1443445,
    name: 'Ceratiomyxomycetes',
    common_name: null,
    parent_id: 47685,
    ancestor_ids: [48460, 47686, 47685, 1443445],
    rank: 'class',
  },
  {
    id: 48309,
    name: 'Merostomata',
    common_name: 'Horseshoe Crabs',
    parent_id: 245097,
    ancestor_ids: [48460, 1, 47120, 245097, 48309],
    rank: 'class',
  },
  {
    id: 60395,
    name: 'Branchiopoda',
    common_name: 'Branchiopods',
    parent_id: 85493,
    ancestor_ids: [48460, 1, 47120, 85493, 60395],
    rank: 'class',
  },
  {
    id: 794035,
    name: 'Mucoromycetes',
    common_name: null,
    parent_id: 1094433,
    ancestor_ids: [48460, 47170, 1094433, 794035],
    rank: 'class',
  },
  {
    id: 59713,
    name: 'Chlorophyceae',
    common_name: 'Chlorophycean green algae',
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 59713],
    rank: 'class',
  },
  {
    id: 152035,
    name: 'Taphrinomycetes',
    common_name: null,
    parent_id: 791576,
    ancestor_ids: [48460, 47170, 48250, 791576, 152035],
    rank: 'class',
  },
  {
    id: 130023,
    name: 'Exobasidiomycetes',
    common_name: null,
    parent_id: 951335,
    ancestor_ids: [48460, 47170, 47169, 951335, 130023],
    rank: 'class',
  },
  {
    id: 794017,
    name: 'Entomophthoromycetes',
    common_name: null,
    parent_id: 372742,
    ancestor_ids: [48460, 47170, 55113, 372742, 794017],
    rank: 'class',
  },
  {
    id: 83703,
    name: 'Alphaproteobacteria',
    common_name: '\u03b1-proteobacteria',
    parent_id: 83702,
    ancestor_ids: [48460, 67333, 83702, 83703],
    rank: 'class',
  },
  {
    id: 51245,
    name: 'Crinoidea',
    common_name: 'Sea Lilies',
    parent_id: 774752,
    ancestor_ids: [48460, 1, 47549, 774752, 51245],
    rank: 'class',
  },
  {
    id: 126918,
    name: 'Eurotatoria',
    common_name: null,
    parent_id: 126917,
    ancestor_ids: [48460, 1, 126917, 126918],
    rank: 'class',
  },
  {
    id: 151788,
    name: 'Charophyceae',
    common_name: null,
    parent_id: 311313,
    ancestor_ids: [48460, 47126, 311313, 151788],
    rank: 'class',
  },
  {
    id: 152067,
    name: 'Oligohymenophorea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 152067],
    rank: 'class',
  },
  {
    id: 117708,
    name: 'Tentaculata',
    common_name: 'Tentaculate Comb Jellies',
    parent_id: 51508,
    ancestor_ids: [48460, 1, 51508, 117708],
    rank: 'class',
  },
  {
    id: 51104,
    name: 'Thaliacea',
    common_name: 'Thaliaceans',
    parent_id: 130868,
    ancestor_ids: [48460, 1, 2, 130868, 51104],
    rank: 'class',
  },
  {
    id: 152037,
    name: 'Atractiellomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 152037],
    rank: 'class',
  },
  {
    id: 142257,
    name: 'Euglenida',
    common_name: 'euglenids',
    parent_id: 142256,
    ancestor_ids: [48460, 47686, 142256, 142257],
    rank: 'class',
  },
  {
    id: 83712,
    name: 'Ustilaginomycetes',
    common_name: 'Smut fungi',
    parent_id: 951335,
    ancestor_ids: [48460, 47170, 47169, 951335, 83712],
    rank: 'class',
  },
  {
    id: 68764,
    name: 'Ostracoda',
    common_name: 'Ostracods',
    parent_id: 473792,
    ancestor_ids: [48460, 1, 47120, 85493, 473792, 68764],
    rank: 'class',
  },
  {
    id: 1273102,
    name: 'Peronosporomycetes',
    common_name: null,
    parent_id: 52595,
    ancestor_ids: [48460, 48222, 52595, 1273102],
    rank: 'class',
  },
  {
    id: 152029,
    name: 'Laboulbeniomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 152029],
    rank: 'class',
  },
  {
    id: 126705,
    name: 'Gammaproteobacteria',
    common_name: '\u03b3-proteobacteria',
    parent_id: 83702,
    ancestor_ids: [48460, 67333, 83702, 126705],
    rank: 'class',
  },
  {
    id: 551611,
    name: 'Chromadorea',
    common_name: 'Chromadoreans',
    parent_id: 54960,
    ancestor_ids: [48460, 1, 54960, 551611],
    rank: 'class',
  },
  {
    id: 151771,
    name: 'Bangiophyceae',
    common_name: null,
    parent_id: 1360860,
    ancestor_ids: [48460, 47126, 57774, 1360860, 151771],
    rank: 'class',
  },
  {
    id: 68105,
    name: 'Phylactolaemata',
    common_name: 'Freshwater bryozoans',
    parent_id: 68104,
    ancestor_ids: [48460, 1, 68104, 68105],
    rank: 'class',
  },
  {
    id: 152031,
    name: 'Orbiliomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 152031],
    rank: 'class',
  },
  {
    id: 152071,
    name: 'Spirotrichea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 152071],
    rank: 'class',
  },
  {
    id: 60583,
    name: 'Calcarea',
    common_name: 'Calcareous Sponges',
    parent_id: 48824,
    ancestor_ids: [48460, 1, 48824, 60583],
    rank: 'class',
  },
  {
    id: 151986,
    name: 'Mollicutes',
    common_name: 'mollicutes',
    parent_id: 151853,
    ancestor_ids: [48460, 67333, 151853, 151986],
    rank: 'class',
  },
  {
    id: 768624,
    name: 'Tubulinea',
    common_name: null,
    parent_id: 245928,
    ancestor_ids: [48460, 47686, 372735, 245928, 768624],
    rank: 'class',
  },
  {
    id: 49231,
    name: 'Petromyzonti',
    common_name: 'Lampreys',
    parent_id: 797045,
    ancestor_ids: [48460, 1, 2, 355675, 797045, 49231],
    rank: 'class',
  },
  {
    id: 130225,
    name: 'Dinophyceae',
    common_name: null,
    parent_id: 1410906,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410906, 130225],
    rank: 'class',
  },
  {
    id: 47825,
    name: 'Pycnogonida',
    common_name: 'Pycnogonids',
    parent_id: 245097,
    ancestor_ids: [48460, 1, 47120, 245097, 47825],
    rank: 'class',
  },
  {
    id: 53659,
    name: 'Xanthophyceae',
    common_name: 'Yellow-green algae',
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 53659],
    rank: 'class',
  },
  {
    id: 1128019,
    name: 'Ellioviricetes',
    common_name: null,
    parent_id: 1128018,
    ancestor_ids: [48460, 131236, 1128016, 1128018, 1128019],
    rank: 'class',
  },
  {
    id: 84638,
    name: 'Symphyla',
    common_name: 'Symphylans',
    parent_id: 144128,
    ancestor_ids: [48460, 1, 47120, 144128, 84638],
    rank: 'class',
  },
  {
    id: 551700,
    name: 'Hoplonemertea',
    common_name: null,
    parent_id: 51280,
    ancestor_ids: [48460, 1, 51280, 551700],
    rank: 'class',
  },
  {
    id: 55164,
    name: 'Trebouxiophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 55164],
    rank: 'class',
  },
  {
    id: 152061,
    name: 'Heterotrichea',
    common_name: null,
    parent_id: 1276097,
    ancestor_ids: [48460, 48222, 118996, 1276097, 152061],
    rank: 'class',
  },
  {
    id: 785242,
    name: 'Tetraphidopsida',
    common_name: 'unjointed-tooth mosses',
    parent_id: 311249,
    ancestor_ids: [48460, 47126, 311249, 785242],
    rank: 'class',
  },
  {
    id: 56328,
    name: 'Anthocerotopsida',
    common_name: null,
    parent_id: 56327,
    ancestor_ids: [48460, 47126, 56327, 56328],
    rank: 'class',
  },
  {
    id: 1128038,
    name: 'Stelpaviricetes',
    common_name: null,
    parent_id: 1128035,
    ancestor_ids: [48460, 131236, 1128035, 1128038],
    rank: 'class',
  },
  {
    id: 128063,
    name: 'Stenolaemata',
    common_name: null,
    parent_id: 68104,
    ancestor_ids: [48460, 1, 68104, 128063],
    rank: 'class',
  },
  {
    id: 861340,
    name: 'Pilidiophora',
    common_name: null,
    parent_id: 51280,
    ancestor_ids: [48460, 1, 51280, 861340],
    rank: 'class',
  },
  {
    id: 152097,
    name: 'Eutardigrada',
    common_name: 'Smooth-bodied Tardigrades',
    parent_id: 124337,
    ancestor_ids: [48460, 1, 124337, 152097],
    rank: 'class',
  },
  {
    id: 51509,
    name: 'Nuda',
    common_name: null,
    parent_id: 51508,
    ancestor_ids: [48460, 1, 51508, 51509],
    rank: 'class',
  },
  {
    id: 60450,
    name: 'Holocephali',
    common_name: 'Chimaeras',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 60450],
    rank: 'class',
  },
  {
    id: 152042,
    name: 'Microbotryomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 152042],
    rank: 'class',
  },
  {
    id: 125763,
    name: 'Neolectomycetes',
    common_name: null,
    parent_id: 791576,
    ancestor_ids: [48460, 47170, 48250, 791576, 125763],
    rank: 'class',
  },
  {
    id: 348289,
    name: 'Globothalamea',
    common_name: null,
    parent_id: 348288,
    ancestor_ids: [48460, 48222, 348288, 348289],
    rank: 'class',
  },
  {
    id: 152081,
    name: 'Andreaeopsida',
    common_name: null,
    parent_id: 311249,
    ancestor_ids: [48460, 47126, 311249, 152081],
    rank: 'class',
  },
  {
    id: 126608,
    name: 'Betaproteobacteria',
    common_name: '\u03b2-proteobacteria',
    parent_id: 83702,
    ancestor_ids: [48460, 67333, 83702, 126608],
    rank: 'class',
  },
  {
    id: 152065,
    name: 'Litostomatea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 152065],
    rank: 'class',
  },
  {
    id: 122159,
    name: 'Rhynchonellata',
    common_name: 'Articulate Brachiopods',
    parent_id: 551549,
    ancestor_ids: [48460, 1, 122158, 551549, 122159],
    rank: 'class',
  },
  {
    id: 152110,
    name: 'Hexactinellida',
    common_name: 'Glass Sponges',
    parent_id: 48824,
    ancestor_ids: [48460, 1, 48824, 152110],
    rank: 'class',
  },
  {
    id: 551699,
    name: 'Palaeonemertea',
    common_name: null,
    parent_id: 51280,
    ancestor_ids: [48460, 1, 51280, 551699],
    rank: 'class',
  },
  {
    id: 68095,
    name: 'Cubozoa',
    common_name: 'Box Jellies',
    parent_id: 47534,
    ancestor_ids: [48460, 1, 47534, 68095],
    rank: 'class',
  },
  {
    id: 1127202,
    name: 'Revtraviricetes',
    common_name: null,
    parent_id: 1127201,
    ancestor_ids: [48460, 131236, 1127201, 1127202],
    rank: 'class',
  },
  {
    id: 152069,
    name: 'Phyllopharyngea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 152069],
    rank: 'class',
  },
  {
    id: 1123685,
    name: 'Alsuviricetes',
    common_name: null,
    parent_id: 1123684,
    ancestor_ids: [48460, 131236, 1123684, 1123685],
    rank: 'class',
  },
  {
    id: 551701,
    name: 'Gordioida',
    common_name: null,
    parent_id: 63142,
    ancestor_ids: [48460, 1, 63142, 551701],
    rank: 'class',
  },
  {
    id: 67990,
    name: 'Scaphopoda',
    common_name: 'Tusk Shells',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 67990],
    rank: 'class',
  },
  {
    id: 152022,
    name: 'Synurophyceae',
    common_name: 'Synurids',
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 152022],
    rank: 'class',
  },
  {
    id: 794008,
    name: 'Synchytriomycetes',
    common_name: null,
    parent_id: 125609,
    ancestor_ids: [48460, 47170, 125609, 794008],
    rank: 'class',
  },
  {
    id: 51018,
    name: 'Staurozoa',
    common_name: 'Stalked Jellies',
    parent_id: 47534,
    ancestor_ids: [48460, 1, 47534, 51018],
    rank: 'class',
  },
  {
    id: 121726,
    name: 'Trematoda',
    common_name: 'Flukes',
    parent_id: 551552,
    ancestor_ids: [48460, 1, 52319, 152105, 551552, 121726],
    rank: 'class',
  },
  {
    id: 152075,
    name: 'Compsopogonophyceae',
    common_name: null,
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 152075],
    rank: 'class',
  },
  {
    id: 1125532,
    name: 'Megaviricetes',
    common_name: null,
    parent_id: 1125531,
    ancestor_ids: [48460, 131236, 1125531, 1125532],
    rank: 'class',
  },
  {
    id: 51943,
    name: 'Chrysophyceae',
    common_name: 'Golden algae',
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 51943],
    rank: 'class',
  },
  {
    id: 152111,
    name: 'Homoscleromorpha',
    common_name: null,
    parent_id: 48824,
    ancestor_ids: [48460, 1, 48824, 152111],
    rank: 'class',
  },
  {
    id: 334684,
    name: 'Imbricatea',
    common_name: null,
    parent_id: 1276042,
    ancestor_ids: [48460, 48222, 151873, 1185636, 1276042, 334684],
    rank: 'class',
  },
  {
    id: 152070,
    name: 'Prostomatea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 152070],
    rank: 'class',
  },
  {
    id: 1128037,
    name: 'Pisoniviricetes',
    common_name: null,
    parent_id: 1128035,
    ancestor_ids: [48460, 131236, 1128035, 1128037],
    rank: 'class',
  },
  {
    id: 152102,
    name: 'Cestoda',
    common_name: 'tapeworms',
    parent_id: 551552,
    ancestor_ids: [48460, 1, 52319, 152105, 551552, 152102],
    rank: 'class',
  },
  {
    id: 510535,
    name: 'Enoplea',
    common_name: 'Enopleans',
    parent_id: 54960,
    ancestor_ids: [48460, 1, 54960, 510535],
    rank: 'class',
  },
  {
    id: 362002,
    name: 'Dinosauriformes',
    common_name: 'Dinosaurs',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 362002],
    rank: 'class',
  },
  {
    id: 124901,
    name: 'Cryptophyceae',
    common_name: 'cryptomonads',
    parent_id: 792081,
    ancestor_ids: [48460, 48222, 792081, 124901],
    rank: 'class',
  },
  {
    id: 83715,
    name: 'Saccharomycetes',
    common_name: 'budding yeasts',
    parent_id: 793974,
    ancestor_ids: [48460, 47170, 48250, 793974, 83715],
    rank: 'class',
  },
  {
    id: 1092973,
    name: 'Bartheletiomycetes',
    common_name: null,
    parent_id: 492000,
    ancestor_ids: [48460, 47170, 47169, 492000, 1092973],
    rank: 'class',
  },
  {
    id: 348305,
    name: 'Tubothalamea',
    common_name: null,
    parent_id: 348288,
    ancestor_ids: [48460, 48222, 348288, 348305],
    rank: 'class',
  },
  {
    id: 205907,
    name: 'Trilobita',
    common_name: 'Trilobites',
    parent_id: 47120,
    ancestor_ids: [48460, 1, 47120, 205907],
    rank: 'class',
  },
  {
    id: 1272894,
    name: 'Nucleohelea',
    common_name: null,
    parent_id: 1185618,
    ancestor_ids: [48460, 48222, 792746, 1185618, 1272894],
    rank: 'class',
  },
  {
    id: 83243,
    name: 'Pauropoda',
    common_name: 'pauropodans',
    parent_id: 144128,
    ancestor_ids: [48460, 1, 47120, 144128, 83243],
    rank: 'class',
  },
  {
    id: 794034,
    name: 'Endogonomycetes',
    common_name: null,
    parent_id: 1094433,
    ancestor_ids: [48460, 47170, 1094433, 794034],
    rank: 'class',
  },
  {
    id: 152119,
    name: 'Enteropneusta',
    common_name: 'Acorn Worms',
    parent_id: 68235,
    ancestor_ids: [48460, 1, 68235, 152119],
    rank: 'class',
  },
  {
    id: 1464834,
    name: 'Noctilucophyceae',
    common_name: null,
    parent_id: 1410906,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410906, 1464834],
    rank: 'class',
  },
  {
    id: 473793,
    name: 'Ichthyostraca',
    common_name: 'Branchiurans and Tongue Worms',
    parent_id: 473792,
    ancestor_ids: [48460, 1, 47120, 85493, 473792, 473793],
    rank: 'class',
  },
  {
    id: 151984,
    name: 'Bacilli',
    common_name: 'aerobic endospore-forming bacteria',
    parent_id: 151853,
    ancestor_ids: [48460, 67333, 151853, 151984],
    rank: 'class',
  },
  {
    id: 152098,
    name: 'Heterotardigrada',
    common_name: 'Rough-bodied Tardigrades',
    parent_id: 124337,
    ancestor_ids: [48460, 1, 124337, 152098],
    rank: 'class',
  },
  {
    id: 152082,
    name: 'Klebsormidiophyceae',
    common_name: null,
    parent_id: 311313,
    ancestor_ids: [48460, 47126, 311313, 152082],
    rank: 'class',
  },
  {
    id: 1125533,
    name: 'Pokkesviricetes',
    common_name: null,
    parent_id: 1125531,
    ancestor_ids: [48460, 131236, 1125531, 1125533],
    rank: 'class',
  },
  {
    id: 85497,
    name: 'Sarcopterygii',
    common_name: 'Lobe-finned Fishes',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 85497],
    rank: 'class',
  },
  {
    id: 951844,
    name: 'Collemopsidiomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 951844],
    rank: 'class',
  },
  {
    id: 129725,
    name: 'Appendicularia',
    common_name: 'Pelagic Tunicates',
    parent_id: 130868,
    ancestor_ids: [48460, 1, 2, 130868, 129725],
    rank: 'class',
  },
  {
    id: 972929,
    name: 'Choanoflagellatea',
    common_name: 'choanoflagellates',
    parent_id: 151874,
    ancestor_ids: [48460, 47686, 151874, 972929],
    rank: 'class',
  },
  {
    id: 49099,
    name: 'Myxini',
    common_name: 'Hagfishes',
    parent_id: 797045,
    ancestor_ids: [48460, 1, 2, 355675, 797045, 49099],
    rank: 'class',
  },
  {
    id: 1273103,
    name: 'Saprolegniomycetes',
    common_name: null,
    parent_id: 52595,
    ancestor_ids: [48460, 48222, 52595, 1273103],
    rank: 'class',
  },
  {
    id: 152056,
    name: 'Conoidasida',
    common_name: null,
    parent_id: 1410902,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410902, 152056],
    rank: 'class',
  },
  {
    id: 152058,
    name: 'Colpodea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 152058],
    rank: 'class',
  },
  {
    id: 517587,
    name: 'Discosea',
    common_name: null,
    parent_id: 245928,
    ancestor_ids: [48460, 47686, 372735, 245928, 517587],
    rank: 'class',
  },
  {
    id: 152063,
    name: 'Karyorelictea',
    common_name: null,
    parent_id: 1276097,
    ancestor_ids: [48460, 48222, 118996, 1276097, 152063],
    rank: 'class',
  },
  {
    id: 152079,
    name: 'Haplomitriopsida',
    common_name: null,
    parent_id: 64615,
    ancestor_ids: [48460, 47126, 64615, 152079],
    rank: 'class',
  },
  {
    id: 1134333,
    name: 'Papovaviricetes',
    common_name: null,
    parent_id: 1134331,
    ancestor_ids: [48460, 131236, 1134331, 1134333],
    rank: 'class',
  },
  {
    id: 152016,
    name: 'Eustigmatophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 152016],
    rank: 'class',
  },
  {
    id: 133972,
    name: 'Lingulata',
    common_name: 'Lingulate Brachiopods',
    parent_id: 551548,
    ancestor_ids: [48460, 1, 122158, 551548, 133972],
    rank: 'class',
  },
  {
    id: 152875,
    name: 'Leptocardii',
    common_name: 'Lancelets',
    parent_id: 129726,
    ancestor_ids: [48460, 1, 2, 129726, 152875],
    rank: 'class',
  },
  {
    id: 1128015,
    name: 'Herviviricetes',
    common_name: 'Herpesviruses',
    parent_id: 1128014,
    ancestor_ids: [48460, 131236, 1128014, 1128015],
    rank: 'class',
  },
  {
    id: 1123688,
    name: 'Tolucaviricetes',
    common_name: null,
    parent_id: 1123684,
    ancestor_ids: [48460, 131236, 1123684, 1123688],
    rank: 'class',
  },
  {
    id: 152046,
    name: 'Polycystina',
    common_name: null,
    parent_id: 211193,
    ancestor_ids: [48460, 48222, 211193, 152046],
    rank: 'class',
  },
  {
    id: 942252,
    name: 'Kinetoplastea',
    common_name: null,
    parent_id: 142256,
    ancestor_ids: [48460, 47686, 142256, 942252],
    rank: 'class',
  },
  {
    id: 1115004,
    name: 'Armophorea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 1115004],
    rank: 'class',
  },
  {
    id: 152026,
    name: 'Glomeromycetes',
    common_name: null,
    parent_id: 151868,
    ancestor_ids: [48460, 47170, 151868, 152026],
    rank: 'class',
  },
  {
    id: 794031,
    name: 'Mortierellomycetes',
    common_name: null,
    parent_id: 794030,
    ancestor_ids: [48460, 47170, 55113, 794030, 794031],
    rank: 'class',
  },
  {
    id: 152021,
    name: 'Raphidophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 152021],
    rank: 'class',
  },
  {
    id: 1145195,
    name: 'Repensiviricetes',
    common_name: null,
    parent_id: 1145193,
    ancestor_ids: [48460, 131236, 1145193, 1145195],
    rank: 'class',
  },
  {
    id: 152066,
    name: 'Nassophorea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 152066],
    rank: 'class',
  },
  {
    id: 152008,
    name: 'Deltaproteobacteria',
    common_name: '\u03b4-proteobacteria',
    parent_id: 83702,
    ancestor_ids: [48460, 67333, 83702, 152008],
    rank: 'class',
  },
  {
    id: 1182089,
    name: 'Nodosariata',
    common_name: null,
    parent_id: 348288,
    ancestor_ids: [48460, 48222, 348288, 1182089],
    rank: 'class',
  },
  {
    id: 828871,
    name: 'Coccolithophyceae',
    common_name: null,
    parent_id: 51845,
    ancestor_ids: [48460, 48222, 51845, 828871],
    rank: 'class',
  },
  {
    id: 152023,
    name: 'Blastocladiomycetes',
    common_name: null,
    parent_id: 1094423,
    ancestor_ids: [48460, 47170, 1094423, 152023],
    rank: 'class',
  },
  {
    id: 209067,
    name: 'Monogenea',
    common_name: 'One-host Flukes',
    parent_id: 551552,
    ancestor_ids: [48460, 1, 52319, 152105, 551552, 209067],
    rank: 'class',
  },
  {
    id: 152015,
    name: 'Dictyochophyceae',
    common_name: 'Silicoflagellates',
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 152015],
    rank: 'class',
  },
  {
    id: 1458001,
    name: 'Palmophyllophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 1458001],
    rank: 'class',
  },
  {
    id: 493192,
    name: 'Granofilosea',
    common_name: null,
    parent_id: 1185636,
    ancestor_ids: [48460, 48222, 151873, 1185636, 493192],
    rank: 'class',
  },
  {
    id: 1464191,
    name: 'Coleochaetophyceae',
    common_name: null,
    parent_id: 311313,
    ancestor_ids: [48460, 47126, 311313, 1464191],
    rank: 'class',
  },
  {
    id: 1128025,
    name: 'Monjiviricetes',
    common_name: null,
    parent_id: 1128017,
    ancestor_ids: [48460, 131236, 1128016, 1128017, 1128025],
    rank: 'class',
  },
  {
    id: 1276034,
    name: 'Vampyrellidea',
    common_name: null,
    parent_id: 1276035,
    ancestor_ids: [48460, 48222, 151873, 1276035, 1276034],
    rank: 'class',
  },
  {
    id: 372764,
    name: 'Solenogastres',
    common_name: 'Solenogasters',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 372764],
    rank: 'class',
  },
  {
    id: 794046,
    name: 'Zoopagomycetes',
    common_name: null,
    parent_id: 55113,
    ancestor_ids: [48460, 47170, 55113, 794046],
    rank: 'class',
  },
  {
    id: 152010,
    name: 'Labyrinthulea',
    common_name: null,
    parent_id: 792746,
    ancestor_ids: [48460, 48222, 792746, 152010],
    rank: 'class',
  },
  {
    id: 497927,
    name: 'Monothalamea',
    common_name: null,
    parent_id: 348288,
    ancestor_ids: [48460, 48222, 348288, 497927],
    rank: 'class',
  },
  {
    id: 424900,
    name: 'Gromiidea',
    common_name: null,
    parent_id: 1276035,
    ancestor_ids: [48460, 48222, 151873, 1276035, 424900],
    rank: 'class',
  },
  {
    id: 246116,
    name: 'Sagittoidea',
    common_name: null,
    parent_id: 151827,
    ancestor_ids: [48460, 1, 151827, 246116],
    rank: 'class',
  },
  {
    id: 796412,
    name: 'Takakiopsida',
    common_name: null,
    parent_id: 311249,
    ancestor_ids: [48460, 47126, 311249, 796412],
    rank: 'class',
  },
  {
    id: 152055,
    name: 'Phytomyxea',
    common_name: null,
    parent_id: 1276035,
    ancestor_ids: [48460, 48222, 151873, 1276035, 152055],
    rank: 'class',
  },
  {
    id: 152089,
    name: 'Halobacteria',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152089],
    rank: 'class',
  },
  {
    id: 738220,
    name: 'Thecofilosea',
    common_name: null,
    parent_id: 1276042,
    ancestor_ids: [48460, 48222, 151873, 1185636, 1276042, 738220],
    rank: 'class',
  },
  {
    id: 55114,
    name: 'Zygomycetes',
    common_name: null,
    parent_id: 55113,
    ancestor_ids: [48460, 47170, 55113, 55114],
    rank: 'class',
  },
  {
    id: 125610,
    name: 'Chytridiomycetes',
    common_name: null,
    parent_id: 125609,
    ancestor_ids: [48460, 47170, 125609, 125610],
    rank: 'class',
  },
  {
    id: 1308221,
    name: 'Archamoebea',
    common_name: null,
    parent_id: 1308220,
    ancestor_ids: [48460, 47686, 372735, 1308213, 1308220, 1308221],
    rank: 'class',
  },
  {
    id: 152040,
    name: 'Cystobasidiomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 152040],
    rank: 'class',
  },
  {
    id: 152048,
    name: 'Heterolobosea',
    common_name: null,
    parent_id: 151879,
    ancestor_ids: [48460, 47686, 151879, 152048],
    rank: 'class',
  },
  {
    id: 326261,
    name: 'Glaucophyceae',
    common_name: null,
    parent_id: 311312,
    ancestor_ids: [48460, 47126, 311312, 326261],
    rank: 'class',
  },
  {
    id: 152109,
    name: 'Palaeacanthocephala',
    common_name: null,
    parent_id: 151826,
    ancestor_ids: [48460, 1, 151826, 152109],
    rank: 'class',
  },
  {
    id: 794003,
    name: 'Rhizophydiomycetes',
    common_name: null,
    parent_id: 125609,
    ancestor_ids: [48460, 47170, 125609, 794003],
    rank: 'class',
  },
  {
    id: 525114,
    name: 'Strophomenata',
    common_name: null,
    parent_id: 122158,
    ancestor_ids: [48460, 1, 122158, 525114],
    rank: 'class',
  },
  {
    id: 152036,
    name: 'Agaricostilbomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 152036],
    rank: 'class',
  },
  {
    id: 152087,
    name: 'Thermoprotei',
    common_name: null,
    parent_id: 151824,
    ancestor_ids: [48460, 151817, 151824, 152087],
    rank: 'class',
  },
  {
    id: 152019,
    name: 'Pelagophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 152019],
    rank: 'class',
  },
  {
    id: 942618,
    name: 'Xylobotryomycetes',
    common_name: null,
    parent_id: 372740,
    ancestor_ids: [48460, 47170, 48250, 372740, 942618],
    rank: 'class',
  },
  {
    id: 151985,
    name: 'Clostridia',
    common_name: 'anaerobic endospore-forming bacteria',
    parent_id: 151853,
    ancestor_ids: [48460, 67333, 151853, 151985],
    rank: 'class',
  },
  {
    id: 1464188,
    name: 'Chlorodendrophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 1464188],
    rank: 'class',
  },
  {
    id: 794097,
    name: 'Bikosea',
    common_name: null,
    parent_id: 792746,
    ancestor_ids: [48460, 48222, 792746, 794097],
    rank: 'class',
  },
  {
    id: 1130283,
    name: 'Cristidiscoidea',
    common_name: null,
    parent_id: 151874,
    ancestor_ids: [48460, 47686, 151874, 1130283],
    rank: 'class',
  },
  {
    id: 1145194,
    name: 'Arfiviricetes',
    common_name: null,
    parent_id: 1145193,
    ancestor_ids: [48460, 131236, 1145193, 1145194],
    rank: 'class',
  },
  {
    id: 1472594,
    name: 'Trichomonadea',
    common_name: null,
    parent_id: 796988,
    ancestor_ids: [48460, 47686, 796988, 1472594],
    rank: 'class',
  },
  {
    id: 1111206,
    name: 'Malasseziomycetes',
    common_name: null,
    parent_id: 951335,
    ancestor_ids: [48460, 47170, 47169, 951335, 1111206],
    rank: 'class',
  },
  {
    id: 152025,
    name: 'Microsporea',
    common_name: null,
    parent_id: 151869,
    ancestor_ids: [48460, 47686, 151869, 152025],
    rank: 'class',
  },
  {
    id: 423802,
    name: 'Acantharea',
    common_name: 'Acantharia',
    parent_id: 211193,
    ancestor_ids: [48460, 48222, 211193, 423802],
    rank: 'class',
  },
  {
    id: 551168,
    name: 'Graptolithoidea',
    common_name: 'Pterobranchs',
    parent_id: 68235,
    ancestor_ids: [48460, 1, 68235, 551168],
    rank: 'class',
  },
  {
    id: 550638,
    name: 'Caudofoveata',
    common_name: 'Caudofoveates',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 550638],
    rank: 'class',
  },
  {
    id: 424296,
    name: 'Acantharia',
    common_name: null,
    parent_id: 211193,
    ancestor_ids: [48460, 48222, 211193, 424296],
    rank: 'class',
  },
  {
    id: 550917,
    name: 'Stylonematophyceae',
    common_name: null,
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 550917],
    rank: 'class',
  },
  {
    id: 1276093,
    name: 'Plagiopylea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 1276093],
    rank: 'class',
  },
  {
    id: 356590,
    name: 'Cyanidiophyceae',
    common_name: null,
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 356590],
    rank: 'class',
  },
  {
    id: 152118,
    name: 'Craniata',
    common_name: 'Craniate Brachiopods',
    parent_id: 551547,
    ancestor_ids: [48460, 1, 122158, 551547, 152118],
    rank: 'class',
  },
  {
    id: 1276134,
    name: 'Proteomyxidea',
    common_name: null,
    parent_id: 1276035,
    ancestor_ids: [48460, 48222, 151873, 1276035, 1276134],
    rank: 'class',
  },
  {
    id: 119001,
    name: 'Dictyosteliomycetes',
    common_name: 'Dictyostelids',
    parent_id: 47685,
    ancestor_ids: [48460, 47686, 47685, 119001],
    rank: 'class',
  },
  {
    id: 119313,
    name: 'Epsilonproteobacteria',
    common_name: '\u03b5-proteobacteria',
    parent_id: 83702,
    ancestor_ids: [48460, 67333, 83702, 119313],
    rank: 'class',
  },
  {
    id: 152003,
    name: 'Flavobacteria',
    common_name: null,
    parent_id: 151844,
    ancestor_ids: [48460, 67333, 151844, 152003],
    rank: 'class',
  },
  {
    id: 714997,
    name: 'Placodermi',
    common_name: 'Placoderms',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 714997],
    rank: 'class',
  },
  {
    id: 1134334,
    name: 'Quintoviricetes',
    common_name: null,
    parent_id: 1134331,
    ancestor_ids: [48460, 131236, 1134331, 1134334],
    rank: 'class',
  },
  {
    id: 152083,
    name: 'Mesostigmatophyceae',
    common_name: null,
    parent_id: 311313,
    ancestor_ids: [48460, 47126, 311313, 152083],
    rank: 'class',
  },
  {
    id: 1128020,
    name: 'Insthoviricetes',
    common_name: null,
    parent_id: 1128018,
    ancestor_ids: [48460, 131236, 1128016, 1128018, 1128020],
    rank: 'class',
  },
  {
    id: 333781,
    name: 'Dinocaridida',
    common_name: 'Dinocarididans',
    parent_id: 47120,
    ancestor_ids: [48460, 1, 47120, 333781],
    rank: 'class',
  },
  {
    id: 144130,
    name: 'Remipedia',
    common_name: 'Remipedes',
    parent_id: 85493,
    ancestor_ids: [48460, 1, 47120, 85493, 144130],
    rank: 'class',
  },
  {
    id: 1095923,
    name: 'Diphyllatea',
    common_name: null,
    parent_id: 796989,
    ancestor_ids: [48460, 47686, 796989, 1095923],
    rank: 'class',
  },
  {
    id: 152043,
    name: 'Mixiomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 152043],
    rank: 'class',
  },
  {
    id: 1470975,
    name: 'Odontostomatea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 1470975],
    rank: 'class',
  },
  {
    id: 151995,
    name: 'Deinococci',
    common_name: null,
    parent_id: 151850,
    ancestor_ids: [48460, 67333, 151850, 151995],
    rank: 'class',
  },
  {
    id: 793998,
    name: 'Cladochytriomycetes',
    common_name: null,
    parent_id: 125609,
    ancestor_ids: [48460, 47170, 125609, 793998],
    rank: 'class',
  },
  {
    id: 152107,
    name: 'Archiacanthocephala',
    common_name: null,
    parent_id: 151826,
    ancestor_ids: [48460, 1, 151826, 152107],
    rank: 'class',
  },
  {
    id: 56254,
    name: 'Protosteliomycetes',
    common_name: 'protostelid slime molds',
    parent_id: 372735,
    ancestor_ids: [48460, 47686, 372735, 56254],
    rank: 'class',
  },
  {
    id: 1128034,
    name: 'Caudoviricetes',
    common_name: 'Tailed bacteriophages',
    parent_id: 1128033,
    ancestor_ids: [48460, 131236, 1128033, 1128034],
    rank: 'class',
  },
  {
    id: 1123686,
    name: 'Flasuviricetes',
    common_name: null,
    parent_id: 1123684,
    ancestor_ids: [48460, 131236, 1123684, 1123686],
    rank: 'class',
  },
  {
    id: 465135,
    name: 'Prasinophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 465135],
    rank: 'class',
  },
  {
    id: 551292,
    name: 'Cyclorhagida',
    common_name: null,
    parent_id: 151832,
    ancestor_ids: [48460, 1, 151832, 551292],
    rank: 'class',
  },
  {
    id: 152044,
    name: 'Wallemiomycetes',
    common_name: null,
    parent_id: 1110629,
    ancestor_ids: [48460, 47170, 47169, 1110629, 152044],
    rank: 'class',
  },
  {
    id: 1256521,
    name: 'Percolatea',
    common_name: null,
    parent_id: 151879,
    ancestor_ids: [48460, 47686, 151879, 1256521],
    rank: 'class',
  },
  {
    id: 550904,
    name: 'Porphyridiophyceae',
    common_name: null,
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 550904],
    rank: 'class',
  },
  {
    id: 503500,
    name: 'Ellobiopsea',
    common_name: null,
    parent_id: 1410906,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410906, 503500],
    rank: 'class',
  },
  {
    id: 151990,
    name: 'Planctomycetacia',
    common_name: null,
    parent_id: 151858,
    ancestor_ids: [48460, 67333, 151858, 151990],
    rank: 'class',
  },
  {
    id: 152027,
    name: 'Monoblepharidomycetes',
    common_name: null,
    parent_id: 1094444,
    ancestor_ids: [48460, 47170, 1094444, 152027],
    rank: 'class',
  },
  {
    id: 151835,
    name: 'Myxozoa',
    common_name: 'Myxozoans',
    parent_id: 47534,
    ancestor_ids: [48460, 1, 47534, 151835],
    rank: 'class',
  },
  {
    id: 550919,
    name: 'Nephroselmidophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 550919],
    rank: 'class',
  },
  {
    id: 152084,
    name: 'Pedinophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 152084],
    rank: 'class',
  },
  {
    id: 152034,
    name: 'Schizosaccharomycetes',
    common_name: null,
    parent_id: 791576,
    ancestor_ids: [48460, 47170, 48250, 791576, 152034],
    rank: 'class',
  },
  {
    id: 152041,
    name: 'Entorrhizomycetes',
    common_name: null,
    parent_id: 1094432,
    ancestor_ids: [48460, 47170, 1094432, 152041],
    rank: 'class',
  },
  {
    id: 1046100,
    name: 'Rozellidea',
    common_name: null,
    parent_id: 151874,
    ancestor_ids: [48460, 47686, 151874, 1046100],
    rank: 'class',
  },
  {
    id: 1430417,
    name: 'Parablepharismea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 1430417],
    rank: 'class',
  },
  {
    id: 1150688,
    name: 'Blastocystea',
    common_name: null,
    parent_id: 792746,
    ancestor_ids: [48460, 48222, 792746, 1150688],
    rank: 'class',
  },
  {
    id: 1271542,
    name: 'Phaeothamniophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 1271542],
    rank: 'class',
  },
  {
    id: 551294,
    name: 'Allomalorhagida',
    common_name: null,
    parent_id: 151832,
    ancestor_ids: [48460, 1, 151832, 551294],
    rank: 'class',
  },
  {
    id: 152001,
    name: 'Chlorobia',
    common_name: null,
    parent_id: 151846,
    ancestor_ids: [48460, 67333, 151846, 152001],
    rank: 'class',
  },
  {
    id: 152004,
    name: 'Sphingobacteria',
    common_name: null,
    parent_id: 151844,
    ancestor_ids: [48460, 67333, 151844, 152004],
    rank: 'class',
  },
  {
    id: 1163950,
    name: 'Resentoviricetes',
    common_name: null,
    parent_id: 1145316,
    ancestor_ids: [48460, 131236, 1145316, 1163950],
    rank: 'class',
  },
  {
    id: 1428076,
    name: 'Muranotrichea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 1428076],
    rank: 'class',
  },
  {
    id: 1128024,
    name: 'Milneviricetes',
    common_name: null,
    parent_id: 1128017,
    ancestor_ids: [48460, 131236, 1128016, 1128017, 1128024],
    rank: 'class',
  },
  {
    id: 1135576,
    name: 'Zetaproteobacteria',
    common_name: '\u03b6-proteobacteria',
    parent_id: 83702,
    ancestor_ids: [48460, 67333, 83702, 1135576],
    rank: 'class',
  },
  {
    id: 152038,
    name: 'Classiculomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 152038],
    rank: 'class',
  },
  {
    id: 151839,
    name: 'Rhombozoa',
    common_name: 'Rhombozoans',
    parent_id: 774624,
    ancestor_ids: [48460, 1, 774624, 151839],
    rank: 'class',
  },
  {
    id: 1276096,
    name: 'Protocruziea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 1276096],
    rank: 'class',
  },
  {
    id: 1098097,
    name: 'Harpellomycetes',
    common_name: null,
    parent_id: 372741,
    ancestor_ids: [48460, 47170, 55113, 372741, 1098097],
    rank: 'class',
  },
  {
    id: 568355,
    name: 'Rostroconchia',
    common_name: 'Rostroconchs',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 568355],
    rank: 'class',
  },
  {
    id: 416489,
    name: 'Tritirachiomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 416489],
    rank: 'class',
  },
  {
    id: 551702,
    name: 'Nectonematoida',
    common_name: null,
    parent_id: 63142,
    ancestor_ids: [48460, 1, 63142, 551702],
    rank: 'class',
  },
  {
    id: 607357,
    name: 'Chlorokybophyceae',
    common_name: null,
    parent_id: 311313,
    ancestor_ids: [48460, 47126, 311313, 607357],
    rank: 'class',
  },
  {
    id: 152117,
    name: 'Pararotatoria',
    common_name: null,
    parent_id: 126917,
    ancestor_ids: [48460, 1, 126917, 152117],
    rank: 'class',
  },
  {
    id: 152033,
    name: 'Pneumocystidomycetes',
    common_name: null,
    parent_id: 791576,
    ancestor_ids: [48460, 47170, 48250, 791576, 152033],
    rank: 'class',
  },
  {
    id: 152091,
    name: 'Methanococci',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152091],
    rank: 'class',
  },
  {
    id: 1308224,
    name: 'Variosea',
    common_name: null,
    parent_id: 1308213,
    ancestor_ids: [48460, 47686, 372735, 1308213, 1308224],
    rank: 'class',
  },
  {
    id: 53528,
    name: 'Monoplacophora',
    common_name: 'Monoplacophorans',
    parent_id: 47115,
    ancestor_ids: [48460, 1, 47115, 53528],
    rank: 'class',
  },
  {
    id: 144129,
    name: 'Cephalocarida',
    common_name: 'Horseshoe Shrimps',
    parent_id: 85493,
    ancestor_ids: [48460, 1, 47120, 85493, 144129],
    rank: 'class',
  },
  {
    id: 152090,
    name: 'Methanobacteria',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152090],
    rank: 'class',
  },
  {
    id: 152076,
    name: 'Rhodellophyceae',
    common_name: null,
    parent_id: 57774,
    ancestor_ids: [48460, 47126, 57774, 152076],
    rank: 'class',
  },
  {
    id: 1271866,
    name: 'Synchromophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 1271866],
    rank: 'class',
  },
  {
    id: 152011,
    name: 'Hyphochytriomycetes',
    common_name: null,
    parent_id: 151864,
    ancestor_ids: [48460, 48222, 151864, 152011],
    rank: 'class',
  },
  {
    id: 1468869,
    name: 'Metromonadea',
    common_name: null,
    parent_id: 1276043,
    ancestor_ids: [48460, 48222, 151873, 1185636, 1276043, 1468869],
    rank: 'class',
  },
  {
    id: 152086,
    name: 'Leiosporocerotopsida',
    common_name: null,
    parent_id: 56327,
    ancestor_ids: [48460, 47126, 56327, 152086],
    rank: 'class',
  },
  {
    id: 152049,
    name: 'Perkinsea',
    common_name: null,
    parent_id: 1410907,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410907, 152049],
    rank: 'class',
  },
  {
    id: 550918,
    name: 'Mamiellophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 550918],
    rank: 'class',
  },
  {
    id: 793989,
    name: 'Basidiobolomycetes',
    common_name: null,
    parent_id: 1094442,
    ancestor_ids: [48460, 47170, 1094442, 793989],
    rank: 'class',
  },
  {
    id: 791577,
    name: 'Archaeorhizomycetes',
    common_name: null,
    parent_id: 791576,
    ancestor_ids: [48460, 47170, 48250, 791576, 791577],
    rank: 'class',
  },
  {
    id: 774725,
    name: 'Pyramimonadophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 774725],
    rank: 'class',
  },
  {
    id: 357099,
    name: 'Spartobacteria',
    common_name: null,
    parent_id: 151863,
    ancestor_ids: [48460, 67333, 151863, 357099],
    rank: 'class',
  },
  {
    id: 152108,
    name: 'Eoacanthocephala',
    common_name: null,
    parent_id: 151826,
    ancestor_ids: [48460, 1, 151826, 152108],
    rank: 'class',
  },
  {
    id: 151861,
    name: 'Thermomicrobia',
    common_name: null,
    parent_id: 151847,
    ancestor_ids: [48460, 67333, 151847, 151861],
    rank: 'class',
  },
  {
    id: 151979,
    name: 'Verrucomicrobiae',
    common_name: null,
    parent_id: 151863,
    ancestor_ids: [48460, 67333, 151863, 151979],
    rank: 'class',
  },
  {
    id: 151998,
    name: 'Anaerolineae',
    common_name: null,
    parent_id: 151847,
    ancestor_ids: [48460, 67333, 151847, 151998],
    rank: 'class',
  },
  {
    id: 151999,
    name: 'Caldilineae',
    common_name: null,
    parent_id: 151847,
    ancestor_ids: [48460, 67333, 151847, 151999],
    rank: 'class',
  },
  {
    id: 152020,
    name: 'Pinguiophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 152020],
    rank: 'class',
  },
  {
    id: 152024,
    name: 'Neocallimastigomycetes',
    common_name: null,
    parent_id: 125609,
    ancestor_ids: [48460, 47170, 125609, 152024],
    rank: 'class',
  },
  {
    id: 152039,
    name: 'Cryptomycocolacomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 152039],
    rank: 'class',
  },
  {
    id: 152072,
    name: 'Ebriophyceae',
    common_name: null,
    parent_id: 792744,
    ancestor_ids: [48460, 48222, 792744, 152072],
    rank: 'class',
  },
  {
    id: 152088,
    name: 'Archaeoglobi',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152088],
    rank: 'class',
  },
  {
    id: 152093,
    name: 'Methanopyri',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152093],
    rank: 'class',
  },
  {
    id: 152094,
    name: 'Thermococci',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152094],
    rank: 'class',
  },
  {
    id: 152095,
    name: 'Thermoplasmata',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152095],
    rank: 'class',
  },
  {
    id: 152099,
    name: 'Mesotardigrada',
    common_name: null,
    parent_id: 124337,
    ancestor_ids: [48460, 1, 124337, 152099],
    rank: 'class',
  },
  {
    id: 1252996,
    name: 'Ascetosporea',
    common_name: null,
    parent_id: 1276035,
    ancestor_ids: [48460, 48222, 151873, 1276035, 1252996],
    rank: 'class',
  },
  {
    id: 1410904,
    name: 'Aconoidasida',
    common_name: null,
    parent_id: 1410902,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410902, 1410904],
    rank: 'class',
  },
  {
    id: 1293136,
    name: 'Elusimicrobia',
    common_name: null,
    parent_id: 796983,
    ancestor_ids: [48460, 67333, 796983, 1293136],
    rank: 'class',
  },
  {
    id: 1300120,
    name: 'Chrymotiviricetes',
    common_name: null,
    parent_id: 1145316,
    ancestor_ids: [48460, 131236, 1145316, 1300120],
    rank: 'class',
  },
  {
    id: 1300126,
    name: 'Vidaverviricetes',
    common_name: null,
    parent_id: 1145316,
    ancestor_ids: [48460, 131236, 1145316, 1300126],
    rank: 'class',
  },
  {
    id: 1273507,
    name: 'Pavlovophyceae',
    common_name: null,
    parent_id: 51845,
    ancestor_ids: [48460, 48222, 51845, 1273507],
    rank: 'class',
  },
  {
    id: 1272924,
    name: 'Picophagophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 1272924],
    rank: 'class',
  },
  {
    id: 1272929,
    name: 'Schizocladiophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 1272929],
    rank: 'class',
  },
  {
    id: 1272880,
    name: 'Trepomonadea',
    common_name: null,
    parent_id: 1272879,
    ancestor_ids: [48460, 47686, 796988, 1272878, 1272879, 1272880],
    rank: 'class',
  },
  {
    id: 1272907,
    name: 'Chrysoparadoxophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 1272907],
    rank: 'class',
  },
  {
    id: 1272914,
    name: 'Phaeosacciophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 1272914],
    rank: 'class',
  },
  {
    id: 1271654,
    name: 'Chrysomeridophyceae',
    common_name: null,
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 1271654],
    rank: 'class',
  },
  {
    id: 1110396,
    name: 'Spiculogloeomycetes',
    common_name: null,
    parent_id: 416490,
    ancestor_ids: [48460, 47170, 47169, 416490, 1110396],
    rank: 'class',
  },
  {
    id: 1121962,
    name: 'Sarcomonadea',
    common_name: null,
    parent_id: 1276043,
    ancestor_ids: [48460, 48222, 151873, 1185636, 1276043, 1121962],
    rank: 'class',
  },
  {
    id: 1121486,
    name: 'Apicomonadea',
    common_name: null,
    parent_id: 1410902,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410902, 1121486],
    rank: 'class',
  },
  {
    id: 1134332,
    name: 'Mouviricetes',
    common_name: null,
    parent_id: 1134331,
    ancestor_ids: [48460, 131236, 1134331, 1134332],
    rank: 'class',
  },
  {
    id: 1123687,
    name: 'Magsaviricetes',
    common_name: null,
    parent_id: 1123684,
    ancestor_ids: [48460, 131236, 1123684, 1123687],
    rank: 'class',
  },
  {
    id: 1165092,
    name: 'Endomicrobia',
    common_name: null,
    parent_id: 796983,
    ancestor_ids: [48460, 67333, 796983, 1165092],
    rank: 'class',
  },
  {
    id: 1241246,
    name: 'Arhynchocoela',
    common_name: null,
    parent_id: 51280,
    ancestor_ids: [48460, 1, 51280, 1241246],
    rank: 'class',
  },
  {
    id: 1239848,
    name: 'Tokiviricetes',
    common_name: null,
    parent_id: 1239847,
    ancestor_ids: [48460, 131236, 1239847, 1239848],
    rank: 'class',
  },
  {
    id: 1185991,
    name: 'Breviatea',
    common_name: null,
    parent_id: 1186038,
    ancestor_ids: [48460, 47686, 796989, 1186038, 1185991],
    rank: 'class',
  },
  {
    id: 1185992,
    name: 'Thecomonadea',
    common_name: null,
    parent_id: 1186038,
    ancestor_ids: [48460, 47686, 796989, 1186038, 1185992],
    rank: 'class',
  },
  {
    id: 1185625,
    name: 'Developea',
    common_name: null,
    parent_id: 1276487,
    ancestor_ids: [48460, 48222, 1276487, 1185625],
    rank: 'class',
  },
  {
    id: 1185632,
    name: 'Nanomonadea',
    common_name: null,
    parent_id: 1185618,
    ancestor_ids: [48460, 48222, 792746, 1185618, 1185632],
    rank: 'class',
  },
  {
    id: 941616,
    name: 'Holophagae',
    common_name: null,
    parent_id: 151841,
    ancestor_ids: [48460, 67333, 151841, 941616],
    rank: 'class',
  },
  {
    id: 1446783,
    name: 'Symbiontida',
    common_name: null,
    parent_id: 142256,
    ancestor_ids: [48460, 47686, 142256, 1446783],
    rank: 'class',
  },
  {
    id: 1453376,
    name: 'Telonemea',
    common_name: null,
    parent_id: 1453375,
    ancestor_ids: [48460, 48222, 1453375, 1453376],
    rank: 'class',
  },
  {
    id: 1464831,
    name: 'Oxyrrhidophyceae',
    common_name: null,
    parent_id: 1410906,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410906, 1464831],
    rank: 'class',
  },
  {
    id: 1464832,
    name: 'Syndinea',
    common_name: null,
    parent_id: 1410906,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410906, 1464832],
    rank: 'class',
  },
  {
    id: 1465243,
    name: 'Chloropicophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 1465243],
    rank: 'class',
  },
  {
    id: 1465244,
    name: 'Prasinodermatophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 1465244],
    rank: 'class',
  },
  {
    id: 1461648,
    name: 'Peribolosporomycetes',
    common_name: null,
    parent_id: 951335,
    ancestor_ids: [48460, 47170, 47169, 951335, 1461648],
    rank: 'class',
  },
  {
    id: 1365894,
    name: 'Psilophytopsida',
    common_name: null,
    parent_id: 1365893,
    ancestor_ids: [48460, 47126, 1365893, 1365894],
    rank: 'class',
  },
  {
    id: 1111207,
    name: 'Moniliellomycetes',
    common_name: null,
    parent_id: 951335,
    ancestor_ids: [48460, 47170, 47169, 951335, 1111207],
    rank: 'class',
  },
  {
    id: 1120378,
    name: 'Diplonemea',
    common_name: null,
    parent_id: 142256,
    ancestor_ids: [48460, 47686, 142256, 1120378],
    rank: 'class',
  },
  {
    id: 1128023,
    name: 'Chunqiuviricetes',
    common_name: null,
    parent_id: 1128017,
    ancestor_ids: [48460, 131236, 1128016, 1128017, 1128023],
    rank: 'class',
  },
  {
    id: 1128026,
    name: 'Yunchangviricetes',
    common_name: null,
    parent_id: 1128017,
    ancestor_ids: [48460, 131236, 1128016, 1128017, 1128026],
    rank: 'class',
  },
  {
    id: 1128036,
    name: 'Duplopiviricetes',
    common_name: null,
    parent_id: 1128035,
    ancestor_ids: [48460, 131236, 1128035, 1128036],
    rank: 'class',
  },
  {
    id: 1186437,
    name: 'Aphelidea',
    common_name: null,
    parent_id: 1186436,
    ancestor_ids: [48460, 47170, 1186436, 1186437],
    rank: 'class',
  },
  {
    id: 1276480,
    name: 'Fusulinata',
    common_name: null,
    parent_id: 348288,
    ancestor_ids: [48460, 48222, 348288, 1276480],
    rank: 'class',
  },
  {
    id: 1276406,
    name: 'Cariacotrichea',
    common_name: null,
    parent_id: 1156601,
    ancestor_ids: [48460, 48222, 118996, 1156601, 1276406],
    rank: 'class',
  },
  {
    id: 152106,
    name: 'Eucycliophora',
    common_name: null,
    parent_id: 151828,
    ancestor_ids: [48460, 1, 151828, 152106],
    rank: 'class',
  },
  {
    id: 151834,
    name: 'Micrognathozoa',
    common_name: 'Micrognathozoans',
    parent_id: 884506,
    ancestor_ids: [48460, 1, 884506, 151834],
    rank: 'class',
  },
  {
    id: 152092,
    name: 'Methanomicrobia',
    common_name: null,
    parent_id: 151825,
    ancestor_ids: [48460, 151817, 151825, 152092],
    rank: 'class',
  },
  {
    id: 794020,
    name: 'Archaeosporomycetes',
    common_name: null,
    parent_id: 151868,
    ancestor_ids: [48460, 47170, 151868, 794020],
    rank: 'class',
  },
  {
    id: 1184051,
    name: 'Picocystophyceae',
    common_name: null,
    parent_id: 50863,
    ancestor_ids: [48460, 47126, 50863, 1184051],
    rank: 'class',
  },
  {
    id: 152013,
    name: 'Bolidophyceae',
    common_name: 'Bolidophytes',
    parent_id: 48221,
    ancestor_ids: [48460, 48222, 48221, 152013],
    rank: 'class',
  },
  {
    id: 1027346,
    name: 'Ichthyosporea',
    common_name: null,
    parent_id: 151874,
    ancestor_ids: [48460, 47686, 151874, 1027346],
    rank: 'class',
  },
  {
    id: 1365645,
    name: 'Arberiopsida',
    common_name: null,
    parent_id: 1365644,
    ancestor_ids: [48460, 47126, 1365644, 1365645],
    rank: 'class',
  },
  {
    id: 1276036,
    name: 'Chlorarachnea',
    common_name: 'chlorarachniophytes',
    parent_id: 1185636,
    ancestor_ids: [48460, 48222, 151873, 1185636, 1276036],
    rank: 'class',
  },
  {
    id: 474015,
    name: 'Polypodiozoa',
    common_name: 'Polypodiozoans',
    parent_id: 47534,
    ancestor_ids: [48460, 1, 47534, 474015],
    rank: 'class',
  },
  {
    id: 1498442,
    name: 'Umbelopsidomycetes',
    common_name: null,
    parent_id: 1094433,
    ancestor_ids: [48460, 47170, 1094433, 1498442],
    rank: 'class',
  },

  {
    id: 473790,
    name: 'Multicrustacea',
    common_name: 'Typical Crustaceans',
    parent_id: 85493,
    ancestor_ids: [48460, 1, 47120, 85493, 473790],
    rank: 'class',
  },
  {
    id: 473792,
    name: 'Oligostraca',
    common_name: 'Oligostracans',
    parent_id: 85493,
    ancestor_ids: [48460, 1, 47120, 85493, 473792],
    rank: 'class',
  },
  {
    id: 797045,
    name: 'Agnatha',
    common_name: 'Jawless Fishes',
    parent_id: 355675,
    ancestor_ids: [48460, 1, 2, 355675, 797045],
    rank: 'class',
  },
  {
    id: 1410906,
    name: 'Dinoflagellata',
    common_name: 'dinoflagellates',
    parent_id: 1410900,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410906],
    rank: 'class',
  },
  {
    id: 551552,
    name: 'Neodermata',
    common_name: null,
    parent_id: 152105,
    ancestor_ids: [48460, 1, 52319, 152105, 551552],
    rank: 'class',
  },
  {
    id: 1276042,
    name: 'Ventrifilosa',
    common_name: null,
    parent_id: 1185636,
    ancestor_ids: [48460, 48222, 151873, 1185636, 1276042],
    rank: 'class',
  },
  {
    id: 1410902,
    name: 'Apicomplexa',
    common_name: 'apicomplexans',
    parent_id: 1410900,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410902],
    rank: 'class',
  },
  {
    id: 1308220,
    name: 'Archamoebae',
    common_name: null,
    parent_id: 1308213,
    ancestor_ids: [48460, 47686, 372735, 1308213, 1308220],
    rank: 'class',
  },
  {
    id: 1276043,
    name: 'Monadofilosa',
    common_name: null,
    parent_id: 1185636,
    ancestor_ids: [48460, 48222, 151873, 1185636, 1276043],
    rank: 'class',
  },
  {
    id: 1410907,
    name: 'Perkinsozoa',
    common_name: null,
    parent_id: 1410900,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410907],
    rank: 'class',
  },
  {
    id: 1272879,
    name: 'Eopharyngia',
    common_name: null,
    parent_id: 1272878,
    ancestor_ids: [48460, 47686, 796988, 1272878, 1272879],
    rank: 'class',
  },
  {
    id: 1410903,
    name: 'Sporozoa',
    common_name: null,
    parent_id: 1410900,
    ancestor_ids: [48460, 48222, 792744, 1410900, 1410903],
    rank: 'class',
  },
];

export const CURRENT_VERSION = '0.0.7';
export const ICON_COUNT = 26;
export const LOWER_TAXA_SELECT_OPTIONS = (
  [...PHYLUM, ...CLASS, ...ORDER] as any[]
).map(formatDropdownOptionsFromApi);

export const DEFAULT_PRESETS = [
  {
    name: 'Bird Watching',
    emoji: '🐦',
    setting: {
      kingdoms: ['1'],
      include: [
        {
          name: 'Aves',
          id: 3,
          common_name: 'Birds',
          parent_id: 355675,
          rank: 'class',
          ancestor_ids: [48460, 1, 2, 355675, 3],
          wikipedia_url: 'http://en.wikipedia.org/wiki/Bird',
          photo:
            'https://inaturalist-open-data.s3.amazonaws.com/photos/2967053/medium.jpg',
          display_name: 'Birds (Aves)',
          scientific_name: 'Aves',
        },
      ],
      exclude: [],
      introduced: '',
      endemic: '',
    },
  },
  // {
  //   name: 'Creepy Crawlies',
  //   code: 'bugs',
  //   setting: formatSetting({
  //     kingdoms: ['1'],
  //     include: true,
  //     lowerTaxa: [
  //       'Arthropoda',
  //       'Nematoda',
  //       'Platyhelminthes',
  //       'Annelida',
  //       'Reptilia',
  //       'Gastropoda',
  //     ],
  //   }),
  //   emoji: '🐍',
  // },
  // {
  //   name: 'Furry Friends',
  //   code: 'furry',
  //   setting: formatSetting({
  //     kingdoms: ['1'],
  //     include: true,
  //     lowerTaxa: ['Mammalia'],
  //   }),
  //   emoji: '🐿️',
  // },
  // {
  //   name: 'No Bugs Please',
  //   code: 'nobugs',
  //   setting: formatSetting({
  //     kingdoms: ['47170', '47126', '1'],
  //     include: false,
  //     lowerTaxa: ['Arthropoda', 'Nematoda', 'Platyhelminthes', 'Annelida'],
  //   }),
  //   emoji: '🚫',
  // },
  // {
  //   name: 'Water World',
  //   code: 'underwater',
  //   setting: formatSetting({
  //     kingdoms: ['1', '47126'],
  //     include: true,
  //     lowerTaxa: [
  //       'Holothuroidea',
  //       'Asteroidea',
  //       'Ophiuroidea',
  //       'Crinoidea',
  //       'Cephalopoda',
  //       'Polychaeta',
  //       'Petromyzonti',
  //       'Elasmobranchii',
  //       'Agnatha',
  //       'Actinopterygii',
  //       'Chlorophyta',
  //       'Rhodophyta',
  //       'Charophyta',
  //       'Cetacea',
  //       'Sirenia',
  //       'Pinnipedia',
  //     ],
  //   }),
  //   emoji: '🌊',
  // },
  // {
  //   name: 'Mushroom Madness',
  //   code: 'mush',
  //   setting: formatSetting({
  //     kingdoms: ['47170'],
  //     include: true,
  //     lowerTaxa: [
  //       'Agaricales',
  //       'Boletales',
  //       'Russulales',
  //       'Polyporales',
  //       'Corticiales',
  //       'Pezizales',
  //       'Hypocreales',
  //       'Xylariales',
  //       'Polyporales',
  //     ],
  //   }),
  //   emoji: '🍄',
  // },
  // {
  //   name: 'Tree Time',
  //   code: 'trees',
  //   setting: formatSetting({
  //     kingdoms: ['47126'],
  //     include: true,
  //     lowerTaxa: [
  //       'Fagales',
  //       'Malpighiales',
  //       'Sapindales',
  //       'Arecales',
  //       'Pinopsida',
  //       'Ginkgoopsida',
  //     ],
  //   }),
  //   emoji: '🌳️',
  // },
  // {
  //   name: 'Flower Power',
  //   code: 'trees',
  //   setting: formatSetting({
  //     kingdoms: ['47126'],
  //     include: true,
  //     lowerTaxa: ['Angiospermae'],
  //   }),
  //   emoji: '🌼',
  // },
  // {
  //   name: 'Shrimps is Bugs',
  //   code: 'trees',
  //   setting: getSetting({
  //     kingdoms: ['1'],
  //     include: true,
  //     lowerTaxa: [
  //       'Hemiptera',
  //       'Coleoptera',
  //       'Diptera',
  //       'Orthoptera',
  //       'Lepidoptera',
  //       'Hymenoptera',
  //       'Isoptera',
  //       'Odonata',
  //       'Phasmatodea',
  //       'Thysanoptera',
  //       'Caridea',
  //       'Dendrobranchiata',
  //     ],
  //   }),
  //   emoji: '🦐',
  // },
];
