import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useThrottle } from '@uidotdev/usehooks';
import { TAXONOMY } from '../constants';

export interface ResultType {
  name: string;
  id: number;
  preferred_common_name: string;
  common_name: string;
  display_name: string;
  parent_id: number;
  rank: string;
  wikipedia_url: string;
  wikipedia_summary: string;
  default_photo: { medium_url: string };
  photo: string;
  ancestor_ids: number[];
  photos: any[];
  uri?: string;
  species_guess: string;
  user: any;
  ident_taxon_ids?: any;
  wrong?: any;
  observed_on_string?: string;
  place_guess?: string;
  taxon?: Record<string, any>;
  scientific_name: string;
}

export const formatDropdownOptionsFromApi = ({
  name,
  id,
  preferred_common_name: common_name,
  parent_id,
  rank,
  ancestor_ids,
  wikipedia_url,
  wikipedia_summary,
  default_photo,
}: ResultType) => ({
  name,
  id,
  common_name,
  parent_id,
  rank,
  ancestor_ids,
  wikipedia_url,
  wikipedia_summary,
  photo: default_photo?.medium_url,
  display_name: displayNameFormat(name, common_name),
  scientific_name: name,
});

export const displayNameFormat = (name: string, common_name: string) => {
  if (common_name) {
    return `${common_name} (${name})`;
  }
  return name;
};

export const useLevelData = (
  getValues: any,
  lockedIn: {
    kingdom?: { id: string };
    phylum?: { id: string };
    class?: { id: string };
    order?: { id: string };
    family?: { id: string };
    genus?: { id: string };
    species?: { id: string };
  },
  showAlert: any,
  {
    taxon_id,
    without_taxon_id,
  }: { taxon_id?: string; without_taxon_id?: string },
) => {
  const useAutocompleteSearch = (
    query: any,
    rank: string,
    parentIds: string,
    showAlert: (props: any) => void,
  ) => {
    const [levels, setLevels] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      if (rank && parentIds && query && !query?.name) {
        let pathParams = `rank=${rank}&per_page=20&q=${query}&taxon_id=${parentIds.toString()}`;
        if (without_taxon_id) {
          pathParams += `&without_taxon_id=${without_taxon_id}`;
        }
        axios
          .get<{ results: ResultType[] }>(
            `https://api.inaturalist.org/v1/taxa?${pathParams}`,
          )
          .then(({ data: { results } }) => {
            setLevels(results.map(formatDropdownOptionsFromApi));
          })
          .catch((err) => {
            console.error(err);
            showAlert({
              severity: 'error',
              summary: 'Error E001',
              detail: err.message,
              sticky: true,
            });
          })
          .finally(() => setLoading(false));
      }
    }, [query, rank, parentIds, showAlert]);

    return { levels, loading };
  };
  let parentIds =
    lockedIn.phylum?.id ||
    getValues('phylum')?.id ||
    taxon_id ||
    lockedIn.kingdom?.id ||
    getValues('kingdom')?.id;

  const throttleDelay = 2500;
  const throttledPhylumValue = useThrottle(getValues('phylum'), throttleDelay);
  const { levels: phylum } = useAutocompleteSearch(
    throttledPhylumValue,
    'phylum',
    parentIds,
    showAlert,
  );
  parentIds = lockedIn.class?.id || getValues('class')?.id || parentIds;
  const throttledClassValue = useThrottle(getValues('class'), throttleDelay);
  const { levels: classValue } = useAutocompleteSearch(
    throttledClassValue,
    'class',
    parentIds,
    showAlert,
  );
  parentIds = lockedIn.order?.id || getValues('order')?.id || parentIds;
  const throttledOrderValue = useThrottle(getValues('order'), throttleDelay);
  const { levels: order } = useAutocompleteSearch(
    throttledOrderValue,
    'order',
    parentIds,
    showAlert,
  );
  parentIds = lockedIn.family?.id || getValues('family')?.id || parentIds;
  const throttledFamilyValue = useThrottle(getValues('family'), throttleDelay);
  const { levels: family } = useAutocompleteSearch(
    throttledFamilyValue,
    'family',
    parentIds,
    showAlert,
  );
  parentIds = lockedIn.genus?.id || getValues('genus')?.id || parentIds;
  const throttledGenusValue = useThrottle(getValues('genus'), throttleDelay);
  const { levels: genus } = useAutocompleteSearch(
    throttledGenusValue,
    'genus',
    parentIds,
    showAlert,
  );
  parentIds = lockedIn.species?.id || getValues('species')?.id || parentIds;
  const throttledSpeciesValue = useThrottle(
    getValues('species'),
    throttleDelay,
  );
  const { levels: species } = useAutocompleteSearch(
    throttledSpeciesValue,
    'species',
    parentIds,
    showAlert,
  );
  const levels: Record<string, string[]> = {
    phylum,
    class: classValue,
    order,
    family,
    genus,
    species,
  };
  return levels;
};

export const useTaxaCalls = (showAlert: (props: any) => void) => {
  const parentCache = useRef<Record<string, any>>({});
  const getTaxa = async (id: number) => {
    if (!id) {
      return null;
    }
    if (parentCache.current[id]) {
      return parentCache.current[id];
    }
    return await axios
      .get(`https://api.inaturalist.org/v1/taxa/${id}`)
      .then(({ data: { results } }) => {
        const formattedResults = results.map(formatDropdownOptionsFromApi)[0];
        parentCache.current[id] = formattedResults;
        return formattedResults;
      })
      .catch((err) => {
        console.error(err);
        showAlert({
          severity: 'error',
          summary: 'Error E002',
          detail: err.message,
          sticky: true,
        });
      });
  };

  const getAncestors = async (ancestorIds: number[]) => {
    const stringIds = ancestorIds.join(',');
    if (parentCache.current[stringIds]) {
      return parentCache.current[stringIds];
    }
    return await axios
      .get(`https://api.inaturalist.org/v1/taxa/${stringIds}`)
      .then(({ data: { results } }) => {
        const fiteredResults = results.filter(({ rank }: any) =>
          TAXONOMY.includes(rank),
        );
        const formattedResults = fiteredResults.map(
          formatDropdownOptionsFromApi,
        );
        parentCache.current[stringIds] = formattedResults;
        return formattedResults;
      })
      .catch((err) => {
        console.error(err);
        showAlert({
          severity: 'error',
          summary: 'Error E003',
          detail: err.message,
          sticky: true,
        });
      });
  };
  return { getTaxa, getAncestors };
};
