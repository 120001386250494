import { useState } from 'react';
import axios from 'axios';
import { formatDropdownOptionsFromApi } from './useLevels';
import { TAXONOMY } from '../constants';
import { Settings, Game, DEFAULT_GAME } from './usePractice';
import { Stats } from './useStats';

export const useObservations = ({
  game,
  setGame,
  stats,
  showAlert,
  navigate,
  resetGame,
}: {
  game: Game;
  setGame: (game: Game) => void;
  stats: Stats;
  showAlert: (props: any) => void;
  navigate: (path: string) => void;
  resetGame: () => void;
}) => {
  const [currentTree, setCurrentTree] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [currentSearchPageCount, setCurrentSearchPageCount] = useState(0);

  const getCurrentTree = async (ids: string) => {
    const newCurrentTree = await axios
      .get(`https://api.inaturalist.org/v1/taxa/${ids}`)
      .then(({ data: { results } }) =>
        results
          .map(formatDropdownOptionsFromApi)
          .filter(({ rank }: any) => TAXONOMY.includes(rank)),
      )
      .catch((err) => {
        console.error(err);
        showAlert({
          severity: 'error',
          summary: 'Error E004',
          detail: err.message,
          sticky: true,
        });
        resetGame();
        navigate('/');
        throw new Error(err);
      });
    return newCurrentTree;
  };

  const getObservation = (observationId: string) => {
    if (!observationId) {
      throw new Error('Invalid Observation ID');
    }

    setLoading(true);
    return axios
      .get(`https://api.inaturalist.org/v1/observations/${observationId}`)
      .then(async ({ data: { results } }) => {
        setGame({ ...game, currentObservation: results[0] });
        const ids = results[0].taxon.ancestor_ids.join(',');
        const newCurrentTree = await getCurrentTree(ids);
        setCurrentTree(newCurrentTree);
        return results[0];
      })
      .catch((err) => {
        console.error(err);
        showAlert({
          severity: 'error',
          summary: 'Error E005',
          detail: err.message,
          sticky: true,
        });
        resetGame();
        navigate('/');
        throw new Error(err);
      })
      .finally(() => setLoading(false));
  };

  const getNewObservation = async (settings: Settings, newGame = false) => {
    const { kingdoms, include, exclude, location, date } = settings;
    const searchQueries: Record<string, string> = {
      photos: 'true',
      quality_grade: 'research',
      rank: 'species',
      per_page: '1',
      locale: 'en',
      taxon_id: kingdoms.join(','),
      not_id: [...stats.practice.games, ...stats.practice.blacklist]
        .map(({ observationId }: any) => observationId)
        .join(','),
      captive: 'false',
      photo_license: [
        'cc-by',
        'cc-by-nc',
        'cc-by-nd',
        'cc-by-sa',
        'cc-by-nc-nd',
        'cc-by-nc-sa',
        'cc0',
      ].join(','),
    };
    if (include.length > 0) {
      searchQueries.taxon_id = include
        .filter(Boolean)
        .map((s: any) => s.id ?? s)
        .join(',');
    }
    if (exclude.length > 0) {
      searchQueries.without_taxon_id = include
        .filter(Boolean)
        .map((s: any) => s.id ?? s)
        .join(',');
    }

    if (location.enabled && location.latitude && location.longitude) {
      searchQueries.radius = Math.max(
        Math.ceil(location.radius / 1000),
        5,
      ).toString();
      searchQueries.lat = location.latitude.toString();
      searchQueries.lng = location.longitude.toString();
    }
    if (date.enabled && date.startDate) {
      const newEndDate = date.endDate
        ? new Date(date.endDate)
        : new Date(date.startDate);
      newEndDate.setUTCHours(23, 59, 59, 999);

      searchQueries.d1 = new Date(date.startDate).toISOString();
      searchQueries.d2 = newEndDate.toISOString();
    }
    if (settings.endemic) {
      searchQueries.endemic = settings.endemic === 'include' ? 'true' : 'false';
    }
    if (settings.introduced) {
      searchQueries.introduced =
        settings.introduced === 'include' ? 'true' : 'false';
    }
    const queryString = Object.keys(searchQueries)
      .map((key) => `${key}=${searchQueries[key]}`)
      .join('&');
    let pageCount = currentSearchPageCount;
    if (!pageCount) {
      setLoading(true);
      pageCount = await axios
        .get(
          `https://api.inaturalist.org/v1/observations?${queryString}&only_id=true`,
        )
        .then(async ({ data: { total_results } }) => total_results)
        .catch((err) => {
          console.error(err);
          showAlert({
            severity: 'error',
            summary: 'Error E006',
            detail: err.message,
            sticky: true,
          });
          navigate('/practice');
          throw new Error(err);
        })
        .finally(() => setLoading(false));

      setCurrentSearchPageCount(pageCount);
    }
    if (pageCount === 0) {
      resetGame();
      throw new Error('No results found!');
    }
    const page = Math.floor(Math.random() * Math.min(10000, pageCount));
    setLoading(true);
    return axios
      .get(
        `https://api.inaturalist.org/v1/observations?${queryString}&page=${
          page || 0
        }`,
      )
      .then(async ({ data: { results } }) => {
        if (results[0]) {
          if (newGame) {
            setGame({
              ...DEFAULT_GAME,
              maxGuesses: settings.maxGuesses,
              currentObservation: results[0],
              startTime: new Date().toISOString(),
            });
          } else {
            setGame({
              ...game,
              maxGuesses: settings.maxGuesses,
              currentObservation: results[0],
              startTime: new Date().toISOString(),
            });
          }
          const ids = results[0].taxon.ancestor_ids.join(',');
          const newCurrentTree = await getCurrentTree(ids);
          setCurrentTree(newCurrentTree);
          return results[0];
        }
      })
      .catch((err) => {
        console.error(err);
        showAlert({
          severity: 'error',
          summary: 'Error E008',
          detail: err.message,
          sticky: true,
        });
        navigate('/');
        throw new Error(err);
      })
      .finally(() => setLoading(false));
  };

  return {
    loading,
    setLoading,
    currentTree,
    getNewObservation,
    getObservation,
    currentSearchPageCount,
    setCurrentSearchPageCount,
    setCurrentTree,
  };
};
