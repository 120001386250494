import React, { useEffect, useRef } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Practice } from '../Practice/Practice';
import { Toast } from 'primereact/toast';
import { NavbarWrapper } from '../../components/NavbarWrapper';
import { Home } from '../Home/Home';
import { Daily } from '../Daily/Daily';
import { ReleaseNotes } from '../ReleaseNotes/ReleaseNotes';
import { About } from '../About/About';
import { useStats } from '../../hooks/useStats';
import UserStats from './UserStats';
import { Feedback } from '../Feedback/Feedback';
import { CURRENT_VERSION, ICON_COUNT } from '../../constants';
import './App.css';

const App = () => {
  const {
    stats,
    addGameToStats,
    setSidebarVisible,
    sidebarVisible,
    resetStats,
    addToBlacklist,
    storedVersion,
    setStoredVersion,
  } = useStats();

  useEffect(() => {
    if (storedVersion !== CURRENT_VERSION) {
      setStoredVersion(CURRENT_VERSION);
      resetStats();
    }
  }, [resetStats, setStoredVersion, storedVersion]);
  const toast = useRef(null);
  const show = (
    { severity, summary, detail, sticky } = {
      severity: 'info',
      summary: 'Alert',
      detail: '',
      sticky: false,
    },
  ) => {
    (toast.current as any).show({
      severity,
      summary,
      sticky,
      detail,
    });
  };

  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <Home showAlert={show} />,
      },
      {
        path: '/practice',
        element: (
          <Practice
            addGameToStats={addGameToStats}
            stats={stats}
            showAlert={show}
            addToBlacklist={addToBlacklist}
            storedVersion={storedVersion}
          />
        ),
      },
      {
        path: '/daily',
        element: <Daily showAlert={show} />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/releases',
        element: <ReleaseNotes />,
      },
      {
        path: '/feedback',
        element: <Feedback showAlert={show} />,
      },
    ].map((i) => ({
      ...i,
      element: (
        <NavbarWrapper
          setSidebarVisible={setSidebarVisible}
          sidebarVisible={sidebarVisible}
          key={i.path}
        >
          {i.element}
        </NavbarWrapper>
      ),
    })),
  );

  return (
    <div className="App">
      <Helmet
        title="iDentifi"
        link={[
          {
            rel: 'icon',
            type: 'image/svg+xml',
            href: `icon_svgs/favicon${Math.round(Math.random() * ICON_COUNT)
              .toString()
              .padStart(2, '0')}.svg`,
          },
        ]}
      />
      <RouterProvider router={router} />

      <UserStats
        {...{
          stats,
          setSidebarVisible,
          sidebarVisible,
          resetStats,
        }}
      />

      <Toast ref={toast} />
    </div>
  );
};

export default App;
