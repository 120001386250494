import React from 'react';
import { Panel } from 'primereact/panel';
import { MdOutlineNewReleases } from 'react-icons/md';
import { Accordion, AccordionTab } from 'primereact/accordion';
import './ReleaseNotes.css';

export const ReleaseNotes = () => {
  const headerTemplate = (
    <div className="p-panel-header">
      <div style={{ padding: 6 }}>
        <MdOutlineNewReleases style={{ marginRight: 12 }} />
        Release Notes
      </div>
    </div>
  );
  return (
    <div className="grid grid-container">
      <div className="col-12 sm:col-12 md:col-11 lg:col-9">
        <Panel headerTemplate={headerTemplate}>
          <div
            className="grid grid-container"
            style={{ alignItems: 'flex-start' }}
          >
            <div className="col-12 md:col-7">
              <div className="release-container">
                <h2 style={{ margin: 0 }}>All Releases</h2>
                <p>Here you can find notes on recent site updates & changes.</p>
                <hr style={{ borderColor: '#fff', marginBottom: 32 }} />
                <Accordion activeIndex={0}>
                  <AccordionTab header="v0.0.7">
                    <div>
                      <em>04/11/2024</em>
                      <ul>
                        <li>Added date filtering</li>
                        <li>
                          Separated include/exclude into two separate search
                          boxes, allowing both to be used at once
                        </li>
                        <li>
                          Fixed a bug with a Google Maps script race condition
                        </li>
                        <li>
                          Improved practice image to be centered & more clear
                          when there are multiple photos to view
                        </li>
                      </ul>
                    </div>
                  </AccordionTab>
                  <AccordionTab header="v0.0.6">
                    <div>
                      <em>04/01/2024</em>
                      <p>
                        Several practice and settings bug fixes and
                        improvements, including:
                      </p>
                      <ul>
                        <li>
                          A bug where guess dropdowns would fail to populate
                        </li>
                        <li>
                          Displaying the proper location name instead of time
                          zone
                        </li>
                        <li>
                          Improved the parent filtering of guess dropdowns
                        </li>
                        <li>Better homepage photos (fixed ratio)</li>
                      </ul>
                    </div>
                  </AccordionTab>
                  <AccordionTab header="v0.0.5">
                    <div>
                      <em>03/31/2024</em>
                      <p>Improved lower taxa filtering in practice settings.</p>
                    </div>
                  </AccordionTab>
                  <AccordionTab header="v0.0.4">
                    <div>
                      <em>03/30/2024</em>
                      <p>
                        Added better location filtering to practice settings.
                      </p>
                    </div>
                  </AccordionTab>
                  <AccordionTab header="v0.0.3">
                    <div>
                      <em>03/25/2024</em>
                      <p>
                        Added practice preset options and the ability to filter
                        by taxonomic order in practice settings.
                      </p>
                    </div>
                  </AccordionTab>
                  <AccordionTab header="v0.0.2">
                    <div>
                      <em>03/04/2024</em>
                      <p>
                        Primarily error-handling fixes, showing proper alerts
                        with error codes on API call failure.
                      </p>
                    </div>
                  </AccordionTab>
                  <AccordionTab header="v0.0.1">
                    <div>
                      <em>10/16/2023</em>
                      <p>
                        The very first version! Includes basic functionality for
                        identification practice and user stats.
                      </p>
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
            <hr className="block md:hidden" style={{ width: '100%' }} />
            <div className="col-12 md:col-5" style={{ padding: 16 }}>
              <h2>Future Features</h2>
              <p>
                Curious if something's on the roadmap? Check out my future plans
                here:
              </p>
              <hr style={{ borderColor: '#fff', marginBottom: 32 }} />
              <ol>
                <li>
                  <strong>Daily Challenge Mode</strong>
                </li>
                <ul>
                  <li>
                    The daily challenge will be a shared observation that
                    changes daily, see how you stack up against other folks with
                    a rank on the daily leaderboard
                  </li>
                </ul>
                <li>
                  <strong>Challenge Sharing</strong>
                </li>
                <li>
                  <strong>More Detailed/Interesting Stats</strong>
                </li>
                <li>
                  <strong>Annotation Filtering</strong>
                </li>
              </ol>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
