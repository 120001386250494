import { getCountryFromCode } from './getCountryFromCode';
import {
  getAmericanStateFromCode,
  getProvinceFromCode,
  getAustralianStateFromCode,
} from './getStateFromCode';

export const shortenLocation = (location: string) => {
  if (!location) {
    return location;
  }
  const locationParts = location.split(', ');
  const country = getCountryFromCode(
    locationParts.pop()?.replace(/ USA/g, 'US').replace(' US-', ' ') as string,
  );
  let state = (locationParts.pop() || '').replace(/^[\s\d]+/, '');
  if (state) {
    switch (country) {
      case 'United States':
        state = getAmericanStateFromCode(state);
        break;
      case 'Canada':
        state = getProvinceFromCode(state);
        break;
      case 'Australia':
        state = getAustralianStateFromCode(state);
        break;
      default:
        break;
    }
  }
  return `${state ?? locationParts?.join(', ') ?? ''}, ${country}`;
};
