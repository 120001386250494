import { useState } from 'react';
import { ResultType } from './useLevels';
import { PiBirdFill, PiPlantFill } from 'react-icons/pi';
import { TbMushroomFilled } from 'react-icons/tb';
import { useLocalStorage } from '@uidotdev/usehooks';

export interface Game {
  currentObservation: ResultType | null;
  incorrectGuesses: ResultType[][];
  lockedIn: Record<string, ResultType>;
  status: '' | 'won' | 'lost';
  maxGuesses: number;
  startTime?: string;
  endTime?: string;
}

export type Settings = IndividualSettings & SharedSettings;

export interface IndividualSettings {
  multiplePhotos: boolean;
  dismissedWarning: boolean;
  savedPresets: {
    name: string;
    emoji: string;
    setting: Omit<Settings, 'savedPresets'>;
  }[];
  maxGuesses: number;
  advancedSettingsOpen: boolean;
}
export interface SharedSettings {
  location: {
    latitude: null | number;
    longitude: null | number;
    enabled: boolean;
    radius: number;
    zoom: number;
  };
  date: {
    enabled: boolean;
    startDate: null | Date;
    endDate: null | Date;
  };
  time: {
    enabled: boolean;
    startTime: null | Date;
    endTime: null | Date;
  };
  kingdoms: string[];
  include: any[];
  exclude: any[];
  introduced: 'include' | 'exclude' | '';
  endemic: 'include' | 'exclude' | '';
}

export const DEFAULT_GAME: Game = {
  currentObservation: null,
  incorrectGuesses: [],
  lockedIn: {},
  status: '',
  maxGuesses: 8,
};

export const KINGDOM_ICONS: Record<string, any> = {
  Fungi: TbMushroomFilled,
  Animalia: PiBirdFill,
  Plantae: PiPlantFill,
};
export const KINGDOM_SELECT_OPTIONS = [
  { name: 'Fungi', value: '47170', icon: TbMushroomFilled },
  { name: 'Animals', value: '1', icon: PiBirdFill },
  { name: 'Plants', value: '47126', icon: PiPlantFill },
];
export const DEFAULT_SETTINGS: Settings = {
  maxGuesses: 8,
  location: {
    enabled: false,
    latitude: null,
    longitude: null,
    radius: 20000,
    zoom: 10,
  },
  date: {
    enabled: false,
    startDate: null,
    endDate: null,
  },
  time: {
    enabled: false,
    startTime: null,
    endTime: null,
  },
  kingdoms: KINGDOM_SELECT_OPTIONS.map(({ value }) => value),
  include: [],
  exclude: [],
  introduced: '',
  endemic: '',
  multiplePhotos: false,
  dismissedWarning: false,
  savedPresets: [],
  advancedSettingsOpen: false,
};

export const usePractice = () => {
  const [settings, setSettings] = useLocalStorage<Settings>(
    'settings',
    DEFAULT_SETTINGS,
  );
  const resetSettings = (
    { latitude, longitude } = { latitude: null, longitude: null },
  ) =>
    setSettings({
      ...DEFAULT_SETTINGS,
      location: { ...DEFAULT_SETTINGS.location, latitude, longitude },
    });
  const [game, setGame] = useState<Game>(DEFAULT_GAME);
  const resetGame = () => {
    setGame({ ...DEFAULT_GAME });
  };
  const setMapLocation = (location: {
    latitude: number;
    longitude: number;
    radius?: number;
    zoom?: number;
  }) => {
    const newSettings = { ...settings };
    newSettings.location.latitude = location.latitude;
    newSettings.location.longitude = location.longitude;
    if (location.radius) {
      newSettings.location.radius = Math.round(location.radius);
    }
    if (location.zoom) {
      newSettings.location.zoom = location.zoom;
    }
    setSettings(newSettings);
  };
  const dismissWarning = () => {
    const newSettings = { ...settings };
    newSettings.dismissedWarning = true;
    setSettings(newSettings);
  };
  return {
    game,
    settings,
    setGame,
    setMapLocation,
    setSettings,
    resetGame,
    resetSettings,
    dismissWarning,
  };
};
