import React from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Controller } from 'react-hook-form';
import * as _ from 'lodash';
import { TAXONOMY } from '../../constants';
import { ResultType, useTaxaCalls } from '../../hooks/useLevels';
import './Practice.css';
const levenshtein = require('js-levenshtein');

export const GuessInput = ({
  level,
  control,
  filteredItems,
  levels,
  items,
  getValues,
  setFilteredItems,
  setValue,
  i,
  loadingParent,
  setLoadingParent,
  game,
  correct,
  currentTree,
  settings,
  setRefresh,
  showAlert,
}: any) => {
  const { getTaxa, getAncestors } = useTaxaCalls(showAlert);

  let inputStyle: Record<string, string> = {
    background: 'transparent',
    color: '#333',
  };
  const backroundColors = ['0', '0', '20', '50', '100', '200', '275'];
  let backgroundColor = correct
    ? `hsl(${backroundColors[i]}, 95%, 85%)`
    : 'transparent';
  let oppositeColor = !correct
    ? `hsl(${backroundColors[i]}
      }, 95%, 85%)`
    : 'transparent';
  if (correct) {
    if (i === 0) {
      backgroundColor = 'white';
      oppositeColor = '#999';
    }
    inputStyle = {
      background: backgroundColor,
      color: '#111',
      borderColor: oppositeColor,
      borderRadius: '50px',
      paddingLeft: '20px',
    };
  }

  const itemTemplate = (item: any) => {
    const allGuesses: number[] = _.uniq(
      game.incorrectGuesses
        .reduce(
          (acc: ResultType[], guess: ResultType[]): ResultType[] => [
            ...acc,
            ...guess,
          ],
          [],
        )
        .map(({ id }: { id: string }) => id),
    );
    return (
      <div
        style={{
          padding: '0.5rem 1rem',
          background:
            allGuesses.filter((value) => item.ancestor_ids.includes(value))
              .length > 0
              ? 'rgba(255, 20, 0, 0.25)'
              : 'transparent',
        }}
        className="flex align-items-center"
      >
        {item.display_name}
      </div>
    );
  };

  return (
    <div className="p-inputgroup flex-1">
      <Controller
        name={level}
        control={control}
        render={({ field }) => (
          <AutoComplete
            {...field}
            inputStyle={inputStyle}
            className="level-autocomplete"
            panelClassName="level-autocomplete-panel"
            panelStyle={{ padding: '0px !important' }}
            field="display_name"
            itemTemplate={itemTemplate}
            dropdown={
              getValues(level)
                ? false
                : (filteredItems[level].dropdown && !game.lockedIn[level]) ??
                  false
            }
            forceSelection
            disabled={
              loadingParent || game.status === 'lost' || !!game.lockedIn[level]
            }
            value={
              game.status === 'lost'
                ? currentTree[TAXONOMY.indexOf(level)]
                : game.lockedIn[level] ?? field.value
            }
            style={{ opacity: 1 }}
            suggestions={
              filteredItems[level].suggestions.length > 0
                ? filteredItems[level].suggestions
                : levels[level]
            }
            completeMethod={(e: any) => {
              const prev = i ? Object.keys(items)[i - 1] : null;
              const values = getValues();
              const newItems = { ...items };

              newItems[level].suggestions = (
                newItems[level].suggestions.length > 0
                  ? newItems[level].suggestions
                  : levels[level]
              ).filter(
                (suggestion: {
                  name: string;
                  common_name: string;
                  ancestor_ids: number[];
                }) => {
                  const { name, common_name, ancestor_ids } = suggestion;
                  const nameMatch =
                    !e.query ||
                    name.toLowerCase().includes(e.query.toLowerCase().trim()) ||
                    (common_name || '')
                      .toLowerCase()
                      .includes(e.query.toLowerCase().trim());
                  let parentMatch = true;
                  if (prev && values[prev]) {
                    parentMatch = ancestor_ids.includes(values[prev].id);
                  }
                  const inKingdom =
                    ancestor_ids.filter((value) =>
                      settings.kingdoms.includes(value.toString().trim()),
                    ).length > 0;
                  return parentMatch && nameMatch && inKingdom;
                },
              );

              const index = TAXONOMY.indexOf(level);
              for (let i = index; i < TAXONOMY.length - 1; i++) {
                newItems[TAXONOMY[i + 1]].suggestions = items[
                  TAXONOMY[i + 1]
                ].suggestions
                  .filter(
                    ({ parent_id }: any) =>
                      parent_id === getValues()[TAXONOMY[i]]?.id,
                  )
                  .sort((a: any, b: any) =>
                    levenshtein(
                      e.query.toLowerCase(),
                      a.display_name.toLowerCase(),
                    ),
                  );
              }

              setFilteredItems(newItems);
            }}
            onSelect={async (e: any) => {
              let index = TAXONOMY.indexOf(level);
              const originalIndex = index;

              setLoadingParent(true);
              getTaxa(e.value?.id).then((v) => setValue(TAXONOMY[index], v));
              const ancestors = await getAncestors(e.value?.ancestor_ids);
              for (const i in ancestors) {
                const rank = ancestors[i].rank;
                setValue(rank, ancestors[i]);
              }
              setLoadingParent(false);

              for (let i = originalIndex; i < TAXONOMY.length - 1; i++) {
                const ancestorIds = getValues(TAXONOMY[i + 1])?.ancestor_ids;
                const currentId = getValues(TAXONOMY[i])?.id;
                if (!ancestorIds?.includes(currentId)) {
                  setValue(TAXONOMY[i + 1], undefined);
                }
              }
            }}
          />
        )}
      />

      {!!getValues(level)?.id && !game.lockedIn[level] && (
        <Button
          link
          type="button"
          disabled={loadingParent}
          onClick={(e) => {
            for (let i = TAXONOMY.indexOf(level); i < TAXONOMY.length; i++) {
              setValue(TAXONOMY[i], undefined);
              setRefresh(new Date().getTime());
            }
          }}
          icon="pi pi-times"
          className="p-button-danger"
        />
      )}
    </div>
  );
};
