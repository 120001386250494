import React from 'react';
import { Panel } from 'primereact/panel';
import { Tooltip } from 'primereact/tooltip';
import { BiBug } from 'react-icons/bi';
import bee from './bee.jpg';
import './About.css';

export const About = () => {
  const headerTemplate = (
    <div className="p-panel-header">
      <div style={{ padding: 6 }}>
        <i className="pi pi-fw pi-info-circle" style={{ marginRight: 12 }} />
        about me
      </div>
    </div>
  );
  return (
    <div className="grid grid-container">
      <Tooltip target=".bee-tooltip" />
      <div className="col-12 sm:col-12 md:col-11 lg:col-9">
        <Panel headerTemplate={headerTemplate}>
          <div className="grid mobile-row">
            <div className="col-12 md:col-7" style={{ padding: 16 }}>
              <h2 style={{ marginTop: 0 }}>
                <BiBug style={{ marginBottom: -4 }} /> hi there!
              </h2>
              <h4>thanks for checking out my website! </h4>
              <p>i'm mel, and i'm a big fan of creatures.</p>
              <p>
                i'd like to thank{' '}
                <a
                  className="link-underline"
                  href="https://www.inaturalist.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  iNaturalist
                </a>{' '}
                for being an amazing tool and resource, and a big inspiration
                for me to spend more time learning about the critters around me.
              </p>
              <p>
                i made this site as a way to practice my identification skills,
                and i hope it can help others too ♥
              </p>
              <h2>my links</h2>
              <ul className="my-links">
                <li>
                  <a
                    className="link-underline"
                    href="https://meltknuckles.info/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    personal site
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://www.instagram.com/meltknuckles/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    instagram
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://meltknuckles.tumblr.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    tumblr
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://cohost.org/meltknuckles"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cohost
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://www.inaturalist.org/people/meltknuckles"
                    target="_blank"
                    rel="noreferrer"
                  >
                    inaturalist
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://bsky.app/profile/meltknuckles.bsky.social"
                    target="_blank"
                    rel="noreferrer"
                  >
                    bluesky
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://twitter.com/meltknuckles"
                    target="_blank"
                    rel="noreferrer"
                  >
                    twitter
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://meltknuckles.itch.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    itch.io
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 md:col-5 text-center">
              <a
                className="link-underline"
                href="https://www.inaturalist.org/observations/187297537"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#000' }}
              >
                <img
                  src={bee}
                  style={{ width: '100%', borderRadius: 5 }}
                  alt="bee on a fingertip"
                />
                <em style={{ opacity: 0.75 }}>
                  a sleepy{' '}
                  <span
                    className="bee-tooltip"
                    data-pr-tooltip="Common Eastern Bumble Bee"
                    data-pr-position="bottom"
                  >
                    bee
                  </span>{' '}
                  i found in a graveyard in portsmouth NH
                </em>
              </a>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
