import { useState, useEffect } from 'react';
const confetti = require('canvas-confetti').default;

export const useConfetti = () => {
  const [fireworkStart, setFireworkStart] = useState(0);

  useEffect(() => {
    const duration = 3 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const randomInRange = (min: number, max: number) => {
      return Math.random() * (max - min) + min;
    };
    if (fireworkStart) {
      const interval: any = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          }),
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          }),
        );
      }, 100);

      return () => clearInterval(interval);
    }
  }, [fireworkStart]);

  const setOffFireworks = () => {
    setFireworkStart(new Date().getTime());
  };

  return { setOffFireworks };
};
