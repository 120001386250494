import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Badge } from 'primereact/badge';
import { useForm } from 'react-hook-form';
import { HiMiniFaceSmile, HiMiniFaceFrown } from 'react-icons/hi2';
import { AiFillFire } from 'react-icons/ai';
import { BsGearFill } from 'react-icons/bs';
import { CURRENT_VERSION, KINGDOM, TAXONOMY } from '../../constants';
import { useObservations } from '../../hooks/useObservations';
import {
  ResultType,
  displayNameFormat,
  useLevelData,
} from '../../hooks/useLevels';
import { GuessInput } from './GuessInput';
import { GuessImage } from './GuessImage';
import { PracticeSettings } from './PracticeSettings';
import { useConfetti } from '../../hooks/useConfetti';
import {
  DEFAULT_GAME,
  DEFAULT_SETTINGS,
  usePractice,
} from '../../hooks/usePractice';
import './Practice.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { InstructionsModal } from './InstructionsModal';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Stats } from '../../hooks/useStats';
import { Tooltip } from 'primereact/tooltip';
import { TbMushroomFilled } from 'react-icons/tb';
import { PiBirdFill, PiPlantFill } from 'react-icons/pi';

export const Practice = (props: {
  addGameToStats: any;
  stats: Stats;
  addToBlacklist: any;
  showAlert: any;
  storedVersion: string;
}) => {
  const { addGameToStats, stats, showAlert, addToBlacklist, storedVersion } =
    props;
  const [infoModalOpen, setInfoModalOpen] = useState<any>(false);
  const [loadingParent, setLoadingParent] = useState(false);
  const [instructionsModalOpen, setInstructionsModalOpen] = useState(false);
  const [completionModalOpen, setCompletionModalOpen] = useState<any>(false);
  const [, setRefresh] = useState(0);
  const { setOffFireworks } = useConfetti();

  const isSmallDevice = useMediaQuery('only screen and (max-width : 575px)');
  const {
    game,
    settings,
    setGame,
    setMapLocation,
    setSettings,
    resetGame,
    resetSettings,
    dismissWarning,
  } = usePractice();

  useEffect(() => {
    if (storedVersion !== CURRENT_VERSION) {
      resetGame();
      setSettings({
        ...DEFAULT_SETTINGS,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedVersion]);

  const navigate = useNavigate();

  const {
    currentTree,
    loading: observationLoading,
    setLoading: setObservationLoading,
    getNewObservation,
    getObservation,
    setCurrentSearchPageCount,
  } = useObservations({ game, setGame, stats, showAlert, navigate, resetGame });
  const getQueryStringParams = (query: any) =>
    query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params: any, param: any) => {
            let [key, value] = param.split('=');
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, ' '))
              : '';
            return params;
          }, {})
      : {};
  const location = useLocation();
  const {
    id: existingId,
    taxon_id,
    without_taxon_id,
  } = getQueryStringParams(location.search);

  useEffect(() => {
    const kingdom = currentTree?.find(({ rank }) => rank === 'kingdom');
    if (kingdom) {
      setGame({
        ...game,
        lockedIn: {
          ...game.lockedIn,
          kingdom,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTree]);

  useEffect(() => {
    if (!observationLoading && existingId && !game.currentObservation?.id) {
      setObservationLoading(true);
      getObservation(existingId)
        .then((res) => {
          setGame({
            ...DEFAULT_GAME,
            maxGuesses: settings.maxGuesses,
            currentObservation: res,
            startTime: new Date().toISOString(),
          });
        })
        .catch((err) => {
          showAlert({
            severity: 'error',
            summary: 'Error getting observation!',
            detail: err.message,
            sticky: true,
          });
          navigate('/');
        })
        .finally(() => setObservationLoading(false));
    }
  }, [
    existingId,
    game.currentObservation,
    getObservation,
    navigate,
    showAlert,
    observationLoading,
    setObservationLoading,
    setGame,
    settings.maxGuesses,
  ]);

  const items: Record<string, Record<string, any>> = {
    kingdom: {
      name: 'kingdom',
      suggestions: KINGDOM.map((i: Record<string, any>) => ({
        ...i,
        display_name: displayNameFormat(i.name, i.common_name),
      })),
      dropdown: true,
    },
    phylum: { name: 'phylum', suggestions: [] },
    class: { name: 'class', suggestions: [] },
    order: { name: 'order', suggestions: [] },
    family: { name: 'family', suggestions: [] },
    genus: { name: 'genus', suggestions: [] },
    species: { name: 'species', suggestions: [] },
  };
  const fullIncorrectGuess: ResultType[] = [];
  const [filteredItems, setFilteredItems] =
    useState<Record<string, Record<string, any>>>(items);

  const { handleSubmit, control, getValues, setValue, reset } = useForm();
  const onSubmit = (data: any) => {
    const correctValues: Record<string, any> = {};
    for (const i in Object.keys(data)) {
      const key = Object.keys(data)[i];
      const value = data[key];
      if (value && currentTree && value.id === currentTree[i].id) {
        correctValues[key] = value;
      } else {
        fullIncorrectGuess.push({ ...value, correct: false });
      }
    }

    const newGame = {
      ...game,
      incorrectGuesses: [...game.incorrectGuesses, fullIncorrectGuess],
      lockedIn: correctValues,
    };
    reset();

    if (Object.keys(newGame.lockedIn).length === TAXONOMY.length) {
      newGame.status = 'won';
      setOffFireworks();
      setCompletionModalOpen(currentTree?.[currentTree.length - 1]);
      if (!game.startTime)
        addGameToStats(
          'practice',
          newGame,
          currentTree,
          new Date().getTime() - new Date(game.startTime as string).getTime(),
        );
    } else if (game.incorrectGuesses.length + 1 >= game.maxGuesses) {
      newGame.status = 'lost';
      const newLockedIn: Record<string, any> = {
        ...game.lockedIn,
      };
      for (const i in currentTree) {
        const level = TAXONOMY[i as any];
        if (!game.lockedIn[level]) {
          newLockedIn[level] = {
            ...currentTree[i as any],
            wrong: true,
          };
        }
      }
      addGameToStats(
        'practice',
        newGame,
        currentTree,
        new Date().getTime() - new Date(game.startTime as string).getTime(),
      );
      setCompletionModalOpen(currentTree?.[currentTree.length - 1]);
    }
    setGame(newGame);
  };

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prev = usePrevious(existingId);
  useEffect(() => {
    if (prev && !existingId) {
      resetGame();
    }
  }, [existingId, prev, resetGame]);

  const levels = useLevelData(getValues, game.lockedIn, showAlert, {
    taxon_id,
    without_taxon_id,
  });
  if (observationLoading) {
    return (
      <div
        className="grid grid-container"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <ProgressSpinner />
      </div>
    );
  }
  if (!game.currentObservation) {
    return (
      <>
        <PracticeSettings
          {...{
            dismissWarning,
            game,
            getNewObservation,
            resetSettings,
            setCurrentSearchPageCount,
            setGame,
            setInstructionsModalOpen,
            setMapLocation,
            setSettings,
            settings,
            showAlert,
            resetGame,
          }}
        />
        <InstructionsModal
          setInstructionsModalOpen={setInstructionsModalOpen}
          instructionsModalOpen={instructionsModalOpen}
        />
      </>
    );
  }

  let guessText = `Remaining Guesses: ${
    game.maxGuesses - game.incorrectGuesses.length
  }`;
  if (game.status === 'won') {
    const guessLength = game.incorrectGuesses.length;
    guessText = `You got it in ${guessLength} guess${
      guessLength > 1 ? 'es' : ''
    }!`;
  } else if (game.status === 'lost') {
    guessText = `Better luck next time :(`;
  }
  const values = getValues();
  return (
    <div className="grid grid-container">
      <Tooltip target=".info-tooltip" />
      <div className="col-12 md:col-11 xl:col-10">
        <Button
          type="button"
          label="Back to Settings"
          icon="pi pi-arrow-left"
          rounded
          text
          aria-label="Back to Settings"
          style={{ padding: '2px 20px', borderRadius: 10, marginBottom: 12 }}
          onClick={() => {
            resetGame();
            reset();
            navigate('/practice');
          }}
        />
        {/* <div
          style={{
            display: 'inline-block',
            fontFamily: 'monospace',
            float: 'right',
          }}
        >
          <ToggleButton
            style={{ fontSize: '0.75em' }}
            className={`time-toggle-btn ${showTimer ? 'timer-shown' : ''}`}
            color="secondary"
            onLabel={`${
              days > 0 ? `${days.toString().padStart(2, '0')}:` : ''
            }${
              hours > 0 ? `${hours.toString().padStart(2, '0')}:` : ''
            }${minutes.toString().padStart(2, '0')}:${seconds
              .toString()
              .padStart(2, '0')}`}
            offLabel="Toggle Timer"
            onIcon="pi pi-fw pi-times"
            offIcon="pi pi-fw pi-stopwatch"
            checked={showTimer}
            onChange={(e: any) => setShowTimer(e.value)}
          />
        </div> */}

        <Panel
          style={{ marginBottom: 16 }}
          headerTemplate={() => (
            <div className="p-panel-header practice">
              <div
                className="col-8 md:col-8"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: 0,
                }}
              >
                Identification Practice
                {stats.practice.streak > 0 && (
                  <div style={{ display: 'inline-block', marginTop: -6 }}>
                    <AiFillFire
                      style={{
                        width: 32,
                        height: 32,
                        marginLeft: 4,
                        marginBottom: -8,
                        color: '#ff9b00',
                      }}
                    />
                    <Badge
                      value={stats.practice.streak}
                      style={{
                        marginLeft: -28,
                        marginTop: -5,
                        color: 'white',
                        backgroundColor: 'transparent',
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="col" style={{ padding: 0, textAlign: 'right' }}>
                <Button
                  link
                  size="small"
                  icon="pi pi-question-circle"
                  rounded
                  onClick={() => setInstructionsModalOpen(true)}
                />
              </div>
            </div>
          )}
        >
          <h2 style={{ marginTop: 0 }}>{guessText}</h2>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <div className="grid">
              <div className="col-12 xl:col-7 flex-order-2 xl:flex-order-1 guess-container">
                {Object.keys(items).map((level: string, i: number) => {
                  const value = game.lockedIn[level] ?? getValues(level);
                  const correct =
                    Array.isArray(currentTree) &&
                    !game.lockedIn?.[level]?.wrong &&
                    game.currentObservation?.ident_taxon_ids.includes(
                      game.lockedIn[level]?.id,
                    );
                  const backroundColors = [
                    '0',
                    '0',
                    '20',
                    '50',
                    '100',
                    '200',
                    '275',
                  ];
                  let backgroundColor = correct
                    ? `hsl(${backroundColors[i]}, 95%, 85%)`
                    : 'transparent';
                  let oppositeColor = !correct
                    ? `hsl(${backroundColors[i]}
                      }, 95%, 85%)`
                    : 'transparent';
                  if (correct && i === 0) {
                    backgroundColor = 'white';
                    oppositeColor = '#999';
                  }
                  const wikiInfo =
                    game.status === 'lost'
                      ? currentTree?.[TAXONOMY.indexOf(level)]
                      : game.lockedIn[level] ?? value;
                  const kingdom = currentTree?.find(
                    ({ rank }) => rank === 'kingdom',
                  );
                  return (
                    <div
                      key={level}
                      className="grid level-container"
                      style={{
                        borderBottom:
                          i === Object.keys(items).length - 1
                            ? 'none'
                            : '1px solid #eee',
                      }}
                    >
                      <div
                        className="col-1 level-status"
                        style={{
                          background: backgroundColor,
                          border: `${i === 0 ? 1 : 2}px solid ${oppositeColor}`,
                          borderRadius: 50,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: correct ? '#555' : oppositeColor,
                        }}
                      >
                        {level === 'kingdom' ? (
                          <div>
                            {kingdom?.name === 'Fungi' && (
                              <div>
                                <TbMushroomFilled />
                              </div>
                            )}
                            {kingdom?.name === 'Animalia' && (
                              <div>
                                <PiBirdFill />
                              </div>
                            )}
                            {kingdom?.name === 'Plantae' && (
                              <div>
                                <PiPlantFill />
                              </div>
                            )}
                          </div>
                        ) : (
                          <i
                            className={`pi pi-${correct ? 'check' : 'times'}`}
                          />
                        )}
                      </div>
                      <div
                        className="col-2 capitalize"
                        style={{ textAlign: 'center', paddingTop: 16 }}
                      >
                        {level}
                      </div>
                      <div className="col">
                        <GuessInput
                          {...{
                            level,
                            control,
                            filteredItems,
                            game,
                            levels,
                            items,
                            getValues,
                            setFilteredItems,
                            setValue,
                            i,
                            loadingParent,
                            setLoadingParent,
                            correct,
                            currentTree,
                            settings,
                            setRefresh,
                            showAlert,
                          }}
                        />
                      </div>
                      {wikiInfo?.wikipedia_url && (
                        <div className="col-auto" style={{ paddingTop: 16 }}>
                          <span
                            className="info-tooltip"
                            data-pr-tooltip="Instructions"
                            data-pr-position="bottom"
                            style={{ display: 'inline-block' }}
                          >
                            <Button
                              type="button"
                              icon="pi pi-info-circle"
                              rounded
                              text
                              aria-label="Info"
                              onClick={() => setInfoModalOpen(wikiInfo)}
                              style={{ marginTop: -8, marginLeft: -6 }}
                            />
                          </span>
                          <a
                            href={wikiInfo.wikipedia_url}
                            style={{
                              opacity: wikiInfo?.wikipedia_url ? 1 : 0,
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              type="button"
                              icon="pi  pi-external-link"
                              rounded
                              text
                              disabled={!wikiInfo?.wikipedia_url}
                              aria-label="Wikipedia Link"
                              style={{ marginTop: -8 }}
                            />
                          </a>
                        </div>
                      )}
                    </div>
                  );
                })}
                {game.status === '' && (
                  <div className="grid">
                    <div className="col-12 sm:col-5 md:col-4">
                      <Button
                        size={isSmallDevice ? 'small' : 'large'}
                        rounded
                        type="button"
                        severity="secondary"
                        disabled={loadingParent}
                        label="Give Up"
                        icon={<HiMiniFaceFrown />}
                        style={{ width: '100%' }}
                        onClick={() => {
                          setCompletionModalOpen(
                            currentTree?.[currentTree.length - 1],
                          );
                          setGame({ ...game, status: 'lost' });
                        }}
                      />
                    </div>
                    <div className="col-12 sm:col-7 md:col-8 guess-btn">
                      <Button
                        size="large"
                        className="green-button"
                        rounded
                        type="submit"
                        disabled={
                          loadingParent ||
                          Object.keys(values).filter((key) => !!values[key]?.id)
                            .length < 2
                        }
                        label="Guess!"
                        icon={<HiMiniFaceSmile />}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                )}

                {game.status !== '' && (
                  <div className="grid">
                    {/* <div className="col-12 md:col-5">
                      <Button
                        rounded
                        outlined
                        severity="secondary"
                        type="button"
                        label="Copy Result"
                        onClick={() => {
                          const incorrect = '✖️';
                          const correct = [
                            '⚪',
                            '🔴',
                            '🟠',
                            '🟡',
                            '🟢',
                            '🔵',
                            '🟣',
                          ];
                          let clipboard = TAXONOMY.map(
                            (key: string, idx: number) => {
                              const level = game.lockedIn[key];
                              return level ? correct[idx] : incorrect;
                            },
                          );
                          console.log('game', game);
                          console.log('clipboard', clipboard);
                          navigator.clipboard.writeText(clipboard.join(''));
                          showAlert({
                            severity: 'success',
                            summary: 'Copied result to keyboard 👍',
                          });
                        }}
                        icon="pi pi-share-alt"
                        style={{ width: '100%' }}
                      />
                    </div> */}
                    <div className="col-12">
                      {game.status && (
                        <a
                          href={game.currentObservation.uri}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: 'block',
                          }}
                        >
                          <Button
                            rounded
                            outlined
                            type="button"
                            icon="pi pi-search"
                            label="View iNaturalist Observation"
                            style={{ width: '100%' }}
                          />
                        </a>
                      )}
                    </div>
                    <div className="col-12 md:col-5">
                      <Button
                        size={isSmallDevice ? 'small' : 'large'}
                        rounded
                        type="button"
                        severity="secondary"
                        disabled={loadingParent}
                        label="Settings"
                        icon={<BsGearFill />}
                        style={{ width: '100%' }}
                        onClick={() => {
                          resetGame();
                          reset();
                          navigate('/practice');
                        }}
                      />
                    </div>
                    <div className="col-12 md:col-7">
                      <Button
                        size="large"
                        className="green-button"
                        rounded
                        type="button"
                        label="Try Another"
                        onClick={async () => {
                          reset();
                          let newObservation: any;
                          try {
                            newObservation = await getNewObservation(
                              settings,
                              true,
                            );
                          } catch {
                            navigate('/');
                            return;
                          }
                          if (!newObservation) {
                            showAlert({
                              severity: 'error',
                              message: 'Error fetching new observation!',
                            });
                            navigate('/practice');
                            resetGame();
                          } else {
                            navigate(`/practice?id=${newObservation.id}`);
                          }
                        }}
                        icon="pi pi-refresh"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 xl:col-5 flex-order-1 xl:flex-order-2 guess-container">
                <GuessImage currentObservation={game.currentObservation} />
              </div>
            </div>
          </form>
          <Dialog
            className="responsive-modal"
            header={infoModalOpen?.display_name}
            visible={!!infoModalOpen}
            onHide={() => setInfoModalOpen(false)}
            closeOnEscape
            dismissableMask
            draggable={false}
          >
            <div className="grid">
              <div
                className="col flex-order-2 md:flex-order-1"
                style={{ padding: 8, fontSize: '1.2em' }}
              >
                {infoModalOpen.wikipedia_summary && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: infoModalOpen.wikipedia_summary,
                    }}
                  />
                )}
              </div>
              <div
                className="col-12 sm:col-4 md:col-auto flex-order-1 md:flex-order-2"
                style={{ padding: 8 }}
              >
                <img
                  src={infoModalOpen?.photo}
                  style={{ maxWidth: '100%', borderRadius: 4 }}
                  alt={infoModalOpen?.display_name}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            className="responsive-modal"
            header={`${
              game.status === 'won' ? "You're right!" : 'Sorry, '
            } it was a ${
              completionModalOpen.common_name ?? completionModalOpen.name
            }!`}
            visible={!!completionModalOpen}
            onHide={() => setCompletionModalOpen(false)}
            draggable={false}
            closable={false}
          >
            <div className="grid" style={{ textAlign: 'center' }}>
              <div
                className="col-12"
                style={{ padding: 8, maxHeight: '60vh', overflow: 'hidden' }}
              >
                <img
                  className="game-end-img"
                  src={completionModalOpen?.photo}
                  alt={completionModalOpen?.display_name}
                />
              </div>
              <div
                className="col-12"
                style={{
                  padding: 8,
                  color: '#666',
                  fontSize: '1.1em',
                  textAlign: 'left',
                  marginTop: 6,
                }}
              >
                {completionModalOpen.wikipedia_summary && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: completionModalOpen.wikipedia_summary,
                    }}
                  />
                )}
              </div>
              {completionModalOpen.wikipedia_url && (
                <div className="col-12 md:col-4">
                  <a
                    href={completionModalOpen.wikipedia_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      type="button"
                      label="View Wiki Page"
                      severity="secondary"
                      icon="pi pi-external-link"
                      style={{
                        width: '100%',
                        marginTop: 12,
                      }}
                      size={isSmallDevice ? 'small' : 'large'}
                      rounded
                    />
                  </a>
                </div>
              )}
              <div
                className={
                  completionModalOpen.wikipedia_url
                    ? 'col-12 sm:col-11 md:col-9 lg:col-8'
                    : 'col-12'
                }
              >
                <Button
                  label="Neat!"
                  className="green-button"
                  icon="pi pi-thumbs-up-fill"
                  style={{ width: '100%', marginTop: 12 }}
                  size="large"
                  onClick={() => {
                    setCompletionModalOpen(false);
                    if (
                      !stats.practice.games.find(
                        ({ observationId }) =>
                          observationId === game.currentObservation?.id,
                      )
                    ) {
                      addGameToStats(
                        'practice',
                        game,
                        currentTree,
                        0,
                        new Date().getTime() -
                          new Date(game.startTime as string).getTime(),
                      );
                    } else {
                      showAlert({
                        severity: 'info',
                        summary: 'Duplicate Observation',
                        detail:
                          "This observation has already been guessed, it won't be added to stats",
                      });
                    }
                  }}
                  rounded
                />
              </div>
              {game.status === 'lost' && (
                <div className="col-12">
                  <Button
                    label="I don't think that was fair, don't add to stats"
                    style={{ width: '100%', marginBottom: -20 }}
                    size="small"
                    onClick={() => {
                      setCompletionModalOpen(false);
                      addToBlacklist(currentTree, game);
                    }}
                    link
                  />
                </div>
              )}
            </div>
          </Dialog>
        </Panel>
      </div>
      <InstructionsModal
        setInstructionsModalOpen={setInstructionsModalOpen}
        instructionsModalOpen={instructionsModalOpen}
      />
    </div>
  );
};
