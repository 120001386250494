import { Panel } from 'primereact/panel';
import React from 'react';

export const Daily = ({ showAlert }: any) => {
  const headerTemplate = (
    <div className="p-panel-header">
      <div style={{ padding: 6 }}>
        <i className="pi pi-fw pi-bolt" style={{ marginRight: 12 }} />
        Daily Challenge
      </div>
    </div>
  );
  return (
    <div className="grid grid-container">
      <div className="col-12 sm:col-12 md:col-11 lg:col-9">
        <Panel headerTemplate={headerTemplate}>
          <div style={{ textAlign: 'center', padding: 100 }}>Coming Soon!</div>
        </Panel>
      </div>
    </div>
  );
};
