import React, { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import { TbZoomQuestion } from 'react-icons/tb';
import { BsGearFill } from 'react-icons/bs';
import { useGeolocated } from 'react-geolocated';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@uidotdev/usehooks';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { MdAddReaction } from 'react-icons/md';
import {
  Accordion,
  AccordionTab,
  AccordionTabChangeEvent,
} from 'primereact/accordion';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import { Panel } from 'primereact/panel';
import { SelectButton } from 'primereact/selectbutton';
import { Message } from 'primereact/message';
import { useMountEffect } from 'primereact/hooks';
import {
  DEFAULT_SETTINGS,
  KINGDOM_SELECT_OPTIONS,
  Settings,
} from '../../hooks/usePractice';
import { MapContainer } from '../Map/Map';
import { useLowerTaxa } from '../../hooks/useLowerTaxa';
import { DEFAULT_PRESETS } from '../../constants';
import { Tooltip } from 'primereact/tooltip';
import { isSafari } from 'react-device-detect';

export const PracticeSettings = ({
  getNewObservation,
  settings,
  setMapLocation,
  setSettings,
  resetSettings,
  setInstructionsModalOpen,
  setCurrentSearchPageCount,
  dismissWarning,
  showAlert,
  resetGame,
}: {
  getNewObservation: any;
  settings: Settings;
  setMapLocation: any;
  setSettings: (settings: Settings) => void;
  resetSettings: (settings: any) => void;
  setInstructionsModalOpen: (open: boolean) => void;
  setCurrentSearchPageCount: any;
  dismissWarning: any;
  showAlert: any;
  resetGame: any;
}) => {
  const [query, setQuery] = useState('');
  const { taxaSuggestions } = useLowerTaxa(query, settings, showAlert);
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 575px)');

  const { coords, isGeolocationAvailable, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    suppressLocationOnMount: !settings.location.enabled,
    isOptimisticGeolocationEnabled: false,
  });

  useEffect(() => {
    if (coords && !settings.location.latitude) {
      setMapLocation({
        ...settings.location,
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  const [selectedPreset, setSelectedPreset] = useState(null);
  const msgs: any = useRef(null);
  const calendarRef: any = useRef(null);
  // const startTimeRef: any = useRef(null);
  // const endTimeRef: any = useRef(null);
  const [modalType, setModalType] = useState<null | string>(null);
  const [newPresetName, setNewPresetName] = useState('');
  const [importSetting, setImportSetting] = useState('');
  const [emoji, setEmoji] = useState('');
  const [selectingEmoji, setSelectingEmoji] = useState(false);
  useMountEffect(() => {
    if (msgs.current && !settings.dismissedWarning) {
      msgs.current.clear();
      msgs.current.show([
        {
          sticky: true,
          severity: 'warn',
          summary: 'Content Warning!',
          detail: (
            <div style={{ paddingLeft: 16 }}>
              These randomized results can sometimes contain deceased animals
              <span style={{ fontSize: '0.7em', display: 'block' }}>
                Most commonly fish, birds, and roadkill
              </span>
            </div>
          ),
        },
      ]);
    }
  });
  const exportSettings: any = _.omit(settings, [
    'dismissedWarning',
    'maxGuesses',
    'multiplePhotos',
    'savedPresets',
    'advancedSettingsOpen',
  ]);
  let modalDisabled = true;
  let exportSettingsValue = '';
  try {
    if (!settings.location.enabled && exportSettings.location) {
      delete exportSettings.location;
    }
    if (!settings.date.enabled && exportSettings.date) {
      delete exportSettings.date;
    }
    if (!settings.time.enabled && exportSettings.time) {
      delete exportSettings.time;
    }
    exportSettingsValue = JSON.stringify(exportSettings);

    if (modalType === 'save') {
      if (emoji && newPresetName) {
        modalDisabled = false;
      }
    } else {
      try {
        JSON.parse(importSetting);
        modalDisabled = false;
      } catch {}
    }
  } catch (err) {
    console.error(err);
    resetSettings({
      latitude: coords?.latitude,
      longitude: coords?.longitude,
    });
  }
  const headerTemplate = (
    <div className="p-panel-header practice-settings">
      <div className="panel-title">
        <BsGearFill style={{ marginRight: 12 }} />
        Identification Practice Settings
      </div>

      <div className="practice-presets">
        <div className="p-inputgroup flex-1">
          <Dropdown
            value={selectedPreset}
            onChange={(e) => {
              setSelectedPreset(e.value);
              setSettings({
                ...settings,
                ...e.value.setting,
              });
            }}
            options={[...DEFAULT_PRESETS, ...(settings.savedPresets ?? [])]}
            optionLabel="name"
            placeholder="Practice Presets"
            className="w-full md:w-14rem"
            itemTemplate={(option) => (
              <>
                {option.emoji}
                <span style={{ marginLeft: 10 }}>{option.name}</span>
              </>
            )}
          />
          <Button
            type="button"
            icon="pi pi-save"
            className="p-button green-button"
            onClick={() => setModalType('save')}
            disabled={_.isEqual(settings, DEFAULT_SETTINGS)}
            tooltip="Save Practice Preset"
            tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
          />
          <Button
            type="button"
            icon="pi pi-file-import"
            className="p-button-secondary"
            onClick={() => setModalType('import')}
            disabled={_.isEqual(settings, DEFAULT_SETTINGS)}
            tooltip="Import Practice Preset"
            tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
          />
          <Dialog
            className="responsive-modal"
            header={
              modalType === 'save'
                ? 'Save Practice Preset'
                : 'Import Practice Preset'
            }
            visible={!!modalType}
            style={{ width: '50vw' }}
            onHide={() => setModalType(null)}
            draggable={false}
            resizable={false}
            dismissableMask
            footer={
              <div>
                <Button
                  className="green-button"
                  label={modalType === 'save' ? 'Save Preset' : 'Import Preset'}
                  icon={
                    modalType === 'save' ? 'pi pi-save' : 'pi pi-file-import'
                  }
                  rounded
                  disabled={modalDisabled}
                  onClick={() => {
                    setSettings({
                      ...settings,
                      savedPresets: [
                        ...settings.savedPresets,
                        {
                          name: newPresetName,
                          setting: exportSettings,
                          emoji,
                        },
                      ],
                    });
                    setModalType(null);
                  }}
                />
              </div>
            }
          >
            {modalType === 'save' ? (
              <div>
                <label
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 8,
                    display: 'block',
                  }}
                >
                  Click Textbox Below to Copy this Practice Setting to your
                  Clipboard
                </label>
                <InputTextarea
                  value={exportSettingsValue}
                  onChange={() => {}}
                  onClick={() => {
                    navigator.clipboard.writeText(exportSettingsValue);
                    showAlert({
                      severity: 'success',
                      summary: 'Successfully copied to clipboard!',
                    });
                  }}
                  rows={3}
                  className="p-variant-filled"
                  style={{ width: '100%', color: '#777' }}
                  spellCheck={false}
                />

                {selectingEmoji && (
                  <div>
                    <div className="fade-bg"></div>
                    <EmojiPicker
                      lazyLoadEmojis
                      emojiStyle={EmojiStyle.NATIVE}
                      skinTonesDisabled
                      previewConfig={{
                        defaultCaption: 'Pick an icon!',
                        showPreview: false,
                      }}
                      onEmojiClick={(e) => {
                        setEmoji(e?.emoji || '');
                        setSelectingEmoji(false);
                      }}
                    />
                  </div>
                )}
                <h4>Save Preset Name and Emoji</h4>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    outlined
                    rounded
                    className="emoji-btn"
                    style={{
                      borderRadius: '20px 0 0 20px',
                      padding: 16,
                      paddingLeft: 24,
                      borderRight: 'none',
                    }}
                    onClick={() => setSelectingEmoji(true)}
                    icon={emoji ? null : <MdAddReaction />}
                    label={emoji}
                  ></Button>
                  <InputText
                    style={{ width: '100%' }}
                    value={newPresetName}
                    onChange={(e) => setNewPresetName(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <InputTextarea
                className={(() => {
                  try {
                    if (importSetting) {
                      JSON.parse(importSetting);
                    }
                    return '';
                  } catch {
                    return 'p-invalid';
                  }
                })()}
                value={importSetting}
                onChange={(e) => setImportSetting(e.target.value)}
                rows={10}
                style={{ width: '100%' }}
              />
            )}
          </Dialog>
          <Button
            type="button"
            link
            size="small"
            icon="pi pi-question-circle"
            rounded
            style={{ marginLeft: 12 }}
            tooltip="View Instructions"
            tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
            onClick={() => setInstructionsModalOpen(true)}
          />
        </div>
      </div>
    </div>
  );
  useEffect(() => {
    const newSettings = { ...settings };
    if (newSettings.endemic === 'include') {
      newSettings.introduced = 'exclude';
    }
    if (newSettings.introduced === 'include') {
      newSettings.endemic = 'exclude';
    }
    setSettings(newSettings);
  }, [settings, setSettings]);
  const introducedDisabled = settings.endemic === 'include';
  const endemicdDisabled = settings.introduced === 'include';

  const advancedSettings =
    settings.include.length > 0 ||
    settings.exclude.length > 0 ||
    settings.endemic ||
    settings.introduced;
  return (
    <div className="grid grid-container">
      <div className="col-12 sm:col-12 md:col-11 lg:col-9">
        <Panel headerTemplate={headerTemplate}>
          <Messages onRemove={() => dismissWarning()} ref={msgs} />
          <p>
            <em>Need instructions?</em> Click the info button on the top right
            corner of this panel.
          </p>
          <form style={{ paddingLeft: 8, paddingRight: 8 }}>
            <div className="grid">
              <div
                className="col-12"
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <label
                  style={{
                    display: 'block',
                    paddingTop: 10,
                    fontWeight: 'bold',
                    marginBottom: 4,
                  }}
                >
                  Kingdoms{' '}
                </label>
                <div
                  className="kingdom-select-container"
                  style={{ width: '100%' }}
                >
                  <SelectButton
                    value={settings.kingdoms}
                    onChange={(e) => {
                      const newSettings = {
                        ...settings,
                        kingdoms: e.value,
                      };
                      setSelectedPreset(null);
                      setSettings(newSettings);
                      setCurrentSearchPageCount(0);
                    }}
                    optionLabel="name"
                    options={KINGDOM_SELECT_OPTIONS}
                    itemTemplate={({ icon: Icon, name }) => (
                      <div style={{ fontSize: '1.25em' }}>
                        <Icon className="kingdom-icon" />
                        {name}
                      </div>
                    )}
                    multiple
                  />
                </div>
              </div>
              <div className="col"></div>
            </div>
            <Accordion
              activeIndex={settings.advancedSettingsOpen ? 0 : null}
              onTabChange={(e: AccordionTabChangeEvent) =>
                setSettings({
                  ...settings,
                  advancedSettingsOpen: e.index === 0,
                })
              }
            >
              <AccordionTab
                header={
                  <span>
                    <Tooltip target=".exclamation-tooltip" />
                    {advancedSettings && (
                      <i
                        className="pi pi-exclamation-circle exclamation-tooltip"
                        style={{ marginRight: 12 }}
                        data-pr-tooltip="Filters in effect!"
                        data-pr-position="bottom"
                      />
                    )}
                    Advanced Taxonomic Filtering
                  </span>
                }
              >
                <div className="grid">
                  <div
                    className="col-12"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <label
                      style={{
                        display: 'block',
                        paddingTop: 10,
                        fontWeight: 'bold',
                        marginBottom: 4,
                      }}
                    >
                      Phylum / Class / Order / Family / Genus / Species
                    </label>
                    <div style={{ width: '100%', marginTop: 16 }}>
                      <label style={{ fontWeight: 'bold' }}>Include:</label>
                      <AutoComplete
                        multiple
                        field="display_name"
                        suggestions={taxaSuggestions}
                        value={settings.include}
                        style={{ width: '100%' }}
                        forceSelection
                        completeMethod={(e: any) => setQuery(e.query)}
                        onBlur={() => setQuery('')}
                        onSelect={(e: any) => {
                          const newSettings = { ...settings };
                          newSettings.include.push(e.value);
                          setSettings(newSettings);
                        }}
                        onUnselect={(e: any) => {
                          const newSettings = { ...settings };
                          newSettings.include = newSettings.include.filter(
                            (p: any) => p.id !== e.value.id,
                          );
                          setSettings(newSettings);
                        }}
                      />
                    </div>
                    <div style={{ width: '100%', marginTop: 16 }}>
                      <label style={{ fontWeight: 'bold' }}>Exclude:</label>
                      <AutoComplete
                        multiple
                        field="display_name"
                        suggestions={taxaSuggestions}
                        value={settings.exclude}
                        style={{ width: '100%' }}
                        forceSelection
                        completeMethod={(e: any) => setQuery(e.query)}
                        onBlur={() => setQuery('')}
                        onSelect={(e: any) => {
                          const newSettings = { ...settings };
                          newSettings.exclude.push(e.value);
                          setSettings(newSettings);
                        }}
                        onUnselect={(e: any) => {
                          const newSettings = { ...settings };
                          newSettings.exclude = newSettings.exclude.filter(
                            (p: any) => p.id !== e.value.id,
                          );
                          setSettings(newSettings);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
                <div className="grid mobile-row">
                  <div className="col">
                    <label
                      style={{
                        display: 'block',
                        paddingTop: 10,
                        fontWeight: 'bold',
                        marginBottom: 4,
                      }}
                    >
                      Introduced Species
                    </label>
                    <span className="p-button-group p-component btn-group">
                      <Button
                        className="pref-button"
                        severity="secondary"
                        type="button"
                        outlined={settings.introduced !== ''}
                        onClick={() =>
                          setSettings({
                            ...settings,
                            introduced: '',
                          })
                        }
                        label="No Preference"
                        rounded
                        disabled={introducedDisabled}
                      />
                      <Button
                        className="pref-button"
                        severity="secondary"
                        type="button"
                        outlined={settings.introduced !== 'include'}
                        onClick={() =>
                          setSettings({
                            ...settings,
                            introduced: 'include',
                          })
                        }
                        label="Only Introduced"
                        rounded
                        icon={isSmallDevice ? null : 'pi pi-check'}
                        disabled={introducedDisabled}
                      />
                      <Button
                        className="pref-button"
                        severity="secondary"
                        type="button"
                        outlined={settings.introduced !== 'exclude'}
                        onClick={() =>
                          setSettings({
                            ...settings,
                            introduced: 'exclude',
                          })
                        }
                        label="Exclude Introduced"
                        rounded
                        icon={isSmallDevice ? null : 'pi pi-times'}
                        disabled={introducedDisabled}
                      />
                    </span>
                  </div>
                </div>
                <div className="grid mobile-row">
                  <div className="col">
                    <label
                      style={{
                        display: 'block',
                        paddingTop: 10,
                        fontWeight: 'bold',
                        marginBottom: 4,
                      }}
                    >
                      Endemic Species
                    </label>
                    <span className="p-button-group p-component btn-group">
                      <Button
                        className="pref-button"
                        severity="secondary"
                        type="button"
                        outlined={settings.endemic !== ''}
                        onClick={() =>
                          setSettings({
                            ...settings,
                            endemic: '',
                          })
                        }
                        label="No Preference"
                        rounded
                        disabled={endemicdDisabled}
                      />
                      <Button
                        className="pref-button"
                        severity="secondary"
                        type="button"
                        outlined={settings.endemic !== 'include'}
                        onClick={() =>
                          setSettings({
                            ...settings,
                            endemic: 'include',
                          })
                        }
                        label="Only Endemic"
                        rounded
                        icon={isSmallDevice ? null : 'pi pi-check'}
                        disabled={endemicdDisabled}
                      />
                      <Button
                        className="pref-button"
                        severity="secondary"
                        type="button"
                        outlined={settings.endemic !== 'exclude'}
                        onClick={() =>
                          setSettings({
                            ...settings,
                            endemic: 'exclude',
                          })
                        }
                        label="Exclude Endemic"
                        rounded
                        icon={isSmallDevice ? null : 'pi pi-times'}
                        disabled={endemicdDisabled}
                      />
                    </span>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
            <div className="grid mobile-row" style={{ marginTop: 8 }}>
              <div
                className="col-12"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputSwitch
                    checked={settings.date?.enabled}
                    onChange={(e) => {
                      const newSettings = {
                        ...settings,
                        date: { ...settings.date, enabled: !!e.value },
                      };
                      if (!e.value) {
                        newSettings.time.enabled = false;
                      }
                      setSettings(newSettings);
                    }}
                  />
                  <label style={{ padding: 16 }}>Enable Date Filtering</label>
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputSwitch
                    checked={settings.time.enabled}
                    onChange={(e) => {
                      const newSettings = {
                        ...settings,
                        time: { ...settings.time, enabled: e.value },
                      };

                      if (e.value) {
                        newSettings.date.enabled = true;
                      }
                      // if (startTimeRef && endTimeRef) {
                      //   startTimeRef.current.updateViewDate(e, start);
                      //   endTimeRef.current.updateViewDate(e, end);
                      // }
                      setSettings(newSettings);
                    }}
                  />
                  <label
                    style={{
                      padding: 16,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Enable Time Filtering{' '}
                    <span
                      style={{
                        opacity: 0.8,
                        fontSize: '0.9em',
                        marginLeft: 10,
                      }}
                    >
                      (Requires Date)
                    </span>
                  </label>
                </div> */}
              </div>
            </div>
            <div
              className="grid mobile-row datetime-container"
              style={{
                height:
                  settings.date.enabled || settings.time.enabled ? 'auto' : 0,
              }}
            >
              <div
                className="col"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Calendar
                  ref={calendarRef}
                  disabled={!settings.date?.enabled}
                  placeholder={settings.date?.enabled ? 'Date Range' : ''}
                  value={
                    settings.date?.enabled
                      ? ([
                          settings.date.startDate
                            ? new Date(settings.date.startDate)
                            : null,
                          settings.date.endDate
                            ? new Date(settings.date.endDate)
                            : null,
                        ].filter(Boolean) as any)
                      : null
                  }
                  onChange={(e: any) => {
                    const startDate = e.value?.[0] || null;
                    const endDate = e.value?.[1] || null;
                    setSettings({
                      ...settings,
                      date: {
                        ...settings.date,
                        startDate,
                        endDate,
                      },
                    });

                    if (startDate && endDate && calendarRef?.current) {
                      calendarRef?.current.hide();
                    }
                  }}
                  style={{ width: isSmallDevice ? '100%' : '48%' }}
                  selectionMode="range"
                  showButtonBar
                  touchUI={isSmallDevice}
                  maxDate={new Date()}
                  monthNavigator
                  yearNavigator
                  yearRange="2009:2024"
                />
                {/*
                <Calendar
                  placeholder="Start Time"
                  ref={startTimeRef}
                  disabled={!settings.time.enabled}
                  style={{ width: '25%' }}
                  touchUI={isSmallDevice}
                  className={!settings.time.enabled ? 'hidden-time' : ''}
                  value={
                    settings.time.enabled && settings.time.startTime
                      ? new Date(settings.time.startTime)
                      : null
                  }
                  viewDate={
                    settings.time.enabled && settings.time.startTime
                      ? new Date(settings.time.startTime)
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      time: { ...settings.time, startTime: e.value },
                    });
                  }}
                  timeOnly
                  hourFormat="24"
                  inline
                />
                <Calendar
                  placeholder="End Time"
                  ref={endTimeRef}
                  disabled={!settings.time.enabled}
                  style={{ width: '25%' }}
                  touchUI={isSmallDevice}
                  className={!settings.time.enabled ? 'hidden-time' : ''}
                  timeOnly
                  value={
                    settings.time.enabled && settings.time.endTime
                      ? new Date(settings.time.endTime)
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      time: { ...settings.time, endTime: e.value },
                    });
                  }}
                  hourFormat="24"
                  inline
                /> */}
              </div>
            </div>
            {!isGeolocationAvailable && (
              <div>
                <Button label="test" />
              </div>
            )}
            <div className="grid mobile-row">
              {isSafari && (
                <div
                  className="col-12"
                  style={{ paddingBottom: 0, marginTop: 16 }}
                >
                  <Message
                    severity="warn"
                    text="There are known issues with location when
                  using Safari, if you encounter any problems please try another
                  browser"
                  />
                </div>
              )}
              <div
                className="col-12"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <InputSwitch
                  checked={settings.location?.enabled}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      location: { ...settings.location, enabled: !!e.value },
                    });
                    getPosition();
                  }}
                />
                <label style={{ padding: 16 }}>Enable Location Filtering</label>
              </div>
            </div>
            {settings.location?.enabled && (
              <MapContainer
                location={settings.location}
                setMapLocation={setMapLocation}
                isGeolocationAvailable={isGeolocationAvailable}
                getPosition={getPosition}
              />
            )}
            {/* <div className="grid" style={{ marginTop: 20 }}>
              <div
                className="col"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <label
                  style={{
                    display: 'inline-block',
                    paddingTop: 10,
                    paddingRight: 10,
                  }}
                >
                  Number of Guesses
                </label>
                <InputNumber
                  value={settings.maxGuesses}
                  onChange={(e) => {
                    setSettings({ ...settings, maxGuesses: e.value });
                    setCurrentSearchPageCount(0);
                    setSelectedPreset(null);
                  }}
                  min={1}
                  max={20}
                  showButtons
                />
              </div>
            </div> */}
          </form>
          <div
            className="grid"
            style={{
              marginBottom: 10,
              marginTop: 16,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <div className="col-12 md:col-4">
              <Button
                severity="secondary"
                label="Reset Settings"
                size={isSmallDevice ? 'small' : 'large'}
                icon="pi pi-fw pi-refresh"
                rounded
                onClick={() => {
                  resetSettings({
                    latitude: coords?.latitude,
                    longitude: coords?.longitude,
                  });
                  window.location.reload();
                }}
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div className="col-12 md:col-8">
              <Button
                className="green-button"
                label="Start Identifying!"
                size="large"
                rounded
                icon={<TbZoomQuestion />}
                style={{ width: '100%' }}
                disabled={
                  settings.kingdoms.length === 0 ||
                  (settings.date?.enabled &&
                    (!settings.date.startDate || !settings.date.endDate))
                }
                onClick={async () => {
                  try {
                    const newObservation = await getNewObservation(
                      settings,
                      false,
                    );

                    if (newObservation) {
                      let i = '';
                      let e = '';
                      if (settings.include.length > 0) {
                        i += `&taxon_id=${settings.include
                          .map((s: any) => s.id ?? s)
                          .join(',')}`;
                      }
                      if (settings.exclude.length > 0) {
                        i += `&without_taxon_id=${settings.exclude
                          .map((s: any) => s.id ?? s)
                          .join(',')}`;
                      }
                      navigate(`/practice?id=${newObservation.id}${i}${e}`);
                      window.scrollTo(0, 0);
                    } else {
                      throw new Error('No result found!');
                    }
                  } catch (err: any) {
                    console.error('err message', err.message);
                    resetGame();
                    showAlert({
                      severity: 'error',
                      summary: 'Error Getting New Observation!',
                      detail: err.message,
                      sticky: true,
                    });
                    navigate('/practice');
                    window.scrollTo(0, 0);
                  }
                }}
              />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
