import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Image as PrimereactImage } from 'primereact/image';
import { Galleria } from 'primereact/galleria';
import { AiOutlineHome } from 'react-icons/ai';
import { LuSmile } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { useMountEffect } from 'primereact/hooks';
import axios from 'axios';
import { shortenLocation } from '../../utils/shortenLocation';
import './Home.css';

export const Home = ({ showAlert }: any) => {
  const navigate = useNavigate();
  const [homepagePhotos, setHomepagePhotos] = useState<any[]>([]);

  const headerTemplate = (
    <div className="p-panel-header">
      <div style={{ padding: 6 }}>
        <AiOutlineHome style={{ marginRight: 12 }} />
        Home
      </div>
    </div>
  );
  useMountEffect(() => {
    const page = `${Math.floor(Math.random() * 100)}`;
    const searchQueries: Record<string, string> = {
      photos: 'true',
      popular: 'true',
      rank: 'species',
      per_page: '25',
      identified: 'true',
      page,
      photo_license: [
        'cc-by',
        'cc-by-nc',
        'cc-by-nd',
        'cc-by-sa',
        'cc-by-nc-nd',
        'cc-by-nc-sa',
        'cc0',
      ].join(','),
    };
    const queryString = Object.keys(searchQueries)
      .map((key) => `${key}=${searchQueries[key]}`)
      .join('&');
    axios
      .get(`https://api.inaturalist.org/v1/observations?${queryString}`)
      .then(async ({ data: { results } }) => {
        const newHomepagePhotos = results.map(
          ({
            photos,
            observed_on_string,
            place_guess,
            species_guess,
            user,
            uri,
          }: any) => ({
            photo: photos[0],
            species: species_guess,
            observed_on_string,
            place_guess,
            user,
            photoUrl: photos[0].url.replace('square', 'original'),
            uri,
          }),
        );
        setHomepagePhotos(newHomepagePhotos);
        const img = new Image();
        img.src = newHomepagePhotos[1];
      })
      .catch((err) => {
        console.error(err);
        showAlert({
          severity: 'error',
          summary: 'Error E010',
          detail: err.message,
          sticky: true,
        });
        navigate('/');
      });
  });
  const galleriaRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const item = homepagePhotos[activeIndex];
  const [width, setWidth] = useState(0);
  const ref: any = useRef(null);
  useEffect(() => {
    if (ref.current?.offsetWidth) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref, item, activeIndex, homepagePhotos]);
  const imgRef = useRef();

  const isSmallDevice = useMediaQuery('only screen and (max-width : 575px)');
  const sideStyle = {
    paddingRight: 32,
  };

  return (
    <div className="grid grid-container">
      <div className="col-12 lg:col-11 xl:col-8">
        <Panel headerTemplate={headerTemplate}>
          <div style={{ padding: 16 }}>
            <div className="grid">
              <div className="col-12 lg:col-8 side" style={sideStyle}>
                <h2 style={{ marginTop: 0 }}>
                  <LuSmile style={{ marginTop: -6 }} />{' '}
                  <div style={{ marginTop: -32, marginLeft: 40 }}>
                    Welcome to iDentifi
                  </div>
                </h2>
                <h3>
                  A Free Identification Practice Tool for Plants, Fungi, and
                  Wildlife
                </h3>
                <p>
                  Challenge yourself to identify the things found in nature, and
                  take a guess from the kingdom down to the species.
                  <br />
                  Hone your knowledge of taxonomy while simple stats are
                  collected to let you know where you need improvement, and what
                  areas you've mastered. No need to sign up, all stats are
                  stored locally on your browser.
                </p>
                <p>
                  This site is currently in an open beta testing stage, so there
                  may be bugs. User settings/stats may also reset with these
                  early updates.
                </p>
                <h4 style={{ marginBottom: 8 }}>
                  This site is powered by the{' '}
                  <a
                    className="link-underline"
                    href="https://api.inaturalist.org/v1/docs/"
                    target="blank"
                    rel="noreferrer"
                  >
                    iNaturalist API
                  </a>
                </h4>
                <div style={{ opacity: 0.8, marginTop: 16 }}>
                  <strong>REMEMBER</strong>: Do not consume any wild plants or
                  fungi without a <em>confident</em> identification.
                  <br />
                  <ul style={{ marginTop: 8 }}>
                    <li>
                      <a
                        href="https://www.wildedible.com/foraging"
                        target="_blank"
                        rel="noreferrer"
                      >
                        🍄🌱 Foraging Safety Tips
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 lg:col-4 text-right homepage-img-container hidden lg:block">
                {item && (
                  <div className="grid" style={{ width: '100%' }}>
                    <div className="col" style={{ position: 'relative' }}>
                      <Button
                        type="button"
                        ref={ref}
                        className="img-container"
                        onClick={() => {
                          (imgRef.current as any)?.show();
                          (galleriaRef.current as any)?.stopSlideShow();
                        }}
                        style={{
                          height: `${width}px`,
                          backgroundImage: `url("${item.photoUrl.replace(
                            'square',
                            'large',
                          )}")`,
                        }}
                      ></Button>

                      <PrimereactImage
                        ref={imgRef as any}
                        src={item.photoUrl.replace('square', 'large')}
                        alt="Image"
                        style={{ display: 'none' }}
                        preview
                        onHide={() =>
                          (galleriaRef.current as any)?.startSlideShow()
                        }
                      />
                      <a
                        href={item.uri}
                        target="_blank"
                        rel="noreferrer"
                        className="img-subtitle link-underline"
                      >
                        {item.species} &copy;{' '}
                        {item.user?.name || item.user?.login}
                        {item?.photos?.[0]?.attribution?.replace(
                          /\(c\).*?,/,
                          '',
                        )}
                        <br />
                        Observed in {shortenLocation(item?.place_guess)}
                      </a>
                    </div>
                  </div>
                )}
                <Galleria
                  ref={galleriaRef as any}
                  value={homepagePhotos}
                  autoPlay
                  circular
                  style={{ display: 'none' }}
                  showItemNavigators={false}
                  activeIndex={activeIndex}
                  onItemChange={(e) => {
                    setActiveIndex(e.index);
                    const img = new Image();
                    img.src = homepagePhotos[
                      (e.index + 1) % homepagePhotos.length
                    ].photoUrl.replace('square', 'large');
                  }}
                  showThumbnails={false}
                  transitionInterval={8000}
                />
              </div>
            </div>
            <div className="grid" style={{ marginTop: 16 }}>
              <div className="col-12 md:col-6">
                <Button
                  style={{ width: '100%' }}
                  severity="secondary"
                  rounded
                  outlined
                  size={isSmallDevice ? 'small' : 'large'}
                  icon="pi pi-fw pi-bolt"
                  onClick={() => navigate('/daily')}
                >
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexGrow: 1,
                    }}
                  >
                    <span>
                      Daily&nbsp;Challenge
                      <span className="coming-soon"> (Coming&nbsp;Soon!)</span>
                    </span>
                  </span>
                </Button>
              </div>
              <div className="col-12 md:col-6">
                <Button
                  className="green-button"
                  style={{ width: '100%' }}
                  label="Identification Practice"
                  rounded
                  size="large"
                  icon="pi pi-fw pi-stopwatch"
                  onClick={() => navigate('/practice')}
                ></Button>
              </div>
            </div>
          </div>
          <div className="grid mobile-row">
            <div className="col-12">
              <h3 style={{ margin: 0, marginBottom: 0 }}>
                Identification Resources
                <a
                  className="link-underline"
                  href="https://www.inaturalist.org/posts/82345-online-id-guides"
                  target="_blank"
                  rel="noreferrer"
                  style={{ paddingLeft: 8, fontSize: '0.75em' }}
                >
                  (Find more here!)
                </a>
              </h3>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <h4 style={{ margin: 0 }}>Birds</h4>
              <ul className="list">
                <li>
                  <a
                    className="link-underline"
                    href="http://www.fws.gov/lab/featheratlas/browse.php"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The Feather Atlas
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://www.allaboutbirds.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    All About Birds
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="https://www.audubon.org/bird-guide"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Audubon Bird Guide to North America
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <h4 style={{ margin: 0 }}>Plants</h4>
              <ul className="list">
                <li>
                  <a
                    className="link-underline"
                    href="http://plants.usda.gov/java/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    The Plant Database
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="http://www.arborday.org/trees/whattree/fullonline.cfm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    “What Tree is That?”
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="http://www.wildflower.org/plants/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Native plant database
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <h4 style={{ margin: 0 }}>Insects</h4>
              <ul className="list">
                <li>
                  <a
                    className="link-underline"
                    href="http://www.bugguide.net/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    BugGuide.net
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="http://www.butterfliesandmoths.org/identification_tools"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Butterflies and Moths
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <h4 style={{ margin: 0 }}>Fungi</h4>
              <ul className="list">
                <li>
                  <a
                    className="link-underline"
                    href="http://americanmushrooms.com/id.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    American Mushrooms
                  </a>
                </li>
                <li>
                  <a
                    className="link-underline"
                    href="http://www.mushroomexpert.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mushroom Expert
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
