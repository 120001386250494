import { useEffect, useState } from 'react';
import { useThrottle } from '@uidotdev/usehooks';
import axios from 'axios';
import { ResultType, formatDropdownOptionsFromApi } from './useLevels';
import { Settings } from './usePractice';

export const useLowerTaxa = (q: string, settings: Settings, showAlert: any) => {
  const throttleDelay = 3000;
  const throttledLowerTaxaValue = useThrottle(q, throttleDelay);

  const [taxaSuggestions, setTaxaSuggestions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (throttledLowerTaxaValue) {
      const searchQueries: Record<string, string> = {
        q: throttledLowerTaxaValue,
        rank: 'phylum,subphylum,superclass,class,subclass,superorder,order,suborder,infraorder,superfamily,epifamily,family,subfamily,supertribe,tribe,subtribe,genus',
        taxon_id: settings.kingdoms.join(','),
      };
      if (settings.include.length > 0) {
        searchQueries.taxon_id = settings.include
          .map((s: any) => s.id ?? s)
          .join(',');
      }
      if (settings.exclude.length > 0) {
        searchQueries.without_taxon_id = settings.exclude
          .map((s: any) => s.id ?? s)
          .join(',');
      }
      axios
        .get<{ results: ResultType[] }>(
          `https://api.inaturalist.org/v1/taxa/autocomplete?${Object.keys(searchQueries).reduce((acc, key) => acc + `${key}=${searchQueries[key]}&`, '')}`,
        )
        .then(({ data: { results } }) =>
          setTaxaSuggestions(results.map(formatDropdownOptionsFromApi)),
        )
        .catch((err) => {
          console.error(err);
          showAlert({
            severity: 'error',
            summary: 'Error E012',
            detail: err.message,
            sticky: true,
          });
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert, throttledLowerTaxaValue, settings.kingdoms]);

  return { taxaSuggestions, loading };
};
