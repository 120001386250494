import React from 'react';
import { Dialog } from 'primereact/dialog';

export const InstructionsModal = ({
  instructionsModalOpen,
  setInstructionsModalOpen,
}: {
  instructionsModalOpen: boolean;
  setInstructionsModalOpen: (open: boolean) => void;
}) => {
  const headerTemplate = (
    <div className="p-panel-header">
      <h2 style={{ margin: 0 }}>
        <i
          className="pi pi-fw pi-question-circle"
          style={{ marginRight: 12 }}
        />
        Instructions
      </h2>
    </div>
  );
  return (
    <Dialog
      header={headerTemplate}
      visible={!!instructionsModalOpen}
      className="responsive-modal"
      onHide={() => setInstructionsModalOpen(false)}
      closeOnEscape
      dismissableMask
      draggable={false}
    >
      <div className="grid grid-container">
        <div className="col-12" style={{ padding: 8, fontSize: '1.2em' }}>
          <ul className="how-to-play">
            <li>
              Input a possible kingdom, phylum, class, order, family, genus, or
              species in their respective dropdowns for the organism that is
              shown in the picture.
            </li>
            <li>
              When you begin typing, possible answers will be suggested in
              dropdown lists from the iNaturalist taxa search. You can guess at
              any level of taxa you like - and when you choose something from
              the dropdown, the broader taxa will be auto-filled after a short
              loading time.
            </li>
            <li>
              If your guess is incorrect, all following guesses branching from
              that incorrect taxa will be marked red in the dropdown options, so
              you can rule them out.
            </li>
            <li>
              Observations with multiple pictures can be browsed through by
              clicking the arrow, and clicking on any image will give you a
              larger view and allow you to zoom in, out, or rotate the image to
              get a better look.
            </li>
            <li>
              If you'd like more information on any selected taxa, you can
              always click the info icon beside it for a snippet from its
              Wikipedia page, as well as a photo.
            </li>
            <li>
              When the game is complete, your results will be added to your
              stats. If a photo is too blurry or misleading to have been
              properly identified, you can choose for it to not be added. You
              can also return to the settings at any time, which discards the
              game.
            </li>
          </ul>

          <div className="p-panel-header">
            <h2>
              <i
                className="pi pi-fw pi-external-link"
                style={{ marginRight: 12 }}
              />
              Links
            </h2>
          </div>
          <ul style={{ margin: 0, marginTop: 0 }}>
            <li>
              <a
                className="link-underline"
                href="https://www.inaturalist.org/pages/lets_id_some_arthropods"
                target="_blank"
                rel="noreferrer"
                style={{ paddingLeft: 8 }}
              >
                iNaturalist: How to Become a Better Identifier
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  );
};
