import React from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { useFormik } from 'formik';
import { MdOutlineFeedback } from 'react-icons/md';
import { FaRegCommentDots } from 'react-icons/fa';
import axios from 'axios';
import './Feedback.css';
import { Button } from 'primereact/button';
import { useMediaQuery } from '@uidotdev/usehooks';

type FormData = {
  title: string;
  content: string;
  type: 'bug' | 'feature' | 'comment';
};

const validate = (values: FormData) => {
  const errors: Partial<FormData> = {};
  if (!values.title) {
    errors.title = 'Must have title';
  } else if (values.title.length > 150) {
    errors.title = 'Title must be under 150 characters';
  }
  if (!values.content) {
    errors.content = 'Must have description';
  }
  return errors;
};
export const Feedback = ({ showAlert }: { showAlert: any }) => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 575px)');
  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
      type: 'bug',
    },
    validate,
    onSubmit: async (values: FormData) => {
      axios
        .post(
          'https://bf8a7urfa3.execute-api.us-east-1.amazonaws.com/default/identifi-feedback-api',
          {
            subject: `#${values.type} ${values.title}`,
            message: values.content,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          showAlert({
            severity: 'success',
            summary: 'Successfully sent feedback!',
          });
        })
        .catch((error: any) => {
          console.error(error);
          showAlert({
            severity: 'error',
            summary: 'Error sending feedback!',
            content: error.message,
            sticky: true,
          });
        });
      formik.resetForm();
    },
  });

  const textfield = (
    field: string,
    value: string | undefined = undefined,
    fieldProps: Record<string, any>,
  ) => {
    const error = Object.keys(formik.errors).includes(field)
      ? (formik.errors as { [key: string]: string })[field]
      : null;
    return (
      <div key={field} className="grid grid-container">
        <div className="col-auto col-12-override">
          <label
            style={{ display: 'inline-block', marginTop: 12, marginLeft: 8 }}
          >
            <span className="label fw-bold">{fieldProps.label ?? field}</span>
          </label>
        </div>
        <div className="col">
          <InputText
            id={field}
            name={field}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={value}
            style={{ width: '100%' }}
            {...fieldProps}
          />
          {error && formik.getFieldMeta(field).touched ? (
            <div className="form-error">{error}</div>
          ) : null}
        </div>
      </div>
    );
  };
  const textAreafield = (
    field: string,
    value: string | undefined = undefined,
    fieldProps: Record<string, any>,
  ) => {
    const error = Object.keys(formik.errors).includes(field)
      ? (formik.errors as { [key: string]: string })[field]
      : null;
    return (
      <div key={field}>
        <label>
          <span className="label fw-bold">{fieldProps.label ?? field}</span>
          <InputTextarea
            id={field}
            name={field}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={value}
            style={{ display: 'block', width: '100%' }}
            {...fieldProps}
          />
        </label>
        {error && formik.getFieldMeta(field).touched ? (
          <div className="form-error">{error}</div>
        ) : null}
      </div>
    );
  };

  const headerTemplate = (
    <div className="p-panel-header">
      <div style={{ padding: 8 }}>
        <MdOutlineFeedback style={{ marginRight: 12 }} />
        Feedback
      </div>
    </div>
  );
  return (
    <div className="grid grid-container">
      <div className="col-12 sm:col-12 md:col-11 lg:col-9">
        <Panel headerTemplate={headerTemplate}>
          <div className="grid mobile-row">
            <div className="col-12" style={{ padding: 16 }}>
              <h2 style={{ marginTop: 0 }}>
                <FaRegCommentDots /> I crave <em>feedback</em>!
              </h2>
              <p>
                Please let me know how things are going! For anything that
                requires attachments/demo or further elaboration, you can always
                feel free to reach out to me directly through email/discord.
              </p>
              <strong>Bugs</strong> = Site errors or unexpected behavior
              <br />
              <strong>Features</strong> = Things to change or add to make the
              site better!
              <br />
              <strong>Comments</strong> = For when you have a lil something to
              say that doesn't fit in either of the above categories
              <br />
              <p>
                When something not working, please try to be descriptive of both
                the expected behavior and what's happening instead, it will help
                me a lot with debugging! 👍
              </p>
              <p>
                Feel free to also leave me your name, or stay anonymous if you
                want.
              </p>
              <br />
              <p>
                <em>Have a question?</em> Shoot me a message directly! This form
                is anonymous and I won't be able to reply.
              </p>
              <hr style={{ marginTop: 32, marginBottom: 32 }} />
              <form onSubmit={formik.handleSubmit} className="bug-form">
                <label id="report-type-label" className="fw-bold label">
                  Type of Feedback <span className="text-red-400">*</span>
                </label>

                <div className="flex gap-3 radio-group">
                  <div className="radio-container">
                    <RadioButton
                      inputId="bug"
                      name="bug"
                      value="bug"
                      onChange={(e) => formik.setFieldValue('type', e.value)}
                      checked={formik.values.type === 'bug'}
                    />
                    <label htmlFor="bug" className="ml-2">
                      Bug
                    </label>
                  </div>
                  <div className="radio-container">
                    <RadioButton
                      inputId="feature"
                      name="feature"
                      value="feature"
                      onChange={(e) => formik.setFieldValue('type', e.value)}
                      checked={formik.values.type === 'feature'}
                    />
                    <label htmlFor="ingredient2" className="ml-2">
                      Feature
                    </label>
                  </div>
                  <div className="radio-container">
                    <RadioButton
                      inputId="comment"
                      name="comment"
                      value="comment"
                      onChange={(e) => formik.setFieldValue('type', e.value)}
                      checked={formik.values.type === 'comment'}
                    />
                    <label htmlFor="ingredient2" className="ml-2">
                      Comment
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: 16 }}>
                  {textfield('title', formik.values.title, {
                    label: (
                      <div>
                        Title <span className="text-red-400">*</span>
                      </div>
                    ),
                    placeholder: 'A short summary, 150 characters max',
                  })}
                </div>

                <div style={{ marginTop: 8 }}>
                  {textAreafield('content', formik.values.content, {
                    label: (
                      <div>
                        Description <span className="text-red-400">*</span>
                      </div>
                    ),
                    rows: 6,
                  })}
                </div>
              </form>
              <div className="grid grid-container">
                <div className="col-12 sm:col-5">
                  <Button
                    type="button"
                    severity="secondary"
                    rounded
                    icon="pi pi-fw pi-refresh"
                    size={isSmallDevice ? 'small' : 'large'}
                    style={{ width: '100%' }}
                    label="Reset Form"
                    onClick={() => formik.resetForm()}
                  ></Button>
                </div>
                <div className="col-12 sm:col-7">
                  <Button
                    type="button"
                    className="green-button"
                    rounded
                    label="Send Feedback"
                    size="large"
                    icon="pi pi-fw pi-envelope"
                    style={{ width: '100%' }}
                    disabled={!(formik.values?.title && formik.isValid)}
                    onClick={() => formik.submitForm()}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
