import { useLocalStorage } from '@uidotdev/usehooks';
import { Game } from './usePractice';
import { ResultType } from './useLevels';
import { useState } from 'react';
import { CURRENT_VERSION } from '../constants';

export interface GameStats {
  observationId: number;
  speciesId: number;
  status: 'won' | 'lost';
  currentTree: any[];
  correct: Record<string, ResultType>;
  guesses: ResultType[][];
  maxGuesses: number;
  datetime: string;
  seconds: number;
}
export interface Blacklist {
  observationId: number;
  speciesId: number;
  currentTree: any[];
}
export interface Stats {
  daily: {
    streak: number;
    games: GameStats[];
  };
  practice: {
    streak: number;
    games: GameStats[];
    blacklist: Blacklist[];
  };
}

export const DEFAULT_STATS: Stats = {
  daily: {
    streak: 0,
    games: [],
  },
  practice: {
    streak: 0,
    games: [],
    blacklist: [],
  },
};

export const useStats = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [stats, setStats] = useLocalStorage<Stats>('stats', DEFAULT_STATS);
  const [currentTab, setCurrentTab] = useState('practice');
  const [storedVersion, setStoredVersion] = useLocalStorage<string>(
    'version',
    CURRENT_VERSION,
  );

  const resetStats = () => {
    setStats(DEFAULT_STATS);
  };

  const addGameToStats = (
    type: 'daily' | 'practice',
    game: Game,
    currentTree: any,
    seconds: number,
  ) => {
    const newStats = { ...stats };
    newStats[type].games.push({
      observationId: game.currentObservation?.id as number,
      speciesId: currentTree?.[currentTree.length - 1]?.id,
      status: game.status as 'won' | 'lost',
      maxGuesses: game.maxGuesses,
      currentTree,
      correct: game.lockedIn,
      guesses: game.incorrectGuesses.filter((guess) => guess.length > 0),
      datetime: new Date().toISOString(),
      seconds,
    });
    if (game.status === 'won') {
      newStats[type].streak += 1;
    } else {
      newStats[type].streak = 0;
    }
    setStats(newStats);
  };

  const addToBlacklist = (currentTree: any[], game: Game) => {
    const newStats = { ...stats };
    const observationInGames = newStats.practice.games.find(
      ({ observationId }) => observationId === game.currentObservation?.id,
    );
    if (observationInGames) {
      newStats.practice.games = newStats.practice.games.filter(
        ({ observationId }) => observationId !== game.currentObservation?.id,
      );
    }
    newStats.practice.blacklist.push({
      observationId: game.currentObservation?.id as number,
      speciesId: currentTree?.[currentTree.length - 1]?.id,
      currentTree,
    });
    setStats(newStats);
  };

  return {
    stats,
    addGameToStats,
    sidebarVisible,
    setSidebarVisible,
    resetStats,
    currentTab,
    setCurrentTab,
    addToBlacklist,
    storedVersion,
    setStoredVersion,
  };
};
