import { Image as PrimereactImage } from 'primereact/image';
import { ResultType } from '../../hooks/useLevels';
import { shortenLocation } from '../../utils/shortenLocation';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

export const GuessImage = ({
  currentObservation,
}: {
  currentObservation: ResultType;
}) => {
  const galleriaRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const ref: any = useRef(null);
  useEffect(() => {
    if (ref.current?.offsetWidth) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);
  const imgRef = useRef();
  const photo = currentObservation.photos?.[activeIndex]?.url;

  if (!photo) {
    return null;
  }
  return (
    <div
      style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}
    >
      <div className="grid" style={{ margin: 0, position: 'relative' }}>
        <div
          className="col-fixed"
          style={{
            width: 45,
            height: '100%',
            position: 'absolute',
            left: 6,
            zIndex: 999,
          }}
        >
          {activeIndex > 0 && (
            <Button
              type="button"
              className="img-button"
              icon="pi pi-chevron-left"
              onClick={() => setActiveIndex(activeIndex - 1)}
            ></Button>
          )}
        </div>
        <div className="col">
          <Button
            type="button"
            ref={ref}
            className="img-container"
            onClick={() => {
              (imgRef.current as any)?.show();
              (galleriaRef.current as any)?.stopSlideShow();
            }}
            style={{
              height: `${width}px`,
              backgroundColor: '#e2ddd6',
              backgroundImage: `url("${photo.replace('square', 'original')}")`,
              textAlign: 'right',
            }}
          >
            {currentObservation.photos.length > 1 && (
              <Badge
                className="img-count"
                value={`${activeIndex + 1}/${currentObservation.photos.length}`}
              ></Badge>
            )}
          </Button>
        </div>
        <div
          className="col-fixed"
          style={{
            width: 45,
            height: '100%',
            position: 'absolute',
            right: 14,
            zIndex: 999,
          }}
        >
          {currentObservation.photos.length > 1 &&
            activeIndex < currentObservation.photos.length - 1 && (
              <Button
                type="button"
                className="img-button"
                icon="pi pi-chevron-right"
                onClick={() => setActiveIndex(activeIndex + 1)}
              ></Button>
            )}
        </div>
        <PrimereactImage
          ref={imgRef as any}
          src={photo.replace('square', 'original')}
          alt="Image"
          style={{ display: 'none' }}
          preview
          onHide={() => (galleriaRef.current as any)?.startSlideShow()}
        />
      </div>
      <i className="img-subtitle" style={{ marginTop: 6 }}>
        &copy; {currentObservation.user?.name || currentObservation.user?.login}
        {currentObservation?.photos?.[0]?.attribution?.replace(/\(c\).*?,/, '')}
        <br />
        Observed at{' '}
        {currentObservation?.observed_on_string &&
        isNaN(Date.parse(currentObservation.observed_on_string))
          ? 'Unknown'
          : new Date(
              currentObservation.observed_on_string as string,
            ).toLocaleString()}{' '}
        in {shortenLocation(currentObservation?.place_guess as string)}
      </i>
    </div>
  );
};
