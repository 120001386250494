import React from 'react';
import { MegaMenu } from 'primereact/megamenu';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import './NavbarWrapper.css';
import { MdOutlineFeedback } from 'react-icons/md';
import { CURRENT_VERSION, ICON_COUNT } from '../constants';

export const NavbarWrapper = ({ children, setSidebarVisible }: any) => {
  const navigate = useNavigate();
  const items = [
    {
      label: 'Daily Challenge',
      icon: 'pi pi-fw pi-bolt',
      command: () => navigate('/daily'),
    },
    {
      label: 'Identification Practice',
      icon: 'pi pi-fw pi-stopwatch',
      command: () => navigate('/practice'),
    },
    {
      label: 'Feedback',
      icon: (
        <span
          style={{
            width: '1.28571429em',
            textAlign: 'center',
            marginRight: 10,
          }}
        >
          <MdOutlineFeedback />
        </span>
      ),
      command: () => navigate('/feedback'),
    },
  ];

  const start = (
    <div
      onClick={() => navigate('/')}
      style={{
        fontSize: '1.75em',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      <img
        style={{ height: 48, width: 48, marginLeft: 24, marginRight: 16 }}
        alt="logo"
        src={`icon_svgs/favicon${Math.round(Math.random() * ICON_COUNT)
          .toString()
          .padStart(2, '0')}.svg`}
      />
      <span
        style={{ display: 'inline-block', marginRight: 16, userSelect: 'none' }}
      >
        <span style={{ color: '#8cb700' }}>i</span>
        Dentifi
        <span style={{ fontSize: '0.5em', opacity: 0.6, marginLeft: 8 }}>
          v{CURRENT_VERSION}
        </span>
      </span>
    </div>
  );

  const end = (
    <Button
      rounded
      link
      icon="pi pi-chart-bar pr-2"
      onClick={() => setSidebarVisible(true)}
    >
      Stats
    </Button>
  );
  return (
    <div>
      <MegaMenu
        id="nav"
        model={items}
        orientation="horizontal"
        start={start}
        end={end}
        breakpoint="960px"
      />
      <div style={{ minHeight: 'calc(100vh - 128px)' }}>{children}</div>
      <div className="footer">
        <div className="footer-links">
          <Button size="small" link onClick={() => navigate('/about')}>
            About
          </Button>
          <Button size="small" link onClick={() => navigate('/releases')}>
            Release Notes
          </Button>
        </div>
        <div>
          Powered by the{' '}
          <a
            href="https://www.inaturalist.org/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="iNaturalist"
              style={{ height: 14, opacity: 0.85, marginLeft: 2 }}
              src="./inatlogo.png"
            />
          </a>{' '}
          <a
            href="https://api.inaturalist.org/v1/docs/"
            target="_blank"
            rel="noreferrer"
          >
            API
          </a>
        </div>
      </div>
    </div>
  );
};
