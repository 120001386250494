import React, { useRef, useState } from 'react';
import GoogleMap from 'google-map-react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { InputNumber } from 'primereact/inputnumber';
import './Map.css';
import { Button } from 'primereact/button';
import { DEFAULT_SETTINGS } from '../../hooks/usePractice';

const DEFAULT_ZOOM = 10;

export const MapContainer = ({
  location,
  setMapLocation,
  isGeolocationAvailable,
  getPosition,
}: {
  location: any;
  setMapLocation: any;
  isGeolocationAvailable: boolean;
  getPosition: any;
}) => {
  const [originalRadius] = useState(DEFAULT_SETTINGS.location.radius);
  const [circle, setCircle] = useState<any>(null);
  const [circleRadius, setCircleRadius] = useState(location.radius);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const targetRef = useRef();
  if (!process.env.REACT_APP_API_KEY) {
    return null;
  }

  return (
    <div
      style={{
        marginBottom: 16,
        width: '100%',
      }}
    >
      {circle && (
        <div className="grid">
          <div className="col lat-lng-rad">
            <div className="location-container">
              <div className="lat">
                <strong>Latitude</strong>
                <InputNumber
                  value={location.latitude}
                  onValueChange={(e) =>
                    setMapLocation({ ...location, latitude: e.value })
                  }
                  minFractionDigits={6}
                  maxFractionDigits={12}
                />
              </div>
              <div className="lng">
                <strong>Longitude</strong>
                <InputNumber
                  value={location.longitude}
                  onValueChange={(e) =>
                    setMapLocation({ ...location, longitude: e.value })
                  }
                  minFractionDigits={6}
                  maxFractionDigits={12}
                />
              </div>
            </div>
            <div className="radius">
              <strong style={{ paddingRight: 12 }}>Radius</strong>
              <InputNumber
                value={Math.round(circleRadius / 1000)}
                showButtons
                min={5}
                max={2560}
                onValueChange={(e) => {
                  if (e.value && circle) {
                    setCircleRadius(e.value * 1000);
                    const newZoom =
                      Math.log2(originalRadius / (e.value * 1000)) +
                      DEFAULT_ZOOM;
                    circle.setRadius(e.value * 1000);
                    setMapLocation({
                      ...location,
                      radius: e.value * 1000,
                      zoom: newZoom,
                    });
                  }
                }}
              />
              <span style={{ paddingLeft: 12 }}>km</span>
            </div>
          </div>
        </div>
      )}

      {circle && (
        <div className="grid">
          <div className="col-12 sm:col">
            <GooglePlacesAutocomplete
              autocompletionRequest={{
                location: { lat: location.lat, lng: location.lng },
                radius: location.radius,
              }}
              selectProps={{
                value: selectedLocation,
                onChange: (value: any) =>
                  geocodeByAddress(value.label)
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                      setSelectedLocation(value);
                      setMapLocation({
                        latitude: lat,
                        longitude: lng,
                      });
                    }),
                placeholder: 'Search for a Location',
              }}
            />
          </div>
          {isGeolocationAvailable && (
            <div className="col-12 sm:col-5 lg:col-4 xl:col-3">
              <Button
                style={{ width: '100%' }}
                className="green-button"
                rounded
                label="Re-center Map"
                icon="pi pi-map-marker"
                type="button"
                onClick={() => {
                  getPosition();
                  setMapLocation({ latitude: null, longitude: null });
                }}
              />
            </div>
          )}
        </div>
      )}
      <div
        ref={targetRef as any}
        style={{
          height: '600px',
          width: '100%',
          marginTop: 10,
        }}
      >
        <GoogleMap
          bootstrapURLKeys={{
            key: process.env.REACT_APP_API_KEY as string,
            libraries: ['places'],
          }}
          center={{ lat: location.latitude, lng: location.longitude }}
          zoom={location.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setCircle(
              new maps.Circle({
                strokeColor: '#8cb700',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#8cb700',
                fillOpacity: 0.3,
                map,
                center: { lat: location.latitude, lng: location.longitude },
                radius: location.radius,
              }),
            );
          }}
          onChange={({ center, zoom }) => {
            if (circle) {
              let newRadius = originalRadius / Math.pow(2, zoom - DEFAULT_ZOOM);
              if (
                newRadius !== originalRadius ||
                center.lat !== location.latitude ||
                center.lng !== location.longitude
              ) {
                if (zoom >= 12) {
                  newRadius = originalRadius / Math.pow(2, 12 - DEFAULT_ZOOM);
                }

                circle.setRadius(newRadius);
                circle.setCenter(center);
                setMapLocation({
                  latitude: center.lat,
                  longitude: center.lng,
                  radius: newRadius,
                  zoom,
                });
                setCircleRadius(newRadius);
                setSelectedLocation(null);
              }
            }
          }}
        ></GoogleMap>
      </div>
    </div>
  );
};

export default MapContainer;
